import CustomerComponent from "./components/Customer";
import NavbarComponent from "./components/Navbar";
import PricingComponent from "./components/Pricing";
import Section1Component from "./components/Section1";
import Section2Component from "./components/Section2";
import Section3Component from "./components/Section3";
import Section4Component from "./components/Section4";
import StepRegister from "./components/StepRegister";

function App() {
  return (
    <>
      <div
        id="main"
        data-framer-hydrate-v2='{"routeId":"TS1ShkoTF","localeId":"default"}'
        data-framer-ssr-released-at="2024-04-23T14:55:14.046Z"
        data-framer-page-optimized-at="2024-04-24T07:20:07.704Z"
      >
        {/*$*/}
        <div
          className="framer-hLdvR framer-YGIOQ framer-UGujo framer-xyoGX framer-Tbn6R framer-ollpel"
          style={{ minHeight: "100vh", width: "auto" }}
        >
          {/* section 1 */}
          <Section1Component />
          <NavbarComponent />
            <div className="framer-1xroo7n-container hidden-q2nkjo" />
            <div
              className="framer-nwuxot"
              data-framer-name="ForMobileCTA"
              id="mobilectascroll"
              name="ForMobileCTA"
            >
              <div
                className="framer-1b6grsm"
                data-framer-name="Product"
                name="Product"
              >
                <div className="ssr-variant hidden-9i23oi hidden-150dn05 hidden-q2nkjo">
                  <div className="framer-d72m6s-container">
                  <div className="framer-E7Qub framer-zG9tg framer-UGujo framer-1cwosbs framer-v-1ohnim0" data-framer-name="Messenger" style={{width: '100%'}} tabIndex={0}>

          <Section2Component />
</div>

                  </div>
                </div>

              </div>
              <Section3Component />
            <Section4Component />
            <CustomerComponent />
          <PricingComponent />
        <StepRegister />
        <div className="framer-1o3zs8n" data-framer-name="Data" name="Data">
  <div className="framer-mr6e23">
    <div className="framer-12phx3y" data-framer-component-type="RichTextContainer" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, transform: 'none'}}>
      <h3 className="framer-text framer-styles-preset-1k1rjey" data-styles-preset="iT7awcTns"> Hiệu suất của OnCenter</h3>
    </div>
    <div className="framer-f6ayo7" data-framer-component-type="RichTextContainer" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, transform: 'none'}}>
      {/* <p className="framer-text framer-styles-preset-129k9s7" data-styles-preset="tvEOTaVxk">※一例です</p> */}
    </div>
  </div>
  <div className="framer-1w736s0">
  <div className="framer-15b7z88" data-framer-name="Card" name="Card">
  <div className="framer-jl0vgb">
    <div className="framer-13qtz55">
      <div className="framer-1sii3d3">
        <div 
          className="framer-1mjdzy8" 
          data-framer-component-type="RichTextContainer" 
          style={{
            outline: 'none',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            flexShrink: 0,
            transform: 'none'
          }}
        >
          <h3 
            className="framer-text" 
            style={{
              fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
              fontSize: '80px',
              fontWeight: 700,
              lineHeight: '1em',
              textAlign: 'left',
              color: 'var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255))'
            }}
          >
            98
          </h3>
        </div>
        <div 
          className="framer-1ssv2x7" 
          data-framer-component-type="RichTextContainer" 
          style={{
            outline: 'none',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            flexShrink: 0,
            transform: 'none'
          }}
        >
          <h3 
            className="framer-text" 
            style={{
              fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
              fontSize: '36px',
              fontWeight: 700,
              lineHeight: '1em',
              textAlign: 'left',
              color: 'var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255))'
            }}
          >
            % 
          </h3>
        </div>
      </div>
    </div>
  </div>
  <div 
    className="framer-d06rre" 
    data-framer-component-type="RichTextContainer" 
    style={{
      outline: 'none',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      flexShrink: 0,
      transform: 'none'
    }}
  >
    <p 
      className="framer-text framer-styles-preset-129k9s7" 
      data-styles-preset="tvEOTaVxk" 
      style={{
        color: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))'
      }}
    >
      Khách hàng tin dùng
    </p>
  </div>
</div>

<div className="framer-1co7xpq" data-framer-name="Card" name="Card">
  <div className="framer-1q21lld">
    <div className="framer-xtbki3">
      <div className="framer-1i9n3md">
        <div 
          className="framer-1e0kcke" 
          data-framer-component-type="RichTextContainer" 
          style={{
            outline: 'none',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            flexShrink: 0,
            transform: 'none'
          }}
        >
          <h3 
            className="framer-text" 
            style={{
              fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
              fontSize: '80px',
              fontWeight: 700,
              lineHeight: '1em',
              textAlign: 'left',
              color: 'var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255))'
            }}
          >
            93
          </h3>
        </div>
        <div 
          className="framer-14vf8r5" 
          data-framer-component-type="RichTextContainer" 
          style={{
            outline: 'none',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            flexShrink: 0,
            transform: 'none'
          }}
        >
          <h3 
            className="framer-text" 
            style={{
              fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
              fontSize: '36px',
              fontWeight: 700,
              lineHeight: '1em',
              textAlign: 'left',
              color: 'var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255))'
            }}
          >
            % 
          </h3>
        </div>
      </div>
    </div>
  </div>
  <div 
    className="framer-1jyv54w" 
    data-framer-component-type="RichTextContainer" 
    style={{
      outline: 'none',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      flexShrink: 0,
      transform: 'none'
    }}
  >
    <p 
      className="framer-text framer-styles-preset-129k9s7" 
      data-styles-preset="tvEOTaVxk" 
      style={{
        color: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))'
      }}
    >
      Hỗ trợ khách hàng
    </p>
  </div>
</div>

<div className="framer-cck2o8" data-framer-name="Card" name="Card">
  <div className="framer-65xt4">
    <div className="framer-p8b8oo">
      <div className="framer-1fo4dif">
        <div 
          className="framer-14cytya" 
          data-framer-component-type="RichTextContainer" 
          style={{
            outline: 'none',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            flexShrink: 0,
            transform: 'none'
          }}
        >
          <h3 
            className="framer-text" 
            style={{
              fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
              fontSize: '80px',
              fontWeight: 700,
              lineHeight: '1em',
              textAlign: 'left',
              color: 'var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255))'
            }}
          >
            40
          </h3>
        </div>
        <div 
          className="framer-1swp7i4" 
          data-framer-component-type="RichTextContainer" 
          style={{
            outline: 'none',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            flexShrink: 0,
            transform: 'none'
          }}
        >
          <h3 
            className="framer-text" 
            style={{
              fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
              fontSize: '36px',
              fontWeight: 700,
              lineHeight: '1em',
              textAlign: 'left',
              color: 'var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255))'
            }}
          >
            +
          </h3>
        </div>
      </div>
    </div>
  </div>
  <div 
    className="framer-kyn7ik" 
    data-framer-component-type="RichTextContainer" 
    style={{
      outline: 'none',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      flexShrink: 0,
      transform: 'none'
    }}
  >
    <p 
      className="framer-text framer-styles-preset-129k9s7" 
      data-styles-preset="tvEOTaVxk" 
      style={{
        color: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))'
      }}
    >
      Người dùng
    </p>
  </div>
</div>

  </div>
</div>

      <div className="framer-wy4eiv-container">
  <div className="framer-a9wfd framer-VlQOU framer-boE3A framer-1s0sa4f framer-v-1s0sa4f" data-framer-name="Desktop" tabIndex={0} style={{backgroundColor: 'rgb(31, 35, 41)', width: '100%', opacity: 1}}>
    <div className="framer-delptr" data-framer-name="Banner" style={{opacity: 1}}>
    <div className="framer-1f6balv" style={{ backgroundColor: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))', opacity: 1 }}>
  <div className="framer-1gz1whz" style={{ opacity: 1 }}>
    <div className="framer-3d42n4" data-framer-component-type="RichTextContainer" style={{ outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, color: 'rgb(255, 255, 255)', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none', opacity: 1 }}>
      <h3 className="framer-text" style={{ fontFamily: '"Noto Sans JP", sans-serif', fontSize: '40px', fontWeight: 900, lineHeight: '1.5em', textAlign: 'center', color: 'rgb(255, 255, 255)' }}>
        OnCenter - Giải pháp quản trị doanh nghiệp hàng đầu
      </h3>
    </div>
    <div className="framer-1yw7dgi" data-framer-component-type="RichTextContainer" style={{ outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none', opacity: 1 }}>
    <p className="framer-text framer-styles-preset-17zl6gk" data-styles-preset="bqFilvqae">Hãy để việc quản lý doanh nghiệp trở nên dễ dàng hơn</p>
  </div>
</div>
<div className="framer-1aeg2re" style={{ opacity: 1 }}>
  <div className="framer-1hsmnch-container" style={{ opacity: 1 }}>
    <a className="framer-z3KvX framer-14f9noh framer-v-1s2ak0y framer-500sgr" href="" rel="noopener" tabIndex={0} data-framer-name="JP FooterBtnBlue" style={{ borderBottomWidth: '0px', borderColor: 'rgba(0, 0, 0, 0)', borderLeftWidth: '0px', borderRightWidth: '0px', borderStyle: 'solid', borderTopWidth: '0px', backgroundColor: 'var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255))', height: '100%', width: '100%', opacity: 1, borderRadius: '30px' }}>
      <div className="framer-1lihwei" style={{ opacity: 1 }}>
        <div className="framer-ok4mx3" data-framer-component-type="RichTextContainer" style={{ outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, color: 'rgb(255, 255, 255)', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none', opacity: 1 }}>
          <p className="framer-text" style={{ fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', fontSize: '18px', fontWeight: 700, lineHeight: '1em', textAlign: 'center', color: 'rgb(255, 255, 255)' }}>
            Đăng nhập
          </p>
        </div>
      </div>
    </a>
  </div>
  <div className="framer-unhxvm-container" style={{ opacity: 1 }}>
    <a className="framer-z3KvX framer-14f9noh framer-v-n2cvhf framer-500sgr" data-framer-name="JP FooterBtnWhite" data-border="true" href="https://www.larksuite.com/ja_jp/salessupport?from=footer_banner" target="_blank" rel="noopener" tabIndex={0} style={{ borderBottomWidth: '1px', borderColor: 'var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255))', borderLeftWidth: '1px', borderRightWidth: '1px', borderStyle: 'solid', borderTopWidth: '1px', backgroundColor: 'rgb(255, 255, 255)', borderRadius: '30px', height: '100%', width: '100%', opacity: 1 }}>
      <div className="framer-1lihwei" style={{ opacity: 1 }}>
        <div className="framer-ok4mx3" data-framer-component-type="RichTextContainer" style={{ outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, color: 'var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none', opacity: 1 }}>
          <p className="framer-text" style={{ fontFamily: '"Noto Sans JP Bold", "Noto Sans JP Bold Placeholder", sans-serif', fontSize: '18px', lineHeight: '1em', textAlign: 'center', color: 'var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255))' }}>
            Đăng ký
          </p>
        </div>
      </div>
    </a>
  </div>
</div>
</div>

    </div>
    <div className="framer-2iu12o" data-framer-name="Gap" style={{opacity: 1}}><div className="framer-6ak310" style={{backgroundColor: 'rgb(69, 73, 78)', opacity: 1}} /></div>
    <div className="framer-1tyf26m" data-framer-name="Down" style={{opacity: 1}}>
      <div className="framer-1ijd1z2" style={{opacity: 1}}>
      <div className="framer-11q26ya" style={{ opacity: 1 }}>
  <a 
    data-framer-component-type="SVG" 
    data-framer-name="Union" 
    href="" 
    target="_blank" 
    rel="noopener" 
    className="framer-12yqdie framer-mlr9dp" 
    style={{ imageRendering: 'pixelated', flexShrink: 0, backgroundSize: '100% 100%', backgroundImage: 'url("data:image/svg+xml")', opacity: 1 }} 
  />
  <div className="framer-1xx5fja" style={{ opacity: 1 }}>
    <div className="framer-2xvx2g" 
      data-framer-component-type="RichTextContainer" 
      style={{ outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, color: 'rgb(255, 255, 255)', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateY(-50%)', opacity: 1 }}
    >
      <p className="framer-text" 
        style={{ fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', fontSize: '12px', color: 'rgb(255, 255, 255)' }}
      >
        © 2024 Công ty cổ phần IKIGROUP.
      </p>
      <p className="framer-text" 
        style={{ fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', fontSize: '12px', color: 'rgb(255, 255, 255)' }}
      >
      Số 8 Phạm Hùng, Mai Dịch, Cầu Giấy, Hà Nội.
      </p>
    </div>
  </div>
</div>

        <div className="framer-o6lbge" style={{opacity: 1, display: 'none'}}>
          <div className="framer-1mj4nht-container" style={{opacity: 1}}>
            <a className="framer-TQCoO framer-1m6jlym framer-v-1m6jlym framer-1kfj3uq" data-framer-name="LinkedIn" href="" target="_blank" rel="noopener" tabIndex={0} style={{opacity: 1}}>
              <div data-framer-component-type="SVG" data-framer-name="Vector" className="framer-e37r5f" style={{imageRendering: 'pixelated', flexShrink: 0, fill: 'rgb(0, 0, 0)', color: 'rgb(0, 0, 0)', opacity: 1}}>
                <div className="svgContainer" style={{width: '100%', height: '100%', aspectRatio: 'inherit'}}>
                  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{width: '100%', height: '100%'}} viewBox="0 0 22 22" preserveAspectRatio="none" width="100%" height="100%">
                    <use href="#svg160196645_952" />
                  </svg>
                </div>
              </div>
            </a>
          </div>
          <div className="framer-1oqf11f-container" style={{opacity: 1}}>
            <a className="framer-TQCoO framer-1m6jlym framer-v-6v59vd framer-1kfj3uq" data-framer-name="Facebook" href="https://www.facebook.com/LarksuiteJapan/" target="_blank" rel="noopener" tabIndex={0} style={{opacity: 1}}>
              <div className="framer-1f36n07" data-framer-name="Facebook" style={{opacity: 1}}>
                <div data-framer-component-type="SVG" data-framer-name="Container" className="framer-1ihon8o" style={{imageRendering: 'pixelated', flexShrink: 0, fill: 'rgb(0, 0, 0)', color: 'rgb(0, 0, 0)', opacity: 1}}>
                  <div className="svgContainer" style={{width: '100%', height: '100%', aspectRatio: 'inherit'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{width: '100%', height: '100%'}} viewBox="0 0 23 22" preserveAspectRatio="none" width="100%" height="100%">
                      <use href="#svg1591365228_688" />
                    </svg>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="framer-1tammec-container" style={{opacity: 1}}>
            <a className="framer-TQCoO framer-1m6jlym framer-v-17zdf8h framer-1kfj3uq" data-framer-name="Twitter" href="https://twitter.com/larkjapan?lang=jp" target="_blank" rel="noopener" tabIndex={0} style={{opacity: 1}}>
              <div className="framer-f9mjz4" data-framer-name="Twitter" style={{opacity: 1}}>
                <div data-framer-component-type="SVG" data-framer-name="Vector" className="framer-c03byc" style={{imageRendering: 'pixelated', flexShrink: 0, fill: 'rgb(0, 0, 0)', color: 'rgb(0, 0, 0)', opacity: 1}}>
                  <div className="svgContainer" style={{width: '100%', height: '100%', aspectRatio: 'inherit'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{width: '100%', height: '100%'}} viewBox="0 0 20 20" preserveAspectRatio="none" width="100%" height="100%">
                      <use href="#svg-164419976_355" />
                    </svg>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="framer-1icugkz-container" style={{opacity: 1}}>
            <a className="framer-TQCoO framer-1m6jlym framer-v-yn2hrd framer-1kfj3uq" data-framer-name="Youtube" href="https://www.youtube.com/@larkjapan" target="_blank" rel="noopener" tabIndex={0} style={{opacity: 1}}>
              <div className="framer-1jiuunl" data-framer-name="YouTube" style={{opacity: 1}}>
                <div data-framer-component-type="SVG" data-framer-name="Vector" className="framer-p0ionp" style={{imageRendering: 'pixelated', flexShrink: 0, fill: 'rgb(0, 0, 0)', color: 'rgb(0, 0, 0)', opacity: 1}}>
                  <div className="svgContainer" style={{width: '100%', height: '100%', aspectRatio: 'inherit'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{width: '100%', height: '100%'}} viewBox="0 0 25 18" preserveAspectRatio="none" width="100%" height="100%">
                      <use href="#svg-307669420_847" />
                    </svg>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="framer-bgomf7" data-framer-name="Language switcher" style={{opacity: 1}}>
            <div className="framer-ti3t0j-container" style={{opacity: 1}}>
              <div className="framer-Ah9Oj framer-1wkywlc framer-v-1upm3se" data-framer-name="JP" tabIndex={0} style={{opacity: 1}}>
                <div className="framer-1xvk2e6" data-framer-name="Menu Item" data-highlight="true" tabIndex={0} style={{borderRadius: '9px', opacity: 1}}>
                  <div className="framer-gs3cqa" style={{opacity: 1}}>
                    <div data-framer-background-image-wrapper="true" style={{position: 'absolute', borderRadius: 'inherit', inset: '0px'}}>
                      <img decoding="async" src="https://framerusercontent.com/images/3UBWGzXFfV4t4BBCYTRcPCeO55c.svg" alt="Language" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center center', objectFit: 'cover', imageRendering: 'auto'}} />
                    </div>
                  </div>
                  <div className="framer-xc5yii" data-framer-component-type="RichTextContainer" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'rgb(187, 191, 196)', transform: 'none', opacity: 1}}>
                    <p className="framer-text" style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLXJlZ3VsYXI=', framerFontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', framerFontSize: '14px', framerLineHeight: '1.4em', framerTextAlignment: 'left', framerTextColor: 'var(--extracted-r6o4lv, rgb(187, 191, 196))'}}>
                      日本語
                    </p>
                  </div>
                  <div data-framer-component-type="SVG" className="framer-dg96du" style={{imageRendering: 'pixelated', flexShrink: 0, backgroundSize: '100% 100%', backgroundImage: 'url("data:image/svg+xml', opacity: 1}} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
            </div>

        </div>
      </div>

    </>
  );
}

export default App;
