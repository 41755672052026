function Section4Component() {
    return ( 
        <div className="framer-bck2dx" data-framer-name="Industry Case" name="Industry Case">
        <div className="framer-1tmg5mw" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, transform: 'none'}} data-framer-component-type="RichTextContainer">
          <h3 className="framer-text framer-styles-preset-1k1rjey" data-styles-preset="iT7awcTns">
            OnCenter đã giúp các doanh nghiệp tăng trưởng và làm việc hiệu quả hơn
          </h3>
        </div>
        <div className="framer-1vyhsjl-container hidden-9i23oi hidden-q2nkjo" data-framer-name="Industry cases" name="Industry cases">
          <section style={{display: 'flex', overflow: 'hidden', width: '100%', height: '100%', position: 'relative', padding: 0}} aria-roledescription="carousel">
            <ul style={{padding: 0, margin: 0, listStyle: 'none', position: 'relative', display: 'flex', flex: '1 1 100%', width: '100%', height: '100%', gap: '16px', alignItems: 'center', flexDirection: 'row', overflowX: 'auto', overflowY: 'hidden', scrollSnapType: 'x mandatory', WebkitOverflowScrolling: 'touch', borderRadius: 0, WebkitMaskImage: 'linear-gradient(to right, rgb(0, 0, 0, 1) 0%, rgb(0, 0, 0, 1) 12.5%, rgba(0, 0, 0, 1) 87.5%, rgb(0, 0, 0, 0) 100%)', MozMaskImage: 'linear-gradient(to right, rgb(0, 0, 0, 1) 0%, rgb(0, 0, 0, 1) 12.5%, rgba(0, 0, 0, 1) 87.5%, rgb(0, 0, 0, 0) 100%)', maskImage: 'linear-gradient(to right, rgb(0, 0, 0, 1) 0%, rgb(0, 0, 0, 1) 12.5%, rgba(0, 0, 0, 1) 87.5%, rgb(0, 0, 0, 0) 100%)'}} className="framer--carousel" data-show-scrollbar="false" aria-atomic="false" aria-live="polite">
              <li style={{scrollSnapAlign: 'center', flexShrink: 0, scrollSnapStop: 'always'}} aria-label="1 of 4">
                <div className="framer-1nlwv2r-container" data-framer-name="Retail" name="Retail">
                  <div name="Retail" className="framer-QTK3Z framer-108qzes framer-v-1cvqz0r" data-framer-name="JP - AIGC" style={{backgroundColor: 'rgb(246, 246, 251)', borderBottomLeftRadius: '16px', borderBottomRightRadius: '16px', borderTopLeftRadius: '16px', borderTopRightRadius: '16px', height: '100%', width: '100%'}} tabIndex={0}>
                    <a className="framer-9mwylm framer-18qcco1" href="" target="_blank" rel="noopener">
                      <div className="framer-5u990a">
                        <div className="framer-179ilkj" data-framer-name="Image">
                          <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                            <img decoding="async" sizes="(min-width: 1240px) 300px, (min-width: 905px) and (max-width: 1239px) 300px, (min-width: 600px) and (max-width: 904px) max(300px, 138px), (max-width: 599px) max(300px, 138px)" src="https://vcdn-vnexpress.vnecdn.net/2019/06/11/973728000-w500-8204-1560247094.png" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center top', objectFit: 'contain', imageRendering: 'auto'}} data-framer-original-sizes="300px" />
                          </div>
                        </div>
                      </div>
                      <div className="framer-1b9kwb0" style={{background: 'linear-gradient(118deg, rgb(231, 230, 236) 0%, rgb(243, 243, 250) 53.153153153153156%, rgba(208, 211, 236, 0.95) 100%)'}}>
                        <div className="framer-q1wo6h">
                          <div className="framer-la8i3p" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0,framerLinkTextColor: 'rgb(0, 153, 255)',framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                            <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTcwMA==',fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',fontSize: '24px',fontWeight: 700}} className="framer-text">
                              Trung tâm dạy IELTS
                            </p>
                          </div>
                          <div className="framer-10vu670-container"><div style={{display: 'contents'}} /></div>
                        </div>
                        <div className="framer-w91f3h" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0,extractedR6o4lv: 'rgb(100, 106, 115)',framerLinkTextColor: 'rgb(0, 153, 255)',framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                          <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLXJlZ3VsYXI=',fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',framerLineHeight: '1.52em',framerTextColor: 'var(--extracted-r6o4lv, rgb(100, 106, 115))'}} className="framer-text">
                            Kiểm soát học viên đến học dễ dàng hơn, học viên chỉ mất 1s để điểm danh ...
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </li>
              <li style={{scrollSnapAlign: 'center', flexShrink: 0, scrollSnapStop: 'always'}} aria-label="2 of 4">
                <div className="framer-4hicva-container" data-framer-name="Food&Beverage" name="Food&Beverage">
                  <div name="Food&Beverage" className="framer-QTK3Z framer-108qzes framer-v-1cvqz0r" data-framer-name="JP - AIGC" style={{backgroundColor: 'rgb(246, 246, 251)', borderBottomLeftRadius: '16px', borderBottomRightRadius: '16px', borderTopLeftRadius: '16px', borderTopRightRadius: '16px', height: '100%', width: '100%'}} tabIndex={0}>
                    <a className="framer-9mwylm framer-18qcco1" href="" target="_blank" rel="noopener">
                      <div className="framer-5u990a">
                        <div className="framer-179ilkj" data-framer-name="Image">
                          <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                            <img decoding="async" sizes="(min-width: 1240px) 300px, (min-width: 905px) and (max-width: 1239px) 300px, (min-width: 600px) and (max-width: 904px) max(300px, 138px), (max-width: 599px) max(300px, 138px)" src="https://storage.googleapis.com/leep_app_website/2021/05/gym-hoc-mon-01.jpg" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center top', objectFit: 'contain', imageRendering: 'auto'}} data-framer-original-sizes="300px" />
                          </div>
                        </div>
                      </div>
                      <div className="framer-1b9kwb0" style={{background: 'linear-gradient(118deg, rgb(231, 230, 236) 0%, rgb(243, 243, 250) 53.153153153153156%, rgba(208, 211, 236, 0.95) 100%)'}}>
                        <div className="framer-q1wo6h">
                          <div className="framer-la8i3p" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0,framerLinkTextColor: 'rgb(0, 153, 255)',framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                            <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTcwMA==',fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',fontSize: '24px',fontWeight: 700}} className="framer-text">
                              Hệ thống phòng GYM
                            </p>
                          </div>
                          <div className="framer-10vu670-container"><div style={{display: 'contents'}} /></div>
                        </div>
                        <div className="framer-w91f3h" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0,extractedR6o4lv: 'rgb(100, 106, 115)',framerLinkTextColor: 'rgb(0, 153, 255)',framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                          <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLXJlZ3VsYXI=',framerFontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',framerLineHeight: '1.52em',framerTextColor: 'var(--extracted-r6o4lv, rgb(100, 106, 115))'}} className="framer-text">
                           Việc chấm công nhân sự đã được tối ưu, phần mềm hỗ trợ tính lương tự động giúp chúng tôi giảm nhân sự kế toán ...
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </li>
              <li style={{scrollSnapAlign: 'center', flexShrink: 0, scrollSnapStop: 'always'}} aria-label="3 of 4">
                <div className="framer-1ah52kp-container" data-framer-name="Manufacturing" name="Manufacturing">
                  <div name="Manufacturing" className="framer-QTK3Z framer-108qzes framer-v-1cvqz0r" data-framer-name="JP - AIGC" style={{backgroundColor: 'rgb(246, 246, 251)', borderBottomLeftRadius: '16px', borderBottomRightRadius: '16px', borderTopLeftRadius: '16px', borderTopRightRadius: '16px', height: '100%', width: '100%'}} tabIndex={0}>
                    <a className="framer-9mwylm framer-18qcco1" href="solutions/usecase/jp-manufacturing.html" target="_blank" rel="noopener">
                      <div className="framer-5u990a">
                        <div className="framer-179ilkj" data-framer-name="Image">
                          <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                            <img decoding="async" sizes="(min-width: 1240px) 300px, (min-width: 905px) and (max-width: 1239px) 300px, (min-width: 600px) and (max-width: 904px) max(300px, 138px), (max-width: 599px) max(300px, 138px)" src="https://ikigroup.vn/images/ikigroup-chinh-phuc-ta-chi-nhu-2.jpg" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center top', objectFit: 'contain', imageRendering: 'auto'}} data-framer-original-sizes="300px" />
                          </div>
                        </div>
                      </div>
                      <div className="framer-1b9kwb0" style={{background: 'linear-gradient(118deg, rgb(231, 230, 236) 0%, rgb(243, 243, 250) 53.153153153153156%, rgba(208, 211, 236, 0.95) 100%)'}}>
                        <div className="framer-q1wo6h">
                          <div className="framer-la8i3p" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0,framerLinkTextColor: 'rgb(0, 153, 255)',framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                            <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTcwMA==',fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',fontSize: '24px',fontWeight: 700}} className="framer-text">
                             IKIGROUP
                            </p>
                          </div>
                          <div className="framer-10vu670-container"><div style={{display: 'contents'}} /></div>
                        </div>
                        <div className="framer-w91f3h" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0,extractedR6o4lv: 'rgb(100, 106, 115)',framerLinkTextColor: 'rgb(0, 153, 255)',framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                          <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLXJlZ3VsYXI=',framerFontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',framerLineHeight: '1.52em',framerTextColor: 'var(--extracted-r6o4lv, rgb(100, 106, 115))'}} className="framer-text">
                           Phần mềm giúp chúng tôi quản lý nhân viên tốt hơn...
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </li>
              {/* <li style={{scrollSnapAlign: 'center', flexShrink: 0, scrollSnapStop: 'always'}} aria-label="4 of 4">
                <div className="framer-ov0q92-container" data-framer-name="Hospitality" name="Hospitality">
                  <div name="Hospitality" className="framer-QTK3Z framer-108qzes framer-v-1cvqz0r" data-framer-name="JP - AIGC" style={{backgroundColor: 'rgb(246, 246, 251)', borderBottomLeftRadius: '16px', borderBottomRightRadius: '16px', borderTopLeftRadius: '16px', borderTopRightRadius: '16px', height: '100%', width: '100%'}} tabIndex={0}>
                    <a className="framer-9mwylm framer-18qcco1" href="ja_jp/blog/hospitalitydx.html" target="_blank" rel="noopener">
                      <div className="framer-5u990a">
                        <div className="framer-179ilkj" data-framer-name="Image">
                          <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                            <img decoding="async" sizes="(min-width: 1240px) 300px, (min-width: 905px) and (max-width: 1239px) 300px, (min-width: 600px) and (max-width: 904px) max(300px, 138px), (max-width: 599px) max(300px, 138px)" srcSet="
                                                                        https://framerusercontent.com/images/SYwJKDfQOzyIMs36se34QUJztnY.png?scale-down-to=512 512w,
                                                                        https://framerusercontent.com/images/SYwJKDfQOzyIMs36se34QUJztnY.png                   552w
                                                                    " src="https://framerusercontent.com/images/SYwJKDfQOzyIMs36se34QUJztnY.png" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center top', objectFit: 'contain', imageRendering: 'auto'}} data-framer-original-sizes="300px" />
                          </div>
                        </div>
                      </div>
                      <div className="framer-1b9kwb0" style={{background: 'linear-gradient(118deg, rgb(231, 230, 236) 0%, rgb(243, 243, 250) 53.153153153153156%, rgba(208, 211, 236, 0.95) 100%)'}}>
                        <div className="framer-q1wo6h">
                          <div className="framer-la8i3p" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0,framerLinkTextColor: 'rgb(0, 153, 255)',framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                            <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTcwMA==',framerFontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',framerFontSize: '24px',framerFontWeight: 700}} className="framer-text">
                              宿泊
                            </p>
                          </div>
                          <div className="framer-10vu670-container"><div style={{display: 'contents'}} /></div>
                        </div>
                        <div className="framer-w91f3h" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0,extractedR6o4lv: 'rgb(100, 106, 115)',framerLinkTextColor: 'rgb(0, 153, 255)',framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                          <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLXJlZ3VsYXI=',framerFontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',framerLineHeight: '1.52em',framerTextColor: 'var(--extracted-r6o4lv, rgb(100, 106, 115))'}} className="framer-text">
                            旅館やホテルの業務効率化。
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </li> */}
            </ul>
            {/* <fieldset style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, pointerEvents: 'none', border: 0, padding: '20px', margin: 0, flexDirection: 'row'}} aria-label="Carousel pagination controls" className="framer--carousel-controls" data-show-mouse-controls="true">
              <button type="button" style={{border: 'none', display: 'block', placeContent: 'center', placeItems: 'center', overflow: 'hidden', background: 'transparent', margin: 0, padding: 0, backgroundColor: 'rgba(0, 0, 0, 0.2)', width: '40px', height: '40px', borderRadius: '40px', cursor: 'default', opacity: 0, pointerEvents: 'none', transform: 'none'}} aria-label="Previous" tabIndex={0}>
                <img alt="" width={40} height={40} src="https://framerusercontent.com/images/6tTbkXggWgQCAJ4DO2QEdXXmgM.svg" />
              </button>
              <button type="button" style={{border: 'none', display: 'block', placeContent: 'center', placeItems: 'center', overflow: 'hidden', background: 'transparent', margin: 0, padding: 0, backgroundColor: 'rgba(0, 0, 0, 0.2)', width: '40px', height: '40px', borderRadius: '40px', cursor: 'pointer', opacity: 1, pointerEvents: 'auto', transform: 'none'}} aria-label="Next" tabIndex={0}>
                <img alt="" width={40} height={40} src="https://framerusercontent.com/images/11KSGbIZoRSg4pjdnUoif6MKHI.svg" />
              </button>
            </fieldset> */}
            <div />
          </section>
        </div>
      </div>
     );
}

export default Section4Component;