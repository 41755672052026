function PricingComponent() {
    return ( 
        <section className="framer-s3b9ci" data-framer-name="Section Pricing" name="Section Pricing" style={{paddingBottom: 70}}>
        <div className="ssr-variant hidden-9i23oi hidden-150dn05 hidden-q2nkjo">
          <div className="framer-1le8kqx-container">
            <div className="framer-5PS9b framer-xyoGX framer-j45eni framer-v-xgwfre" data-framer-name="JP-Desktop-JPlocalized" style={{width: '100%'}} tabIndex={0}>
              <div className="framer-12ngtcs" style={{outline: 'none', display: 'flex', flexDirection: 'column',height: 56, justifyContent: 'flex-start', flexShrink: 0, extractedGdpscs: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                <h3 className="framer-text framer-styles-preset-1k1rjey" data-styles-preset="iT7awcTns"><strong className="framer-text">Bảng giá</strong></h3>
              </div>
              <div className="framer-ww4un5">
                <div className="framer-zpi2od-container">
                  <div className="framer-6pgH8 framer-nmafvn framer-v-qt5zkq" data-framer-name="JP-Desktop-JPlocalized" style={{backgroundColor: 'rgb(246, 246, 251)', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', boxShadow: '0px 8px 24px 8px rgba(31, 35, 41, 0.04), 0px 6px 12px 0px rgba(31, 35, 41, 0.04), 0px 4px 8px -8px rgba(31, 35, 41, 0.06)', height: '100%', width: '100%'}} tabIndex={0}>
                    <div className="framer-10qizo0" data-framer-name="icon_score_filled" />
                    <div className="framer-y5z7ta">
                      <div className="framer-euwgtc-container">
                        <div className="framer-kdPcG framer-gwGpF framer-v-wwjzt8" style={{display: 'contents'}} tabIndex={0}>
                          <a className="framer-a0lxbi framer-1m5gekm" data-framer-name="btn_JP_CTA_secondary" data-highlight="true" style={{borderBottomWidth: '2px', borderColor: 'var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255)) /* {"name":"Primary Blue"} */', borderLeftWidth: '2px', borderRightWidth: '2px', borderStyle: 'solid', borderTopWidth: '2px', backgroundColor: 'var(--token-169df4fe-d50e-4b43-be91-166ff06866ac, rgb(255, 255, 255))', borderBottomLeftRadius: '80px', borderBottomRightRadius: '80px', borderTopLeftRadius: '80px', borderTopRightRadius: '80px', boxShadow: 'inset 0px 1px 1px 0px hsla(0, 0%, 100%, 0.25)', width: '100%'}} data-border="true" href="global/registerecd3.html?redirect_uri=https://www.larksuite.com/ja_jp/download&registration_process=global_register&app_id=1001&from=home_plans_starter" rel="noopener" tabIndex={0}>
                            <div className="framer-ue8ntn" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', framerParagraphSpacing: '0px', extractedR6o4lv: 'var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255))', transform: 'none'}} data-framer-component-type="RichTextContainer">
                              <p style={{fontSelector: 'Q1VTVE9NO05vdG8gU2FucyBKUCBCb2xk', fontFamily: '"Noto Sans JP Bold", "Noto Sans JP Bold Placeholder", sans-serif', fontSize: '20px', lineHeight: '1.4em', color: 'var(--extracted-r6o4lv, var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255)))'}} className="framer-text">
                                Đăng ký
                              </p>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div className="framer-gqct46" data-framer-name="Title">
                        <div className="framer-ox9qzn" data-framer-name="plan name">
                          <div className="framer-j7fijr" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))', transform: 'none'}} data-framer-component-type="RichTextContainer">
                            <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTcwMA==', fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', fontSize: '20px', fontWeight: 700, lineHeight: '1.5em', textAlign: 'left', color: 'var(--extracted-r6o4lv, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))'}} className="framer-text">
                              Gói STARTER
                            </p>
                          </div>
                          
                        </div>
                        <div className="framer-qtn1up">
  <div className="framer-ywirf8" data-framer-name="price">
    <div 
      className="framer-ddn82k" 
      style={{
        outline: 'none',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        flexShrink: 0,
        extractedR6o4lv: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))',
        transform: 'none'
      }} 
      data-framer-component-type="RichTextContainer"
    >
      <p 
        style={{
          fontFamily: '"Noto Sans JP", sans-serif',
          fontSize: '30px',
          fontWeight: 900,
          lineHeight: '40px',
          textAlign: 'left',
          color: 'var(--extracted-r6o4lv, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))'
        }} 
        className="framer-text"
      >
        2.000.000 VNĐ
      </p>
    </div>
  </div>
</div>

                      </div>
                    </div>
                    <div className="framer-77b05d" data-framer-name="divider" style={{background: 'linear-gradient(90deg, rgb(65, 164, 255) 0%, rgb(99, 143, 255) 51.35135135135135%, rgb(149, 84, 255) 100%)', opacity: '0.5'}} />
                    <div className="framer-9q1a1r" data-framer-name="Details">
  <div className="framer-15o0h5o" data-framer-name="first">
    <div className="framer-myc1kj" data-framer-name="Frame 1565846">
      <div 
        className="framer-1tvum4y" 
        style={{
          outline: 'none',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          flexShrink: 0,
          color: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))',
          transform: 'none'
        }} 
        data-framer-component-type="RichTextContainer"
      >
        <p 
          style={{
            fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
            fontSize: '14px',
            lineHeight: '22px',
            textAlign: 'left',
            color: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'
          }} 
          className="framer-text"
        >
        </p>
      </div>
    </div>
    <div className="framer-dtwkz" data-framer-name="Frame 1565846">
      <div className="framer-rd88st">
        <div 
          style={{
            position: 'absolute',
            borderRadius: 'inherit',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }} 
          data-framer-background-image-wrapper="true"
        >
          <img 
            decoding="async" 
            src="https://framerusercontent.com/images/cOaz3GHgCa2AI5K2LccgvEFPSs.svg" 
            alt="" 
            style={{
              display: 'block',
              width: '100%',
              height: '100%',
              borderRadius: 'inherit',
              objectPosition: 'center',
              objectFit: 'contain',
              imageRendering: 'auto'
            }} 
          />
        </div>
      </div>
      <div 
        className="framer-5urwie" 
        style={{
          outline: 'none',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          flexShrink: 0,
          color: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))',
          transform: 'none'
        }} 
        data-framer-component-type="RichTextContainer"
      >
        <p 
          style={{
            fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
            fontSize: '14px',
            lineHeight: '22px',
            textAlign: 'left',
            color: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'
          }} 
          className="framer-text"
        >
          Tính năng quản lý nhân sự
        </p>
      </div>
    </div>
    <div className="framer-qowhgc" data-framer-name="Frame 1565846">
      <div className="framer-1i2t9z2">
        <div 
          style={{
            position: 'absolute',
            borderRadius: 'inherit',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }} 
          data-framer-background-image-wrapper="true"
        >
          <img 
            decoding="async" 
            src="https://framerusercontent.com/images/cOaz3GHgCa2AI5K2LccgvEFPSs.svg" 
            alt="" 
            style={{
              display: 'block',
              width: '100%',
              height: '100%',
              borderRadius: 'inherit',
              objectPosition: 'center',
              objectFit: 'contain',
              imageRendering: 'auto'
            }} 
          />
        </div>
      </div>
      <div 
        className="framer-1quvcrl" 
        style={{
          outline: 'none',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          flexShrink: 0,
          color: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))',
          transform: 'none'
        }} 
        data-framer-component-type="RichTextContainer"
      >
        <p 
          style={{
            fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
            fontSize: '14px',
            lineHeight: '22px',
            textAlign: 'left',
            color: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'
          }} 
          className="framer-text"
        >
          10 người dùng
        </p>
      </div>
    </div>
    <div className="framer-1j90z6j" data-framer-name="Frame 1565846">
      <div 
        className="framer-k6g3f1" 
        style={{
          outline: 'none',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          flexShrink: 0,
          color: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))',
          transform: 'none'
        }} 
        data-framer-component-type="RichTextContainer"
      >
        <p 
          style={{
            fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
            fontSize: '14px',
            lineHeight: '22px',
            textAlign: 'left',
            color: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'
          }} 
          className="framer-text"
        >
          Bộ nhớ 10 GB
        </p>
      </div>
      <div className="framer-1895xm7">
        <div 
          style={{
            position: 'absolute',
            borderRadius: 'inherit',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }} 
          data-framer-background-image-wrapper="true"
        >
          <img 
            decoding="async" 
            src="https://framerusercontent.com/images/cOaz3GHgCa2AI5K2LccgvEFPSs.svg" 
            alt="" 
            style={{
              display: 'block',
              width: '100%',
              height: '100%',
              borderRadius: 'inherit',
              objectPosition: 'center',
              objectFit: 'contain',
              imageRendering: 'auto'
            }} 
          />
        </div>
      </div>
    </div>
  </div>
</div>

                  </div>
                </div>
                <div className="framer-zpi2od-container">
                  <div className="framer-6pgH8 framer-nmafvn framer-v-qt5zkq" data-framer-name="JP-Desktop-JPlocalized" style={{backgroundColor: 'rgb(246, 246, 251)', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', boxShadow: '0px 8px 24px 8px rgba(31, 35, 41, 0.04), 0px 6px 12px 0px rgba(31, 35, 41, 0.04), 0px 4px 8px -8px rgba(31, 35, 41, 0.06)', height: '100%', width: '100%'}} tabIndex={0}>
                    <div className="framer-10qizo0" data-framer-name="icon_score_filled" />
                    <div className="framer-y5z7ta">
                      <div className="framer-euwgtc-container">
                        <div className="framer-kdPcG framer-gwGpF framer-v-wwjzt8" style={{display: 'contents'}} tabIndex={0}>
                          <a className="framer-a0lxbi framer-1m5gekm" data-framer-name="btn_JP_CTA_secondary" data-highlight="true" style={{borderBottomWidth: '2px', borderColor: 'var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255)) /* {"name":"Primary Blue"} */', borderLeftWidth: '2px', borderRightWidth: '2px', borderStyle: 'solid', borderTopWidth: '2px', backgroundColor: 'var(--token-169df4fe-d50e-4b43-be91-166ff06866ac, rgb(255, 255, 255))', borderBottomLeftRadius: '80px', borderBottomRightRadius: '80px', borderTopLeftRadius: '80px', borderTopRightRadius: '80px', boxShadow: 'inset 0px 1px 1px 0px hsla(0, 0%, 100%, 0.25)', width: '100%'}} data-border="true" href="global/registerecd3.html?redirect_uri=https://www.larksuite.com/ja_jp/download&registration_process=global_register&app_id=1001&from=home_plans_starter" rel="noopener" tabIndex={0}>
                            <div className="framer-ue8ntn" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', framerParagraphSpacing: '0px', extractedR6o4lv: 'var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255))', transform: 'none'}} data-framer-component-type="RichTextContainer">
                              <p style={{fontSelector: 'Q1VTVE9NO05vdG8gU2FucyBKUCBCb2xk', fontFamily: '"Noto Sans JP Bold", "Noto Sans JP Bold Placeholder", sans-serif', fontSize: '20px', lineHeight: '1.4em', color: 'var(--extracted-r6o4lv, var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255)))'}} className="framer-text">
                                Đăng ký
                              </p>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div className="framer-gqct46" data-framer-name="Title">
                        <div className="framer-ox9qzn" data-framer-name="plan name">
                          <div className="framer-j7fijr" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))', transform: 'none'}} data-framer-component-type="RichTextContainer">
                            <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTcwMA==', fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', fontSize: '20px', fontWeight: 700, lineHeight: '1.5em', textAlign: 'left', color: 'var(--extracted-r6o4lv, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))'}} className="framer-text">
                              Gói PRO
                            </p>
                          </div>
                          
                        </div>
                        <div className="framer-qtn1up">
  <div className="framer-ywirf8" data-framer-name="price">
    <div 
      className="framer-ddn82k" 
      style={{
        outline: 'none',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        flexShrink: 0,
        extractedR6o4lv: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))',
        transform: 'none'
      }} 
      data-framer-component-type="RichTextContainer"
    >
      <p 
        style={{
          fontFamily: '"Noto Sans JP", sans-serif',
          fontSize: '30px',
          fontWeight: 900,
          lineHeight: '40px',
          textAlign: 'left',
          color: 'var(--extracted-r6o4lv, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))'
        }} 
        className="framer-text"
      >
        13.000.000 VNĐ
      </p>
    </div>
  </div>
</div>

                      </div>
                    </div>
                    <div className="framer-77b05d" data-framer-name="divider" style={{background: 'linear-gradient(90deg, rgb(65, 164, 255) 0%, rgb(99, 143, 255) 51.35135135135135%, rgb(149, 84, 255) 100%)', opacity: '0.5'}} />
                    <div className="framer-9q1a1r" data-framer-name="Details">
  <div className="framer-15o0h5o" data-framer-name="first">
    <div className="framer-myc1kj" data-framer-name="Frame 1565846">
      <div 
        className="framer-1tvum4y" 
        style={{
          outline: 'none',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          flexShrink: 0,
          color: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))',
          transform: 'none'
        }} 
        data-framer-component-type="RichTextContainer"
      >
        <p 
          style={{
            fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
            fontSize: '14px',
            lineHeight: '22px',
            textAlign: 'left',
            color: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'
          }} 
          className="framer-text"
        >
        </p>
      </div>
    </div>
    <div className="framer-dtwkz" data-framer-name="Frame 1565846">
      <div className="framer-rd88st">
        <div 
          style={{
            position: 'absolute',
            borderRadius: 'inherit',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }} 
          data-framer-background-image-wrapper="true"
        >
          <img 
            decoding="async" 
            src="https://framerusercontent.com/images/cOaz3GHgCa2AI5K2LccgvEFPSs.svg" 
            alt="" 
            style={{
              display: 'block',
              width: '100%',
              height: '100%',
              borderRadius: 'inherit',
              objectPosition: 'center',
              objectFit: 'contain',
              imageRendering: 'auto'
            }} 
          />
        </div>
      </div>
      <div 
        className="framer-5urwie" 
        style={{
          outline: 'none',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          flexShrink: 0,
          color: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))',
          transform: 'none'
        }} 
        data-framer-component-type="RichTextContainer"
      >
        <p 
          style={{
            fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
            fontSize: '14px',
            lineHeight: '22px',
            textAlign: 'left',
            color: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'
          }} 
          className="framer-text"
        >
          Tính năng quản lý nhân sự
        </p>
      </div>
    </div>
    <div className="framer-1sbbw11" data-framer-name="Frame 1565846">
      <div className="framer-1cph6eq">
        <div 
          style={{
            position: 'absolute',
            borderRadius: 'inherit',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }} 
          data-framer-background-image-wrapper="true"
        >
          <img 
            decoding="async" 
            src="https://framerusercontent.com/images/cOaz3GHgCa2AI5K2LccgvEFPSs.svg" 
            alt="" 
            style={{
              display: 'block',
              width: '100%',
              height: '100%',
              borderRadius: 'inherit',
              objectPosition: 'center',
              objectFit: 'contain',
              imageRendering: 'auto'
            }} 
          />
        </div>
      </div>
      <div 
        className="framer-gj51g7" 
        style={{
          outline: 'none',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          flexShrink: 0,
          color: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))',
          transform: 'none'
        }} 
        data-framer-component-type="RichTextContainer"
      >
        <p 
          style={{
            fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
            fontSize: '14px',
            lineHeight: '22px',
            textAlign: 'left',
            color: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'
          }} 
          className="framer-text"
        >
          Tính năng chấm công khuôn mặt
        </p>
      </div>
    </div>
    <div className="framer-9ug6o6" data-framer-name="Frame 1565846">
      <div 
        className="framer-1mdqt0n" 
        style={{
          outline: 'none',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          flexShrink: 0,
          color: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))',
          transform: 'none'
        }} 
        data-framer-component-type="RichTextContainer"
      >
        <p 
          style={{
            fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
            fontSize: '14px',
            lineHeight: '22px',
            textAlign: 'left',
            color: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'
          }} 
          className="framer-text"
        >
          Domain riêng
        </p>
      </div>
      <div className="framer-reo8cl">
        <div 
          style={{
            position: 'absolute',
            borderRadius: 'inherit',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }} 
          data-framer-background-image-wrapper="true"
        >
          <img 
            decoding="async" 
            src="https://framerusercontent.com/images/cOaz3GHgCa2AI5K2LccgvEFPSs.svg" 
            alt="" 
            style={{
              display: 'block',
              width: '100%',
              height: '100%',
              borderRadius: 'inherit',
              objectPosition: 'center',
              objectFit: 'contain',
              imageRendering: 'auto'
            }} 
          />
        </div>
      </div>
    </div>
    <div className="framer-qowhgc" data-framer-name="Frame 1565846">
      <div className="framer-1i2t9z2">
        <div 
          style={{
            position: 'absolute',
            borderRadius: 'inherit',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }} 
          data-framer-background-image-wrapper="true"
        >
          <img 
            decoding="async" 
            src="https://framerusercontent.com/images/cOaz3GHgCa2AI5K2LccgvEFPSs.svg" 
            alt="" 
            style={{
              display: 'block',
              width: '100%',
              height: '100%',
              borderRadius: 'inherit',
              objectPosition: 'center',
              objectFit: 'contain',
              imageRendering: 'auto'
            }} 
          />
        </div>
      </div>
      <div 
        className="framer-1quvcrl" 
        style={{
          outline: 'none',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          flexShrink: 0,
          color: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))',
          transform: 'none'
        }} 
        data-framer-component-type="RichTextContainer"
      >
        <p 
          style={{
            fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
            fontSize: '14px',
            lineHeight: '22px',
            textAlign: 'left',
            color: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'
          }} 
          className="framer-text"
        >
          100 + người dùng
        </p>
      </div>
    </div>
    <div className="framer-1j90z6j" data-framer-name="Frame 1565846">
      <div 
        className="framer-k6g3f1" 
        style={{
          outline: 'none',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          flexShrink: 0,
          color: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))',
          transform: 'none'
        }} 
        data-framer-component-type="RichTextContainer"
      >
        <p 
          style={{
            fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
            fontSize: '14px',
            lineHeight: '22px',
            textAlign: 'left',
            color: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'
          }} 
          className="framer-text"
        >
          Bộ nhớ 500 GB
        </p>
      </div>
      <div className="framer-1895xm7">
        <div 
          style={{
            position: 'absolute',
            borderRadius: 'inherit',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }} 
          data-framer-background-image-wrapper="true"
        >
          <img 
            decoding="async" 
            src="https://framerusercontent.com/images/cOaz3GHgCa2AI5K2LccgvEFPSs.svg" 
            alt="" 
            style={{
              display: 'block',
              width: '100%',
              height: '100%',
              borderRadius: 'inherit',
              objectPosition: 'center',
              objectFit: 'contain',
              imageRendering: 'auto'
            }} 
          />
        </div>
      </div>
    </div>
  </div>
</div>

                  </div>
                </div>
                
              </div>
             
            </div>
          </div>
        </div>
      </section>
      
     );
}

export default PricingComponent;