function CustomerComponent() {
    return ( 
        <div className="framer-12mcgpv" data-framer-name="Customer story" name="Customer story">
        <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
          <img decoding="async" loading="lazy" sizes="(min-width: 1240px) 100vw, (min-width: 905px) and (max-width: 1239px) 100vw, (min-width: 600px) and (max-width: 904px) 100vw, (max-width: 599px) 100vw" srcSet="
                                    https://framerusercontent.com/images/p6tE3NeNiaSy9sYy5altWsB9kDc.jpg?scale-down-to=512   512w,
                                    https://framerusercontent.com/images/p6tE3NeNiaSy9sYy5altWsB9kDc.jpg?scale-down-to=1024 1024w,
                                    https://framerusercontent.com/images/p6tE3NeNiaSy9sYy5altWsB9kDc.jpg?scale-down-to=2048 2048w,
                                    https://framerusercontent.com/images/p6tE3NeNiaSy9sYy5altWsB9kDc.jpg                    3840w
                                " src="https://framerusercontent.com/images/p6tE3NeNiaSy9sYy5altWsB9kDc.jpg" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center top', objectFit: 'cover', imageRendering: 'auto'}} data-framer-original-sizes="100vw" />
        </div>
        <div className="framer-80lkia">
          <div className="framer-1igqe7u" data-framer-name="Text" name="Text">
            <div className="framer-1utbipn" style={{outline: 'none', display: 'flex', flexDirection: 'column', height: 65, justifyContent: 'flex-start', flexShrink: 0, transform: 'none'}} data-framer-component-type="RichTextContainer">
              <h3 className="framer-text framer-styles-preset-1k1rjey" data-styles-preset="iT7awcTns">
                Chia sẻ của các CEO khi sử dụng OnCenter
              </h3>
            </div>
          </div>
        </div>
        <section className="framer-1xx2yms" data-framer-name="Section Customer" name="Section Customer">
          <div className="framer-1g48bs7" data-framer-name="Wrapper" name="Wrapper">
            <div className="framer-a4ysik" data-framer-name="Content" name="Content">
              <div className="ssr-variant hidden-q2nkjo">
                <div className="framer-1wrny3g-container">
                  <a className="framer-1HX4f framer-thvK1 framer-22t5bk framer-v-22t5bk framer-1y57cxu" data-framer-name="Variant 1" data-highlight="true" style={{borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', boxShadow: 'none', height: '100%', width: '100%'}} rel="noopener" tabIndex={0}>
                    <div className="framer-bn6kic" style={{backgroundColor: 'rgba(255, 255, 255, 0.9)', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px'}}>
                      <div className="framer-12xvsgr">
                        <div className="framer-1r4jmtf">
                          <div className="framer-104cv59" style={{borderBottomLeftRadius: '16px', borderBottomRightRadius: '16px', borderTopLeftRadius: '16px', borderTopRightRadius: '16px'}}>
                            <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                              <img decoding="async" sizes="122px" src="https://framerusercontent.com/images/oALcE2Dj6aIzPhDLnNzQAy2CNZo.png" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'cover', imageRendering: 'auto'}} />
                            </div>
                          </div>
                          <div className="framer-1n8ojpe">
                            <div className="framer-1c4uczn" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedA0htzi: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                              <h3 style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTcwMA==', fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', fontSize: '20px', fontWeight: 700, framerLineHeight: '1.5em', framerTextAlignment: 'left', color: 'var(--extracted-a0htzi, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))'}} className="framer-text">
                                Trần Đức Hoài
                              </h3>
                            </div>
                            <div className="framer-199i1uo" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedA0htzi: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                              <h3 style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTUwMA==', fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', fontWeight: 500, lineHeight: '1.5em', textAlign: 'center', color: 'var(--extracted-a0htzi, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'}} className="framer-text">
                                CEO Exam24h
                              </h3>
                            </div>
                            <div className="framer-13jjdu6">
                              <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                                <img decoding="async" sizes="120px" src="https://exam24h.com/images/question/banner.png" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'contain', imageRendering: 'auto'}} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="framer-77b05d" data-framer-name="divider" style={{background: 'linear-gradient(90deg, rgb(65, 164, 255) 0%, rgb(99, 143, 255) 51.35135135135135%, rgb(149, 84, 255) 100%)', opacity: '0.5'}} />
                      <div className="framer-1nzdbbn">
                        <div className="framer-ue73fo" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedA0htzi: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', framerParagraphSpacing: '8px', transform: 'none'}} data-framer-component-type="RichTextContainer">
                          <h3 style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLXJlZ3VsYXI=', fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', lineHeight: '24px', textAlign: 'left', color: 'var(--extracted-a0htzi, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))'}} className="framer-text">
                          Phần mềm quản lý nhân sự OnCenter phổ biến dành cho các doanh nghiệp nhỏ và vừa. Nó được thiết kế để đơn giản hóa quy trình quản lý nhân sự từ tuyển dụng, lưu trữ hồ sơ nhân viên đến theo dõi thời gian làm việc và quản lý phúc lợi.
                          </h3>
                        </div>
                      </div>
                      <div className="framer-vppp4b" data-framer-name="Global_Button" style={{borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px', borderTopLeftRadius: '6px', borderTopRightRadius: '6px'}}>
                        <div className="framer-1wjx6w8" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                          
                        </div>
                        <div className="framer-29iihe-container"><div style={{display: 'contents'}} /></div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="ssr-variant hidden-ollpel hidden-9i23oi hidden-150dn05">
                <div className="framer-1wrny3g-container">
                  <a className="framer-1HX4f framer-thvK1 framer-22t5bk framer-v-bubd2b framer-1y57cxu" data-framer-name="Mobile" data-highlight="true" style={{borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', boxShadow: 'none', height: '100%', width: '100%'}} rel="noopener" tabIndex={0}>
                    <div className="framer-bn6kic" style={{backgroundColor: 'rgba(255, 255, 255, 0.9)', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px'}}>
                      <div className="framer-12xvsgr">
                        <div className="framer-1r4jmtf">
                          <div className="framer-104cv59" style={{borderBottomLeftRadius: '16px', borderBottomRightRadius: '16px', borderTopLeftRadius: '16px', borderTopRightRadius: '16px'}}>
                            <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                              <img decoding="async" sizes="92px" src="https://framerusercontent.com/images/oALcE2Dj6aIzPhDLnNzQAy2CNZo.png" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'cover', imageRendering: 'auto'}} />
                            </div>
                          </div>
                          <div className="framer-1n8ojpe">
                            <div className="framer-1c4uczn" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedA0htzi: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                              <h3 style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTcwMA==', framerFontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', fontSize: '20px', fontWeight: 700, framerLineHeight: '1.5em', framerTextAlignment: 'left', framerTextColor: 'var(--extracted-a0htzi, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))'}} className="framer-text">
                                Trần Đức Hoài
                              </h3>
                            </div>
                            <div className="framer-199i1uo" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedA0htzi: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                              <h3 style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTUwMA==', fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', fFontWeight: 500, framerLineHeight: '1.5em', textAlign: 'center', color: 'var(--extracted-a0htzi, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'}} className="framer-text">
                               CEO Exam24h
                              </h3>
                            </div>
                            <div className="framer-13jjdu6">
                              <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                                <img decoding="async" sizes="90px" src="https://framerusercontent.com/images/2gk6RcW43lnA5IMT2kIMM5s4r0Q.png" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'contain', imageRendering: 'auto'}} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="framer-77b05d" data-framer-name="divider" style={{background: 'linear-gradient(90deg, rgb(65, 164, 255) 0%, rgb(99, 143, 255) 51.35135135135135%, rgb(149, 84, 255) 100%)', opacity: '0.5'}} />
                      <div className="framer-1nzdbbn">
                        <div className="framer-ue73fo" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedA0htzi: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', framerParagraphSpacing: '8px', transform: 'none'}} data-framer-component-type="RichTextContainer">
                          <h3 style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLXJlZ3VsYXI=', fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', lineHeight: '24px', textAlign: 'left', color: 'var(--extracted-a0htzi, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))'}} className="framer-text">
                          OnCenter là một giải pháp quản lý nhân sự toàn diện, phù hợp cho các doanh nghiệp lớn và tập đoàn. Nó cung cấp các công cụ hỗ trợ toàn bộ chu trình quản lý nhân sự từ tuyển dụng, đào tạo, đánh giá hiệu suất đến phát triển năng lực nhân viên.
                            </h3>
                        </div>
                      </div>
                      <div className="framer-vppp4b" data-framer-name="Global_Button" style={{borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px', borderTopLeftRadius: '6px', borderTopRightRadius: '6px'}}>
                        <div className="framer-1wjx6w8" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                         
                        </div>
                        <div className="framer-29iihe-container"><div style={{display: 'contents'}} /></div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="ssr-variant hidden-q2nkjo">
                <div className="framer-1x9bask-container">
                  <a className="framer-1HX4f framer-thvK1 framer-22t5bk framer-v-22t5bk framer-1y57cxu" data-framer-name="Variant 1" data-highlight="true" style={{borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', boxShadow: 'none', height: '100%', width: '100%'}} href="ja_jp/customer/stories/qqenglishe1db.html?from=home" rel="noopener" tabIndex={0}>
                    <div className="framer-bn6kic" style={{backgroundColor: 'rgba(255, 255, 255, 0.9)', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px'}}>
                      <div className="framer-12xvsgr">
                        <div className="framer-1r4jmtf">
                          <div className="framer-104cv59" style={{borderBottomLeftRadius: '16px', borderBottomRightRadius: '16px', borderTopLeftRadius: '16px', borderTopRightRadius: '16px'}}>
                            <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                              <img decoding="async" sizes="(min-width: 1240px) 122px, (min-width: 905px) and (max-width: 1239px) 122px, (min-width: 600px) and (max-width: 904px) 122px, (max-width: 599px) 92px" srcSet="
                                                                            https://framerusercontent.com/images/F8QcHdXck2F9nE8rIVApHx8ga4.webp?scale-down-to=512 512w,
                                                                            https://framerusercontent.com/images/F8QcHdXck2F9nE8rIVApHx8ga4.webp                   610w
                                                                        " src="https://framerusercontent.com/images/F8QcHdXck2F9nE8rIVApHx8ga4.webp" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'cover', imageRendering: 'auto'}} data-framer-original-sizes="122px" />
                            </div>
                          </div>
                          <div className="framer-1n8ojpe">
                            <div className="framer-1c4uczn" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedA0htzi: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                              <h3 style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTcwMA==', fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', fontSize: '20px', fontWeight: 700, framerLineHeight: '1.5em', framerTextAlignment: 'left', color: 'var(--extracted-a0htzi, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))'}} className="framer-text">
                               Vũ Văn An
                              </h3>
                            </div>
                            <div className="framer-199i1uo" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedA0htzi: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                              <h3 style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTUwMA==', fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', framerFontWeight: 500, framerLineHeight: '1.5em', textAlign: 'center', color: 'var(--extracted-a0htzi, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'}} className="framer-text">
                                Hệ thống học tiếng QQ English
                              </h3>
                            </div>
                            <div className="framer-13jjdu6">
                              <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                                <img decoding="async" sizes="120px" src="https://framerusercontent.com/images/8NkjUb91cboCON78E7A8TPCg1c.png" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'contain', imageRendering: 'auto'}} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="framer-77b05d" data-framer-name="divider" style={{background: 'linear-gradient(90deg, rgb(65, 164, 255) 0%, rgb(99, 143, 255) 51.35135135135135%, rgb(149, 84, 255) 100%)', opacity: '0.5'}} />
                      <div className="framer-1nzdbbn">
                        <div className="framer-ue73fo" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedA0htzi: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', framerParagraphSpacing: '8px', transform: 'none'}} data-framer-component-type="RichTextContainer">
                          <h3 style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLXJlZ3VsYXI=', fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', lineHeight: '24px', textAlign: 'left', color: 'var(--extracted-a0htzi, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))'}} className="framer-text">
                          OnCenter là một giải pháp quản lý nhân sự toàn diện, phù hợp cho các doanh nghiệp lớn và tập đoàn. Nó cung cấp các công cụ hỗ trợ toàn bộ chu trình quản lý nhân sự từ tuyển dụng, đào tạo, đánh giá hiệu suất đến phát triển năng lực nhân viên.
                            </h3>
                        </div>
                      </div>
                      <div className="framer-vppp4b" data-framer-name="Global_Button" style={{borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px', borderTopLeftRadius: '6px', borderTopRightRadius: '6px'}}>
                        <div className="framer-1wjx6w8" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                         
                        </div>
                        <div className="framer-29iihe-container"><div style={{display: 'contents'}} /></div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="ssr-variant hidden-ollpel hidden-9i23oi hidden-150dn05">
                <div className="framer-1x9bask-container">
                  <a className="framer-1HX4f framer-thvK1 framer-22t5bk framer-v-bubd2b framer-1y57cxu" data-framer-name="Mobile" data-highlight="true" style={{borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', boxShadow: 'none', height: '100%', width: '100%'}} rel="noopener" tabIndex={0}>
                    <div className="framer-bn6kic" style={{backgroundColor: 'rgba(255, 255, 255, 0.9)', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px'}}>
                      <div className="framer-12xvsgr">
                        <div className="framer-1r4jmtf">
                          <div className="framer-104cv59" style={{borderBottomLeftRadius: '16px', borderBottomRightRadius: '16px', borderTopLeftRadius: '16px', borderTopRightRadius: '16px'}}>
                            <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                              <img decoding="async" sizes="(min-width: 1240px) 122px, (min-width: 905px) and (max-width: 1239px) 122px, (min-width: 600px) and (max-width: 904px) 122px, (max-width: 599px) 92px" srcSet="
                                                                            https://framerusercontent.com/images/F8QcHdXck2F9nE8rIVApHx8ga4.webp?scale-down-to=512 512w,
                                                                            https://framerusercontent.com/images/F8QcHdXck2F9nE8rIVApHx8ga4.webp                   610w
                                                                        " src="https://framerusercontent.com/images/F8QcHdXck2F9nE8rIVApHx8ga4.webp" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'cover', imageRendering: 'auto'}} data-framer-original-sizes="92px" />
                            </div>
                          </div>
                          <div className="framer-1n8ojpe">
                            <div className="framer-1c4uczn" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedA0htzi: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                              <h3 style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTcwMA==', fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', fontSize: '20px', fontWeight: 700, lineHeight: '1.5em', textAlign: 'left', color: 'var(--extracted-a0htzi, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))'}} className="framer-text">
                                Vũ văn An
                              </h3>
                            </div>
                            <div className="framer-199i1uo" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedA0htzi: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                              <h3 style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTUwMA==', fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', fontWeight: 500, lineHeight: '1.5em', textAlign: 'center', color: 'var(--extracted-a0htzi, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'}} className="framer-text">
                                CEO QQ English
                              </h3>
                            </div>
                            <div className="framer-13jjdu6">
                              <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                                <img decoding="async" sizes="90px" src="https://framerusercontent.com/images/8NkjUb91cboCON78E7A8TPCg1c.png" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'contain', imageRendering: 'auto'}} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="framer-77b05d" data-framer-name="divider" style={{background: 'linear-gradient(90deg, rgb(65, 164, 255) 0%, rgb(99, 143, 255) 51.35135135135135%, rgb(149, 84, 255) 100%)', opacity: '0.5'}} />
                      <div className="framer-1nzdbbn">
                        <div className="framer-ue73fo" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedA0htzi: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', framerParagraphSpacing: '8px', transform: 'none'}} data-framer-component-type="RichTextContainer">
                          <h3 style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLXJlZ3VsYXI=', fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', lineHeight: '24px', textAlign: 'left', color: 'var(--extracted-a0htzi, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))'}} className="framer-text">
                          OnCenter là một phần mềm quản lý nhân sự (HR) phổ biến dành cho các doanh nghiệp nhỏ và vừa. Nó được thiết kế để đơn giản hóa quy trình quản lý nhân sự từ tuyển dụng, lưu trữ hồ sơ nhân viên đến theo dõi thời gian làm việc và quản lý phúc lợi.
                            </h3>
                        </div>
                      </div>
                      <div className="framer-vppp4b" data-framer-name="Global_Button" style={{borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px', borderTopLeftRadius: '6px', borderTopRightRadius: '6px'}}>
                        <div className="framer-1wjx6w8" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                         
                        </div>
                        <div className="framer-29iihe-container"><div style={{display: 'contents'}} /></div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="ssr-variant hidden-q2nkjo">
                <div className="framer-1rinji6-container">
                  <a className="framer-1HX4f framer-thvK1 framer-22t5bk framer-v-22t5bk framer-1y57cxu" data-framer-name="Variant 1" data-highlight="true" style={{borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', boxShadow: 'none', height: '100%', width: '100%'}} rel="noopener" tabIndex={0}>
                    <div className="framer-bn6kic" style={{backgroundColor: 'rgba(255, 255, 255, 0.9)', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px'}}>
                      <div className="framer-12xvsgr">
                        <div className="framer-1r4jmtf">
                          <div className="framer-104cv59" style={{borderBottomLeftRadius: '16px', borderBottomRightRadius: '16px', borderTopLeftRadius: '16px', borderTopRightRadius: '16px'}}>
                            <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                              <img decoding="async" sizes="(min-width: 1240px) 122px, (min-width: 905px) and (max-width: 1239px) 122px, (min-width: 600px) and (max-width: 904px) 122px, (max-width: 599px) 92px" src="https://cafebiz.cafebizcdn.vn/162123310254002176/2021/8/20/2-15610377384801271542157-16294226808261577231904.jpg" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'cover', imageRendering: 'auto'}} data-framer-original-sizes="122px" />
                            </div>
                          </div>
                          <div className="framer-1n8ojpe">
                            <div className="framer-1c4uczn" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedA0htzi: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                              <h3 style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTcwMA==', fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', fontSize: '20px', fontWeight: 700, lineHeight: '1.5em', textAlign: 'left', color: 'var(--extracted-a0htzi, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))'}} className="framer-text">
                                Cộng Cafe
                              </h3>
                            </div>
                            <div className="framer-199i1uo" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedA0htzi: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                              <h3 style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTUwMA==', fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', fontWeight: 500, lineHeight: '1.5em', textAlign: 'center', color: 'var(--extracted-a0htzi, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'}} className="framer-text">
                                HR Director
                              </h3>
                            </div>
                            <div className="framer-13jjdu6">
                              <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                                <img decoding="async" sizes="(min-width: 1240px) max(120px, calc(min(126px, 100vw) - 26px)), (min-width: 905px) and (max-width: 1239px) max(120px, calc(min(126px, 100vw) - 26px)), (min-width: 600px) and (max-width: 904px) max(120px, calc(min(126px, 100vw) - 26px)), (max-width: 599px) max(90px, calc(min(126px, 100vw) - 26px))" s src="https://1office.vn/wp-content/uploads/2019/07/c%E1%BB%99ng-1.jpg" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'contain', imageRendering: 'auto'}} data-framer-original-sizes="120px" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="framer-77b05d" data-framer-name="divider" style={{background: 'linear-gradient(90deg, rgb(65, 164, 255) 0%, rgb(99, 143, 255) 51.35135135135135%, rgb(149, 84, 255) 100%)', opacity: '0.5'}} />
                      <div className="framer-1nzdbbn">
                        <div className="framer-ue73fo" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedA0htzi: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', framerParagraphSpacing: '8px', transform: 'none'}} data-framer-component-type="RichTextContainer">
                          <h3 style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLXJlZ3VsYXI=', fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', lineHeight: '24px', textAlign: 'left', color: 'var(--extracted-a0htzi, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))'}} className="framer-text">
                            
                      OnCenter là một nền tảng quản lý nhân sự mạnh tại Việt Nam - nền tảng này áp dụng các công nghệ rất mới cho F&B giúp cho giải quyết nhiều bài toán quản lý phức tạp tại Cộng Cafe. Sau khi đã dùng rất nhiều phần mềm chúng tôi đã tin tưởng lựa chọn và sử dụng OnCenter như đối tác uy tín để chuyển đổi số hoạt động HR của công ty. 
                    </h3>
                        </div>
                      </div>
                      <div className="framer-vppp4b" data-framer-name="Global_Button" style={{borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px', borderTopLeftRadius: '6px', borderTopRightRadius: '6px'}}>
                        <div className="framer-1wjx6w8" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                         
                        </div>
                        <div className="framer-29iihe-container"><div style={{display: 'contents'}} /></div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="ssr-variant hidden-ollpel hidden-9i23oi hidden-150dn05">
                <div className="framer-1rinji6-container">
                  <a className="framer-1HX4f framer-thvK1 framer-22t5bk framer-v-bubd2b framer-1y57cxu" data-framer-name="Mobile" data-highlight="true" style={{borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', boxShadow: 'none', height: '100%', width: '100%'}} href="ja_jp/customer/stories/ever-brewe1db.html?from=home" rel="noopener" tabIndex={0}>
                    <div className="framer-bn6kic" style={{backgroundColor: 'rgba(255, 255, 255, 0.9)', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px'}}>
                      <div className="framer-12xvsgr">
                        <div className="framer-1r4jmtf">
                          <div className="framer-104cv59" style={{borderBottomLeftRadius: '16px', borderBottomRightRadius: '16px', borderTopLeftRadius: '16px', borderTopRightRadius: '16px'}}>
                            <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                              <img decoding="async" sizes="(min-width: 1240px) 122px, (min-width: 905px) and (max-width: 1239px) 122px, (min-width: 600px) and (max-width: 904px) 122px, (max-width: 599px) 92px" src="https://cafebiz.cafebizcdn.vn/162123310254002176/2021/8/20/2-15610377384801271542157-16294226808261577231904.jpg" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'cover', imageRendering: 'auto'}} data-framer-original-sizes="92px" />
                            </div>
                          </div>
                          <div className="framer-1n8ojpe">
                            <div className="framer-1c4uczn" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedA0htzi: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                              <h3 style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTcwMA==', fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', fontSize: '20px', fontWeight: 700, lineHeight: '1.5em', textAlign: 'left', color: 'var(--extracted-a0htzi, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))'}} className="framer-text">
                                Cộng Cafe
                              </h3>
                            </div>
                            <div className="framer-199i1uo" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedA0htzi: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                              <h3 style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTUwMA==', fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', fontWeight: 500, lineHeight: '1.5em', textAlign: 'center', color: 'var(--extracted-a0htzi, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'}} className="framer-text">
                                HR Director
                              </h3>
                            </div>
                            <div className="framer-13jjdu6">
                              <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                                <img decoding="async" sizes="(min-width: 1240px) max(120px, calc(min(126px, 100vw) - 26px)), (min-width: 905px) and (max-width: 1239px) max(120px, calc(min(126px, 100vw) - 26px)), (min-width: 600px) and (max-width: 904px) max(120px, calc(min(126px, 100vw) - 26px)), (max-width: 599px) max(90px, calc(min(126px, 100vw) - 26px))" src="https://1office.vn/wp-content/uploads/2019/07/c%E1%BB%99ng-1.jpg" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'contain', imageRendering: 'auto'}} data-framer-original-sizes="90px" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="framer-77b05d" data-framer-name="divider" style={{background: 'linear-gradient(90deg, rgb(65, 164, 255) 0%, rgb(99, 143, 255) 51.35135135135135%, rgb(149, 84, 255) 100%)', opacity: '0.5'}} />
                      <div className="framer-1nzdbbn">
                        <div className="framer-ue73fo" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedA0htzi: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', framerParagraphSpacing: '8px', transform: 'none'}} data-framer-component-type="RichTextContainer">
                          <h3 style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLXJlZ3VsYXI=', fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', lineHeight: '24px', textAlign: 'left', color: 'var(--extracted-a0htzi, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))'}} className="framer-text">
                          OnCenter là một nền tảng quản lý nhân sự mạnh tại Việt Nam - nền tảng này áp dụng các công nghệ rất mới cho F&B giúp cho giải quyết nhiều bài toán quản lý phức tạp tại Cộng Cafe. Sau khi đã dùng rất nhiều phần mềm chúng tôi đã tin tưởng lựa chọn và sử dụng OnCenter như đối tác uy tín để chuyển đổi số hoạt động HR của công ty.
                            </h3>
                        </div>
                      </div>
                      <div className="framer-vppp4b" data-framer-name="Global_Button" style={{borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px', borderTopLeftRadius: '6px', borderTopRightRadius: '6px'}}>
                        <div className="framer-1wjx6w8" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                          
                        </div>
                        <div className="framer-29iihe-container"><div style={{display: 'contents'}} /></div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="framer-1v2guga hidden-q2nkjo" data-framer-name="Company Logos" name="Company Logos">
        <div className="framer-mk0qce-container">
          <div className="framer-pjNLU framer-v-vld1wf" style={{display: 'contents'}} tabIndex={0}>
            <a className="framer-vld1wf framer-1tyxgpt" data-framer-name="Variant 1" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px', boxShadow: 'none'}} href="ja_jp/customer/stories/tokyu.html" target="_blank" rel="noopener">
              <div className="framer-ao0x9g">
                <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                  <img decoding="async" sizes="calc(min(126px, 100vw) - 26px)" src="https://framerusercontent.com/images/djJHA8pfQN7pHZxgjtd1UE7zRSw.svg" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'contain', imageRendering: 'auto'}} />
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="framer-1i9ch64-container">
          <div className="framer-pjNLU framer-v-18x4xwr" style={{display: 'contents'}} tabIndex={0}>
            <a className="framer-vld1wf framer-1tyxgpt" data-framer-name="w/o link" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px', boxShadow: 'none'}}>
              <div className="framer-ao0x9g">
                <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                  <img decoding="async" sizes="calc(min(126px, 100vw) - 26px)" src="https://framerusercontent.com/images/l8D8nAIYK0gmFAkaTWJ8jTkbfq8.png" alt="Wagyu Mafia" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'contain', imageRendering: 'auto'}} />
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="framer-1a8ndka-container">
          <div className="framer-pjNLU framer-v-18x4xwr" style={{display: 'contents'}} tabIndex={0}>
            <a className="framer-vld1wf framer-1tyxgpt" data-framer-name="w/o link" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px', boxShadow: 'none'}}>
              <div className="framer-ao0x9g">
                <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                  <img decoding="async" sizes="calc(min(126px, 100vw) - 26px)" src="https://framerusercontent.com/images/RV2Um1pmtCrRNwe9dR5bJSwoEXQ.svg" alt="バーガーキング" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'contain', imageRendering: 'auto'}} />
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="framer-fxuisk-container">
          <div className="framer-pjNLU framer-v-18x4xwr" style={{display: 'contents'}} tabIndex={0}>
            <a className="framer-vld1wf framer-1tyxgpt" data-framer-name="w/o link" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px', boxShadow: 'none'}}>
              <div className="framer-ao0x9g">
                <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                  <img decoding="async" sizes="calc(min(126px, 100vw) - 26px)" src="https://framerusercontent.com/images/iSMIstBXSQPJTDWC16ObiaZ2o.svg" alt="三和交通" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'contain', imageRendering: 'auto'}} />
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="framer-yltbc5-container">
          <div className="framer-pjNLU framer-v-vld1wf" style={{display: 'contents'}} tabIndex={0}>
            <a className="framer-vld1wf framer-1tyxgpt" data-framer-name="Variant 1" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px', boxShadow: 'none'}} href="ja_jp/customer/stories/syomikai.html" target="_blank" rel="noopener">
              <div className="framer-ao0x9g">
                <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                  <img decoding="async" sizes="calc(min(126px, 100vw) - 26px)" src="https://framerusercontent.com/images/0VTfHF6s66OcAfBYr7Bjg1aFhQs.svg" alt="翔未会" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'contain', imageRendering: 'auto'}} />
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="framer-q9zkny-container">
          <div className="framer-pjNLU framer-v-vld1wf" style={{display: 'contents'}} tabIndex={0}>
            <a className="framer-vld1wf framer-1tyxgpt" data-framer-name="Variant 1" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px', boxShadow: 'none'}} href="ja_jp/customer/stories/komano.html" target="_blank" rel="noopener">
              <div className="framer-ao0x9g">
                <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                  <img decoding="async" sizes="(min-width: 1240px) calc(min(126px, 100vw) - 26px), (min-width: 905px) and (max-width: 1239px) calc(min(126px, 100vw) - 26px), (min-width: 600px) and (max-width: 904px) calc(min(126px, 100vw) - 26px), (max-width: 599px) calc(min(126px, 100vw) - 26px)" srcSet="
                                                            https://framerusercontent.com/images/srhILu8XYfoVezMFblAm4LGhs.png?scale-down-to=512   512w,
                                                            https://framerusercontent.com/images/srhILu8XYfoVezMFblAm4LGhs.png?scale-down-to=1024 1024w,
                                                            https://framerusercontent.com/images/srhILu8XYfoVezMFblAm4LGhs.png                    1440w
                                                        " src="https://framerusercontent.com/images/srhILu8XYfoVezMFblAm4LGhs.png" alt="Bar Moon Walk" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'contain', imageRendering: 'auto'}} data-framer-original-sizes="calc(min(126px, 100vw) - 26px)" />
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="framer-1mie4ja-container">
          <div className="framer-pjNLU framer-v-vld1wf" style={{display: 'contents'}} tabIndex={0}>
            <a className="framer-vld1wf framer-1tyxgpt" data-framer-name="Variant 1" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px', boxShadow: 'none'}} href="ja_jp/customer/stories/hokkaido-chamber-of-commerce.html" target="_blank" rel="noopener">
              <div className="framer-ao0x9g">
                <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                  <img decoding="async" sizes="calc(min(126px, 100vw) - 26px)" src="https://framerusercontent.com/images/rtBSrOaT0KZr5NdSJK26dv3lSM.svg" alt="北海道新冠町商工会" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'contain', imageRendering: 'auto'}} />
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="framer-y3264q-container">
          <div className="framer-pjNLU framer-v-vld1wf" style={{display: 'contents'}} tabIndex={0}>
            <a className="framer-vld1wf framer-1tyxgpt" data-framer-name="Variant 1" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px', boxShadow: 'none'}} href="ja_jp/customer/stories/imawa.html" target="_blank" rel="noopener">
              <div className="framer-ao0x9g">
                <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                  <img decoding="async" sizes="(min-width: 1240px) calc(min(126px, 100vw) - 26px), (min-width: 905px) and (max-width: 1239px) calc(min(126px, 100vw) - 26px), (min-width: 600px) and (max-width: 904px) calc(min(126px, 100vw) - 26px), (max-width: 599px) calc(min(126px, 100vw) - 26px)" srcSet="https://framerusercontent.com/images/cfEmPyuR8jtLCO3Gbus3VYCUyY.jpeg?scale-down-to=512 512w, https://framerusercontent.com/images/cfEmPyuR8jtLCO3Gbus3VYCUyY.jpeg 521w" src="https://framerusercontent.com/images/cfEmPyuR8jtLCO3Gbus3VYCUyY.jpeg" alt="今和教育" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'contain', imageRendering: 'auto'}} data-framer-original-sizes="calc(min(126px, 100vw) - 26px)" />
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="framer-1pq6b0l-container">
          <div className="framer-pjNLU framer-v-vld1wf" style={{display: 'contents'}} tabIndex={0}>
            <a className="framer-vld1wf framer-1tyxgpt" data-framer-name="Variant 1" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px', boxShadow: 'none'}} href="ja_jp/customer/stories/qqenglish.html" target="_blank" rel="noopener">
              <div className="framer-ao0x9g">
                <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                  <img decoding="async" sizes="(min-width: 1240px) calc(min(126px, 100vw) - 26px), (min-width: 905px) and (max-width: 1239px) calc(min(126px, 100vw) - 26px), (min-width: 600px) and (max-width: 904px) calc(min(126px, 100vw) - 26px), (max-width: 599px) calc(min(126px, 100vw) - 26px)" srcSet="https://framerusercontent.com/images/ELeNmJPDLzFqqyAV9RDIg7kHml8.png?scale-down-to=512 512w, https://framerusercontent.com/images/ELeNmJPDLzFqqyAV9RDIg7kHml8.png 810w" src="https://framerusercontent.com/images/ELeNmJPDLzFqqyAV9RDIg7kHml8.png" alt="QQ English" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'contain', imageRendering: 'auto'}} data-framer-original-sizes="calc(min(126px, 100vw) - 26px)" />
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="framer-1mwu3n0-container">
          <div className="framer-pjNLU framer-v-vld1wf" style={{display: 'contents'}} tabIndex={0}>
            <a className="framer-vld1wf framer-1tyxgpt" data-framer-name="Variant 1" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px', boxShadow: 'none'}} href="ja_jp/customer/stories/next.html" target="_blank" rel="noopener">
              <div className="framer-ao0x9g">
                <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                  <img decoding="async" sizes="calc(min(126px, 100vw) - 26px)" src="https://framerusercontent.com/images/yHZH2cHWgzqAc7ListXuD1ICzR0.svg" alt="Next" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'contain', imageRendering: 'auto'}} />
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="framer-pd8i9i-container">
          <div className="framer-pjNLU framer-v-vld1wf" style={{display: 'contents'}} tabIndex={0}>
            <a className="framer-vld1wf framer-1tyxgpt" data-framer-name="Variant 1" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px', boxShadow: 'none'}} href="ja_jp/customer/stories/kinsuikan.html" target="_blank" rel="noopener">
              <div className="framer-ao0x9g">
                <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                  <img decoding="async" sizes="calc(min(126px, 100vw) - 26px)" src="https://framerusercontent.com/images/pvWYVQewaD1dWQ6gKt0ETFSmwY8.svg" alt="錦水館" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'contain', imageRendering: 'auto'}} />
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="framer-1v6dw1q-container">
          <div className="framer-pjNLU framer-v-vld1wf" style={{display: 'contents'}} tabIndex={0}>
            <a className="framer-vld1wf framer-1tyxgpt" data-framer-name="Variant 1" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px', boxShadow: 'none'}} href="ja_jp/customer/stories/syrus-japan.html" target="_blank" rel="noopener">
              <div className="framer-ao0x9g">
                <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                  <img decoding="async" sizes="calc(min(126px, 100vw) - 26px)" src="https://framerusercontent.com/images/CYc2YSlKn6xpMqkIKQwvIKRwq3A.svg" alt="Syrius" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'contain', imageRendering: 'auto'}} />
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="framer-1oy9o60-container">
          <div className="framer-pjNLU framer-v-18x4xwr" style={{display: 'contents'}} tabIndex={0}>
            <a className="framer-vld1wf framer-1tyxgpt" data-framer-name="w/o link" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px', boxShadow: 'none'}}>
              <div className="framer-ao0x9g">
                <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                  <img decoding="async" sizes="calc(min(126px, 100vw) - 26px)" src="https://framerusercontent.com/images/s6OBl9toAJSRDHv9B2ios504uGQ.svg" alt="ザカモア" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'contain', imageRendering: 'auto'}} />
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="framer-1dqzjfp-container">
          <div className="framer-pjNLU framer-v-vld1wf" style={{display: 'contents'}} tabIndex={0}>
            <a className="framer-vld1wf framer-1tyxgpt" data-framer-name="Variant 1" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px', boxShadow: 'none'}} href="ja_jp/customer/stories/artplay-video-game-project-management.html" target="_blank" rel="noopener">
              <div className="framer-ao0x9g">
                <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                  <img decoding="async" sizes="calc(min(126px, 100vw) - 26px)" src="https://framerusercontent.com/images/B4xeuV1bnGc4VMntHjWCKUBO8.svg" alt="ArtPlay" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'contain', imageRendering: 'auto'}} />
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="framer-114zwgf-container">
          <div className="framer-pjNLU framer-v-vld1wf" style={{display: 'contents'}} tabIndex={0}>
            <a className="framer-vld1wf framer-1tyxgpt" data-framer-name="Variant 1" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px', boxShadow: 'none'}} href="ja_jp/customer/stories/ever-brew.html" target="_blank" rel="noopener">
              <div className="framer-ao0x9g">
                <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                  <img decoding="async" sizes="(min-width: 1240px) max(120px, calc(min(126px, 100vw) - 26px)), (min-width: 905px) and (max-width: 1239px) max(120px, calc(min(126px, 100vw) - 26px)), (min-width: 600px) and (max-width: 904px) max(120px, calc(min(126px, 100vw) - 26px)), (max-width: 599px) max(90px, calc(min(126px, 100vw) - 26px))" srcSet="
                                                            https://framerusercontent.com/images/76lznc0JlNJvfna1Tql9CcCFHdY.png?scale-down-to=512   512w,
                                                            https://framerusercontent.com/images/76lznc0JlNJvfna1Tql9CcCFHdY.png?scale-down-to=1024 1024w,
                                                            https://framerusercontent.com/images/76lznc0JlNJvfna1Tql9CcCFHdY.png                    2048w
                                                        " src="https://framerusercontent.com/images/76lznc0JlNJvfna1Tql9CcCFHdY.png" alt="Ever Brew" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'contain', imageRendering: 'auto'}} data-framer-original-sizes="calc(min(126px, 100vw) - 26px)" />
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      
        </section>
        </div>
      
     );
}

export default CustomerComponent;