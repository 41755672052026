import { useEffect, useState } from "react";

function NavbarComponent() {
    const [scrolled, setScrolled] = useState(false);

    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 60) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
  
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

  return (
    <>
      <div className="framer-j8e4ms" style={{ transform: "translateX(-50%)", backgroundColor: scrolled ? 'white': '' }} id="header-site">
        <div className="framer-14hduhn-container">
          <div style={{ width: "100%" }}>
            <nav
              className="framer-Tcihl framer-nd8x2 framer-UQieZ framer-1mzsypx framer-v-16mnfre"
              data-highlight="true"
              tabIndex={0}
              style={{
                width: "100%",
                backgroundColor: "rgba(255, 255, 255, 0)",
                opacity: 1,
              }}
            >
              <div
                className="framer-1wyg00d"
                data-framer-name="Icons"
                style={{ opacity: 1 }}
              >
                <a
                  className="framer-rjdzgo framer-1kqrwl0"
                  href=""
                  rel="noopener"
                  style={{ opacity: 1 }}
                >
                  <div
                    data-framer-background-image-wrapper="true"
                    style={{
                      position: "absolute",
                      borderRadius: "inherit",
                      inset: "0px",
                    }}
                  >
                    <img
                    className="img-logo"
                      decoding="async"
                      src="./oncenter_logo.svg"
                      alt=""
                    />
                  </div>
                </a>
              </div>
              <div
                className="framer-cw38jw"
                data-framer-name="Links"
                style={{ opacity: 1 }}
              >
                <div
                  className="framer-64h2ow"
                  data-framer-name="Customer Stories"
                  style={{ opacity: 1 }}
                >
                  <div
                    className="framer-1rqgzf9-container"
                    style={{ transform: "translate(-50%, -50%)", opacity: 1 }}
                  >
                    <a
                      className="framer-PDDWw framer-bpum76 framer-v-bpum76 framer-13k97wl"
                      data-framer-name="Variant 1"
                      href="https://www.larksuite.com/ja_jp/customers?from=navbar"
                      target="_blank"
                      rel="noopener"
                      tabIndex={0}
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0)",
                        borderRadius: "4px",
                        opacity: 1,
                      }}
                    >
                      <div
                        className="framer-6ho6gz"
                        data-framer-component-type="RichTextContainer"
                        style={{
                          outline: "none",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          flexShrink: 0,
                          extractedR6o4lv:
                            "var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))",
                          transform: "none",
                          opacity: 1,
                        }}
                      >
                        <p
                          className="framer-text"
                          style={{
                            fontSelector:
                              "Q1VTVE9NO05vdG8gU2FucyBKUCBNZWRpdW0=",
                            framerFontFamily:
                              '"Noto Sans JP Medium", sans-serif',
                            framerTextColor:
                              "var(--extracted-r6o4lv, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))",
                          }}
                        >
                          aaaa
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
                <div
                  className="framer-1nox8a"
                  data-framer-name="Trust"
                  style={{ opacity: 1 }}
                >
                  <div
                    className="framer-kgyvby-container"
                    style={{ opacity: 1 }}
                  >
                    <a
                      className="framer-PDDWw framer-bpum76 framer-v-bpum76 framer-13k97wl"
                      data-framer-name="Variant 1"
                      href="https://www.larksuite.com/ja_jp/trust/security?from=navbar"
                      target="_blank"
                      rel="noopener"
                      tabIndex={0}
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0)",
                        borderRadius: "4px",
                        opacity: 1,
                      }}
                    >
                      <div
                        className="framer-6ho6gz"
                        data-framer-component-type="RichTextContainer"
                        style={{
                          outline: "none",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          flexShrink: 0,
                          extractedR6o4lv:
                            "var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))",
                          transform: "none",
                          opacity: 1,
                        }}
                      >
                        <p
                          className="framer-text"
                          style={{
                            fontSelector:
                              "Q1VTVE9NO05vdG8gU2FucyBKUCBNZWRpdW0=",
                            framerFontFamily:
                              '"Noto Sans JP Medium", sans-serif',
                            framerTextColor:
                              "var(--extracted-r6o4lv, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))",
                          }}
                        >
                          セキュリティ
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="framer-n4s68s"
                style={{ transform: "translateY(-50%)", opacity: 1 }}
              >
                <div className="framer-v7433t-container" style={{ opacity: 1 }}>
                <div
                      className=""
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="nav-right-ctn"
                        style={{
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <button
                            id="SignInBtn"
                            style={{
                              fontFamily: '"Noto Sans JP Medium", sans-serif',
                              fontSize: "16px",
                              fontWeight: 500,
                              whiteSpace: "nowrap",
                              color: "rgb(31, 35, 41)",
                              backgroundColor: "rgba(206, 223, 255, 0)",
                              borderColor: "rgba(255, 255, 255, 0)",
                              textDecoration: "none",
                              padding: "6px",
                              borderRadius: "4px",
                              cursor: "initial",
                            }}
                          >
                            {" "}
                            Đăng ký{" "}
                          </button>
                        </div>
                        <div>
                          <button
                            className="try-free-btn"
                            style={{
                              marginLeft: "12px",
                              padding: "8px 16px",
                              borderRadius: "100px",
                              backgroundColor: "rgb(51, 112, 255)",
                              fontFamily: '"Noto Sans JP Medium", sans-serif',
                              fontSize: "16px",
                              fontWeight: 500,
                              whiteSpace: "nowrap",
                              border: "0px",
                              color: "white",
                              textDecoration: "none",
                              cursor: "initial",
                            }}
                          >
                            {" "}
                            Đăng nhập{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}

export default NavbarComponent;
