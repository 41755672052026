function Section3Component() {
  return (
    <div
      className="framer-1tlonfm"
      data-framer-name="Lark's feature"
      name="Lark's feature"
    >
      <div className="framer-1eocjfg">
        <div
          className="framer-1f8read"
          data-framer-name="Lark の特徴"
          style={{
            outline: "none",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            flexShrink: 0,
            transform: "none",
          }}
          data-framer-component-type="RichTextContainer"
        >
          <p
            className="framer-text framer-styles-preset-jg60nb"
            data-styles-preset="X82eKqeAg"
          >
            ╲ Quản lý nhân sự ╱
          </p>
        </div>
        <div
          className="framer-1uevnrn"
          data-framer-name="Lark の特徴"
          style={{
            outline: "none",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            flexShrink: 0,
            transform: "none",
          }}
          data-framer-component-type="RichTextContainer"
        >
          <h3
            className="framer-text framer-styles-preset-1k1rjey"
            data-styles-preset="iT7awcTns"
          >
            HRM
          </h3>
        </div>
      </div>
      <div className="framer-1bjjcap">
        <div className="ssr-variant hidden-9i23oi hidden-150dn05 hidden-q2nkjo">
          <div className="framer-1nigt3q-container">
            <div
              className="framer-IvKEh framer-M4n02 framer-UGujo framer-1cc99ru framer-v-1cc99ru"
              data-framer-name="Before"
              style={{
                borderBottomWidth: "0px",
                borderColor: "rgba(0, 0, 0, 0)",
                borderLeftWidth: "0px",
                borderRightWidth: "0px",
                borderStyle: "solid",
                borderTopWidth: "0px",
                backgroundColor: "rgba(0, 0, 0, 0)",
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                height: "100%",
                width: "100%",
              }}
              tabIndex={0}
            >
              <div
                style={{
                  position: "absolute",
                  borderRadius: "inherit",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                }}
                data-framer-background-image-wrapper="true"
              >
                <img
                  decoding="async"
                  sizes="(min-width: 1240px) max((min(100vw, 1240px) - 291px) / 2, 0px), (min-width: 600px) and (max-width: 904px) calc(min(100vw, 700px) - 48px), (min-width: 905px) and (max-width: 1239px) calc((min(100vw, 1240px) - 48px) * 0.46), (max-width: 599px) calc(min(100vw, 480px) - 32px)"
                  srcSet="
                                              https://framerusercontent.com/images/QP2nkQ6z79pPRUNgpcj2jNN7xVk.png?scale-down-to=512   512w,
                                              https://framerusercontent.com/images/QP2nkQ6z79pPRUNgpcj2jNN7xVk.png?scale-down-to=1024 1024w,
                                              https://framerusercontent.com/images/QP2nkQ6z79pPRUNgpcj2jNN7xVk.png                    1120w
                                          "
                  src="https://framerusercontent.com/images/QP2nkQ6z79pPRUNgpcj2jNN7xVk.png?scale-down-to=1024"
                  alt=""
                  style={{
                    display: "block",
                    width: "100%",
                    height: "100%",
                    borderRadius: "inherit",
                    objectPosition: "center bottom",
                    objectFit: "contain",
                    imageRendering: "auto",
                  }}
                  data-framer-original-sizes="max((min(100vw, 1240px) - 291px) / 2, 0px)"
                />
              </div>
              <div className="framer-7xcdew">
                <div className="framer-wnn4gv">
                  <div className="framer-1egupmm">
                    <div
                      className="framer-410ztq"
                      style={{
                        outline: "none",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        flexShrink: 0,
                        extractedR6o4lv:
                          "var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))",
                        transform: "none",
                      }}
                      data-framer-component-type="RichTextContainer"
                    >
                      <p
                        style={{
                          fontSelector: "R0Y7Tm90byBTYW5zIEpQLTkwMA==",
                          fontFamily: '"Noto Sans JP", sans-serif',
                          fontSize: "24px",
                          fontWeight:"bold",
                          lineHeight: "1.5em",
                          textAlign: "left",
                          color:
                            "var(--extracted-r6o4lv, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))",
                        // whiteSpace: "pre-line"
                        }}
                        className="framer-text"
                      >
                        Quản lý thông tin nhân sự
                      </p>
                    </div>
                  </div>
                  <div
                    className="framer-1ha6s17"
                    style={{
                      outline: "none",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      flexShrink: 0,
                      extractedR6o4lv:
                        "var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))",
                      transform: "none",
                    }}
                    data-framer-component-type="RichTextContainer"
                  >
                    <p
                      style={{
                        fontSelector: "R0Y7Tm90byBTYW5zIEpQLTUwMA==",
                        fontFamily:
                          '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
                        fontWeight: 500,
                        lineHeight: "1.5em",
                        textAlign: "left",
                        color:
                          "var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))",
                      }}
                      className="framer-text"
                    >
                     Bộ giải pháp quản trị nhân sự và phát triển con người toàn diện
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ssr-variant hidden-ollpel hidden-150dn05 hidden-q2nkjo">
  <div className="framer-1nigt3q-container">
    <div className="framer-IvKEh framer-M4n02 framer-UGujo framer-1cc99ru framer-v-kxctfk" data-framer-name="Mobile - Before" style={{borderBottomWidth: '0px', borderColor: 'rgba(0, 0, 0, 0)', borderLeftWidth: '0px', borderRightWidth: '0px', borderStyle: 'solid', borderTopWidth: '0px', backgroundColor: 'rgba(0, 0, 0, 0)', borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0, height: '100%', width: '100%'}} tabIndex={0}>
      <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
        <img decoding="async" sizes="(min-width: 1240px) max((min(100vw, 1240px) - 291px) / 2, 0px), (min-width: 600px) and (max-width: 904px) calc(min(100vw, 700px) - 48px), (min-width: 905px) and (max-width: 1239px) calc((min(100vw, 1240px) - 48px) * 0.46), (max-width: 599px) calc(min(100vw, 480px) - 32px)" srcSet="
                                              https://framerusercontent.com/images/QP2nkQ6z79pPRUNgpcj2jNN7xVk.png?scale-down-to=512   512w,
                                              https://framerusercontent.com/images/QP2nkQ6z79pPRUNgpcj2jNN7xVk.png?scale-down-to=1024 1024w,
                                              https://framerusercontent.com/images/QP2nkQ6z79pPRUNgpcj2jNN7xVk.png                    1120w
                                          " src="https://framerusercontent.com/images/QP2nkQ6z79pPRUNgpcj2jNN7xVk.png?scale-down-to=1024" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center bottom', objectFit: 'contain', imageRendering: 'auto'}} data-framer-original-sizes="calc(min(100vw, 700px) - 48px)" />
      </div>
      <div className="framer-7xcdew">
        <div className="framer-wnn4gv">
          <div className="framer-1egupmm">
            <div className="framer-410ztq" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))', transform: 'none'}} data-framer-component-type="RichTextContainer">
              <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTkwMA==', fontFamily: '"Noto Sans JP", sans-serif', fontWeight:"bold", lineHeight: '1.5em', textAlignment: 'left', Color: 'var(--extracted-r6o4lv, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))'}} className="framer-text">
                Quản lý thông tin nhân sự
              </p>
            </div>
          </div>
          <div className="framer-1ha6s17" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))', transform: 'none'}} data-framer-component-type="RichTextContainer">
            <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTUwMA==', fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', fontSize: '14px', fontWeight: 500, lineHeight: '1.5em', textAlign: 'left', color: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'}} className="framer-text">
             Bộ giải pháp quản trị nhân sự và phát triển con người toàn diện
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div className="ssr-variant hidden-ollpel hidden-9i23oi hidden-q2nkjo">
    <div className="framer-1nigt3q-container">
      <div className="framer-IvKEh framer-M4n02 framer-UGujo framer-1cc99ru framer-v-kxctfk" data-framer-name="Mobile - Before" style={{borderBottomWidth: '0px', borderColor: 'rgba(0, 0, 0, 0)', borderLeftWidth: '0px', borderRightWidth: '0px', borderStyle: 'solid', borderTopWidth: '0px', backgroundColor: 'rgba(0, 0, 0, 0)', borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0, height: '100%', width: '100%'}} tabIndex={0}>
        <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
          <img decoding="async" sizes="(min-width: 1240px) max((min(100vw, 1240px) - 291px) / 2, 0px), (min-width: 600px) and (max-width: 904px) calc(min(100vw, 700px) - 48px), (min-width: 905px) and (max-width: 1239px) calc((min(100vw, 1240px) - 48px) * 0.46), (max-width: 599px) calc(min(100vw, 480px) - 32px)" srcSet="
                                              https://framerusercontent.com/images/QP2nkQ6z79pPRUNgpcj2jNN7xVk.png?scale-down-to=512   512w,
                                              https://framerusercontent.com/images/QP2nkQ6z79pPRUNgpcj2jNN7xVk.png?scale-down-to=1024 1024w,
                                              https://framerusercontent.com/images/QP2nkQ6z79pPRUNgpcj2jNN7xVk.png                    1120w
                                          " src="https://framerusercontent.com/images/QP2nkQ6z79pPRUNgpcj2jNN7xVk.png?scale-down-to=1024" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center bottom', objectFit: 'contain', imageRendering: 'auto'}} data-framer-original-sizes="calc((min(100vw, 1240px) - 48px) * 0.46)" />
        </div>
        <div className="framer-7xcdew">
          <div className="framer-wnn4gv">
            <div className="framer-1egupmm">
              <div className="framer-410ztq" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, transform: 'none'}} data-framer-component-type="RichTextContainer">
                <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTkwMA==', fontFamily: '"Noto Sans JP", sans-serif', fontWeight:"bold", lineHeight: '1.5em', textAlign: 'left', color: 'var(--extracted-r6o4lv, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))'}} className="framer-text">
                  Quản lý thông tin nhân sự
                </p>
              </div>
            </div>
            <div className="framer-1ha6s17" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, transform: 'none'}} data-framer-component-type="RichTextContainer">
              <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTUwMA==', fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', fontSize: '14px', fontWeight: 500, lineHeight: '1.5em', textAlign: 'left', color: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'}} className="framer-text">
               Bộ giải pháp quản trị nhân sự và phát triển con người toàn diện
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="ssr-variant hidden-ollpel hidden-9i23oi hidden-150dn05">
    <div className="framer-1nigt3q-container">
      <div className="framer-IvKEh framer-M4n02 framer-UGujo framer-1cc99ru framer-v-kxctfk" data-framer-name="Mobile - Before" style={{borderBottomWidth: '0px', borderColor: 'rgba(0, 0, 0, 0)', borderLeftWidth: '0px', borderRightWidth: '0px', borderStyle: 'solid', borderTopWidth: '0px', backgroundColor: 'rgba(0, 0, 0, 0)', borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0, height: '100%', width: '100%'}} tabIndex={0}>
        <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
          <img decoding="async" sizes="(min-width: 1240px) max((min(100vw, 1240px) - 291px) / 2, 0px), (min-width: 600px) and (max-width: 904px) calc(min(100vw, 700px) - 48px), (min-width: 905px) and (max-width: 1239px) calc((min(100vw, 1240px) - 48px) * 0.46), (max-width: 599px) calc(min(100vw, 480px) - 32px)" srcSet="
                                              https://framerusercontent.com/images/QP2nkQ6z79pPRUNgpcj2jNN7xVk.png?scale-down-to=512   512w,
                                              https://framerusercontent.com/images/QP2nkQ6z79pPRUNgpcj2jNN7xVk.png?scale-down-to=1024 1024w,
                                              https://framerusercontent.com/images/QP2nkQ6z79pPRUNgpcj2jNN7xVk.png                    1120w
                                          " src="https://framerusercontent.com/images/QP2nkQ6z79pPRUNgpcj2jNN7xVk.png?scale-down-to=1024" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center bottom', objectFit: 'contain', imageRendering: 'auto'}} data-framer-original-sizes="calc(min(100vw, 480px) - 32px)" />
        </div>
        <div className="framer-7xcdew">
          <div className="framer-wnn4gv">
            <div className="framer-1egupmm">
              <div className="framer-410ztq" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, transform: 'none'}} data-framer-component-type="RichTextContainer">
                <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTkwMA==', fontFamily: '"Noto Sans JP", sans-serif', fontWeight:"bold", lineHeight: '1.5em', textAlign: 'left', color: 'var(--extracted-r6o4lv, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))'}} className="framer-text">
                  Quản lý thông tin nhân sự
                </p>
              </div>
            </div>
            <div className="framer-1ha6s17" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0,  transform: 'none'}} data-framer-component-type="RichTextContainer">
              <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTUwMA==', fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', fontSize: '14px', fontWeight: 500, lineHeight: '1.5em', textAlign: 'left', color: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'}} className="framer-text">
               Bộ giải pháp quản trị nhân sự và phát triển con người toàn diện
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <div className="ssr-variant hidden-ollpel hidden-9i23oi hidden-q2nkjo">
      <div className="framer-ot7i78" style={{transform: 'translateX(-50%)'}}>
        <div data-framer-component-type="SVG" data-framer-name="Frame 1912055126" name="Frame 1912055126" style={{imageRendering: 'pixelated', flexShrink: 0, fill: 'rgba(0, 0, 0, 1)', color: 'rgba(0, 0, 0, 1)'}} className="framer-1q0oeca hidden-9i23oi hidden-q2nkjo">
          <div className="svgContainer" style={{width: '100%', height: '100%', aspectRatio: 'inherit'}}>
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{width: '100%', height: '100%'}} viewBox="0 0 175 40"><use href="#svg996630736_1216" /></svg>
          </div>
        </div>
        <div data-framer-component-type="SVG" data-framer-name="arrow" name="arrow" style={{imageRendering: 'pixelated', flexShrink: 0, fill: 'rgba(0, 0, 0, 1)', color: 'rgba(0, 0, 0, 1)'}} className="framer-1w0h39g hidden-ollpel hidden-150dn05">
          <div className="svgContainer" style={{width: '100%', height: '100%', aspectRatio: 'inherit'}}>
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{width: '100%', height: '100%'}} viewBox="0 0 124 100"><use href="#svg-265018200_1256" /></svg>
          </div>
        </div>
        <div className="framer-1jdx463" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
          <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLXJlZ3VsYXI=', fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', fontSize: '14px', color: 'var(--token-7346c956-2945-4614-93bd-7323a4c57dab, rgb(255, 255, 255))'}} className="framer-text">
          Bộ giải pháp quản trị tài chính doanh nghiệp
          </p>
        </div>
      </div>
    </div>
    <div className="ssr-variant hidden-9i23oi hidden-150dn05 hidden-q2nkjo">
      <div className="framer-5quvfm-container" data-framer-name="All-in-one" name="All-in-one">
        <div name="All-in-one" className="framer-IvKEh framer-M4n02 framer-UGujo framer-1cc99ru framer-v-i2v4cz" data-framer-name="After" style={{borderBottomWidth: '0px', borderColor: 'rgba(0, 0, 0, 0)', borderLeftWidth: '0px', borderRightWidth: '0px', borderStyle: 'solid', borderTopWidth: '0px', backgroundColor: 'rgba(0, 0, 0, 0)', borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0, height: '100%', width: '100%'}} tabIndex={0}>
          <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
            <img decoding="async" sizes="(min-width: 1240px) max((min(100vw, 1240px) - 291px) / 2, 0px)" srcSet="
                                                https://framerusercontent.com/images/lpLdtsCnv7aWeBwu5cf5UaUSk.png?scale-down-to=512   512w,
                                                https://framerusercontent.com/images/lpLdtsCnv7aWeBwu5cf5UaUSk.png?scale-down-to=1024 1024w,
                                                https://framerusercontent.com/images/lpLdtsCnv7aWeBwu5cf5UaUSk.png?scale-down-to=2048 2048w,
                                                https://framerusercontent.com/images/lpLdtsCnv7aWeBwu5cf5UaUSk.png                    2217w
                                            " src="https://framerusercontent.com/images/lpLdtsCnv7aWeBwu5cf5UaUSk.png" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center bottom', objectFit: 'contain', imageRendering: 'auto'}} data-framer-original-sizes="max((min(100vw, 1240px) - 291px) / 2, 0px)" />
          </div>
          <div className="framer-7xcdew">
            <div className="framer-wnn4gv">
              <div className="framer-1egupmm">
                <div className="framer-410ztq" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))', transform: 'none'}} data-framer-component-type="RichTextContainer">
                  <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTkwMA==', fontFamily: '"Noto Sans JP", sans-serif', fontSize: '24px', fontWeight:"bold", lineHeight: '1.5em', textAlign: 'left', color: 'var(--extracted-r6o4lv, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))'}} className="framer-text">
                  Bộ giải pháp quản trị tài chính doanh nghiệp
                  </p>
                </div>
                <div className="framer-1gvo5ek-container">
                  <a className="framer-43g8y framer-thvK1 framer-1xql04t framer-v-142g9u framer-14bqysp" data-framer-name="Text Link with icon" data-highlight="true" style={{borderBottomWidth: '0px', borderColor: 'rgba(0, 0, 0, 0)', borderLeftWidth: '0px', borderRightWidth: '0px', borderStyle: 'solid', borderTopWidth: '0px', backgroundColor: 'rgba(0, 0, 0, 0)', borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px', borderTopLeftRadius: '6px', borderTopRightRadius: '6px', boxShadow: 'none'}} href="" rel="noopener" tabIndex={0}>
                    <div className="framer-144pz35" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                      <p className="framer-text framer-styles-preset-91fca6" data-styles-preset="ikm7JuTuk" style={{color: 'var(--extracted-r6o4lv, var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255)))'}}>
                      
                      </p>
                    </div>
                    <div className="framer-y8b76s-container"><div style={{display: 'contents'}} /></div>
                  </a>
                </div>
              </div>
              <div className="framer-1ha6s17" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))', transform: 'none'}} data-framer-component-type="RichTextContainer">
                <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTUwMA==', fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', fontWeight: 500, lineHeight: '1.5em', textAlign: 'left', color: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'}} className="framer-text">
                Giúp lãnh đạo doanh nghiệp có thể nắm thông tin tài chính đúng nhất, sớm nhất, đầy đủ nhất để ra quyết định chính xác nhất.
                </p>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    <div className="ssr-variant hidden-ollpel hidden-150dn05 hidden-q2nkjo">
      <div className="framer-5quvfm-container" data-framer-name="All-in-one" name="All-in-one">
        <div name="All-in-one" className="framer-IvKEh framer-M4n02 framer-UGujo framer-1cc99ru framer-v-sch4q7" data-framer-name="Mobile - After" style={{borderBottomWidth: '0px', borderColor: 'rgba(0, 0, 0, 0)', borderLeftWidth: '0px', borderRightWidth: '0px', borderStyle: 'solid', borderTopWidth: '0px', backgroundColor: 'rgb(246, 246, 251)', borderBottomLeftRadius: '16px', borderBottomRightRadius: '16px', borderTopLeftRadius: '16px', borderTopRightRadius: '16px', height: '100%', width: '100%'}} tabIndex={0}>
          <div className="framer-hwr9l5">
            <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
              <img decoding="async" sizes="(min-width: 600px) and (max-width: 904px) calc(calc(min(100vw, 700px) - 48px) * 0.66), (min-width: 905px) and (max-width: 1239px) calc(calc((min(100vw, 1240px) - 48px) * 0.4351) * 0.66), (max-width: 599px) calc(calc(min(100vw, 480px) - 32px) * 0.66)" srcSet="https://framerusercontent.com/images/Z4yYwYaWNa4E5LlTcaxzaHcRKM.png?scale-down-to=512 512w, https://framerusercontent.com/images/Z4yYwYaWNa4E5LlTcaxzaHcRKM.png 788w" src="https://framerusercontent.com/images/Z4yYwYaWNa4E5LlTcaxzaHcRKM.png" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'contain', imageRendering: 'auto'}} data-framer-original-sizes="calc(calc(min(100vw, 700px) - 48px) * 0.66)" />
            </div>
          </div>
          <div className="framer-7xcdew">
            <div className="framer-wnn4gv">
              <div className="framer-1egupmm">
                <div className="framer-410ztq" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))', transform: 'none'}} data-framer-component-type="RichTextContainer">
                  <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTkwMA==', fontFamily: '"Noto Sans JP", sans-serif', fontWeight:"bold", lineHeight: '1.5em', textAlign: 'left', color: 'var(--extracted-r6o4lv, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))'}} className="framer-text">
                  Bộ giải pháp quản trị tài chính doanh nghiệp
                  </p>
                </div>
                <div className="framer-1gvo5ek-container">
                  <a className="framer-43g8y framer-thvK1 framer-1xql04t framer-v-142g9u framer-14bqysp" data-framer-name="Text Link with icon" data-highlight="true" style={{borderBottomWidth: '0px', borderColor: 'rgba(0, 0, 0, 0)', borderLeftWidth: '0px', borderRightWidth: '0px', borderStyle: 'solid', borderTopWidth: '0px', backgroundColor: 'rgba(0, 0, 0, 0)', borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px', borderTopLeftRadius: '6px', borderTopRightRadius: '6px', boxShadow: 'none'}} href="" rel="noopener" tabIndex={0}>
                    <div className="framer-144pz35" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                      <p className="framer-text framer-styles-preset-91fca6" data-styles-preset="ikm7JuTuk" style={{color: 'var(--extracted-r6o4lv, var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255)))'}}>
                      
                      </p>
                    </div>
                    <div className="framer-y8b76s-container"><div style={{display: 'contents'}} /></div>
                  </a>
                </div>
              </div>
              <div className="framer-1ha6s17" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))', transform: 'none'}} data-framer-component-type="RichTextContainer">
                <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTUwMA==', fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', fontSize: '14px', fontWeight: 500, lineHeight: '1.5em', textAlign: 'left', color: 'var(--extracted-r6o4lv, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))'}} className="framer-text">
                Bộ giải pháp quản trị tài chính doanh nghiệp
                </p>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    <div className="ssr-variant hidden-ollpel hidden-9i23oi hidden-q2nkjo">
      <div className="framer-5quvfm-container" data-framer-name="All-in-one" name="All-in-one">
        <div name="All-in-one" className="framer-IvKEh framer-M4n02 framer-UGujo framer-1cc99ru framer-v-sch4q7" data-framer-name="Mobile - After" style={{borderBottomWidth: '0px', borderColor: 'rgba(0, 0, 0, 0)', borderLeftWidth: '0px', borderRightWidth: '0px', borderStyle: 'solid', borderTopWidth: '0px', backgroundColor: 'rgb(246, 246, 251)', borderBottomLeftRadius: '16px', borderBottomRightRadius: '16px', borderTopLeftRadius: '16px', borderTopRightRadius: '16px', height: '100%', width: '100%'}} tabIndex={0}>
          <div className="framer-hwr9l5">
            <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
              <img decoding="async" sizes="(min-width: 600px) and (max-width: 904px) calc(calc(min(100vw, 700px) - 48px) * 0.66), (min-width: 905px) and (max-width: 1239px) calc(calc((min(100vw, 1240px) - 48px) * 0.4351) * 0.66), (max-width: 599px) calc(calc(min(100vw, 480px) - 32px) * 0.66)" srcSet="https://framerusercontent.com/images/Z4yYwYaWNa4E5LlTcaxzaHcRKM.png?scale-down-to=512 512w, https://framerusercontent.com/images/Z4yYwYaWNa4E5LlTcaxzaHcRKM.png 788w" src="https://framerusercontent.com/images/Z4yYwYaWNa4E5LlTcaxzaHcRKM.png" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'contain', imageRendering: 'auto'}} data-framer-original-sizes="calc(calc((min(100vw, 1240px) - 48px) * 0.4351) * 0.66)" />
            </div>
          </div>
          <div className="framer-7xcdew">
            <div className="framer-wnn4gv">
              <div className="framer-1egupmm">
                <div className="framer-410ztq" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))', transform: 'none'}} data-framer-component-type="RichTextContainer">
                  <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTkwMA==', fontFamily: '"Noto Sans JP", sans-serif', fontWeight:"bold", lineHeight: '1.5em', textAlign: 'left', color: 'var(--extracted-r6o4lv, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))'}} className="framer-text">
                  Bộ giải pháp quản trị tài chính doanh nghiệp
                  </p>
                </div>
                <div className="framer-1gvo5ek-container">
                  <a className="framer-43g8y framer-thvK1 framer-1xql04t framer-v-142g9u framer-14bqysp" data-framer-name="Text Link with icon" data-highlight="true" style={{borderBottomWidth: '0px', borderColor: 'rgba(0, 0, 0, 0)', borderLeftWidth: '0px', borderRightWidth: '0px', borderStyle: 'solid', borderTopWidth: '0px', backgroundColor: 'rgba(0, 0, 0, 0)', borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px', borderTopLeftRadius: '6px', borderTopRightRadius: '6px', boxShadow: 'none'}} href="" rel="noopener" tabIndex={0}>
                    <div className="framer-144pz35" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                      <p className="framer-text framer-styles-preset-91fca6" data-styles-preset="ikm7JuTuk" style={{color: 'var(--extracted-r6o4lv, var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255)))'}}>
                      
                      </p>
                    </div>
                    <div className="framer-y8b76s-container"><div style={{display: 'contents'}} /></div>
                  </a>
                </div>
              </div>
              <div className="framer-1ha6s17" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))', transform: 'none'}} data-framer-component-type="RichTextContainer">
                <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTUwMA==', fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', fontSize: '14px', fontWeight: 500, lineHeight: '1.5em', textAlign: 'left', color: 'var(--extracted-r6o4lv, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))'}} className="framer-text">
                Giúp lãnh đạo doanh nghiệp có thể nắm thông tin tài chính đúng nhất, sớm nhất, đầy đủ nhất để ra quyết định chính xác nhất.
                
                </p>
              </div>
            </div>
            <div className="framer-6nlf16-container">
            
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="ssr-variant hidden-ollpel hidden-9i23oi hidden-150dn05">
      <div className="framer-5quvfm-container" data-framer-name="All-in-one" name="All-in-one">
        <div name="All-in-one" className="framer-IvKEh framer-M4n02 framer-UGujo framer-1cc99ru framer-v-sch4q7" data-framer-name="Mobile - After" style={{borderBottomWidth: '0px', borderColor: 'rgba(0, 0, 0, 0)', borderLeftWidth: '0px', borderRightWidth: '0px', borderStyle: 'solid', borderTopWidth: '0px', backgroundColor: 'rgb(246, 246, 251)', borderBottomLeftRadius: '16px', borderBottomRightRadius: '16px', borderTopLeftRadius: '16px', borderTopRightRadius: '16px', height: '100%', width: '100%'}} tabIndex={0}>
          <div className="framer-hwr9l5">
            <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
              <img decoding="async" sizes="(min-width: 600px) and (max-width: 904px) calc(calc(min(100vw, 700px) - 48px) * 0.66), (min-width: 905px) and (max-width: 1239px) calc(calc((min(100vw, 1240px) - 48px) * 0.4351) * 0.66), (max-width: 599px) calc(calc(min(100vw, 480px) - 32px) * 0.66)" srcSet="https://framerusercontent.com/images/Z4yYwYaWNa4E5LlTcaxzaHcRKM.png?scale-down-to=512 512w, https://framerusercontent.com/images/Z4yYwYaWNa4E5LlTcaxzaHcRKM.png 788w" src="https://framerusercontent.com/images/Z4yYwYaWNa4E5LlTcaxzaHcRKM.png" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'contain', imageRendering: 'auto'}} data-framer-original-sizes="calc(calc(min(100vw, 480px) - 32px) * 0.66)" />
            </div>
          </div>
          <div className="framer-7xcdew">
            <div className="framer-wnn4gv">
              <div className="framer-1egupmm">
                <div className="framer-410ztq" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))', transform: 'none'}} data-framer-component-type="RichTextContainer">
                  <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTkwMA==', fontFamily: '"Noto Sans JP", sans-serif', fontWeight:"bold", lineHeight: '1.5em', textAlign: 'left', color: 'var(--extracted-r6o4lv, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))'}} className="framer-text">
                    
                  </p>
                </div>
                <div className="framer-1gvo5ek-container">
                  <a className="framer-43g8y framer-thvK1 framer-1xql04t framer-v-142g9u framer-14bqysp" data-framer-name="Text Link with icon" data-highlight="true" style={{borderBottomWidth: '0px', borderColor: 'rgba(0, 0, 0, 0)', borderLeftWidth: '0px', borderRightWidth: '0px', borderStyle: 'solid', borderTopWidth: '0px', backgroundColor: 'rgba(0, 0, 0, 0)', borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px', borderTopLeftRadius: '6px', borderTopRightRadius: '6px', boxShadow: 'none'}} href="" rel="noopener" tabIndex={0}>
                    <div className="framer-144pz35" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                      <p className="framer-text framer-styles-preset-91fca6" data-styles-preset="ikm7JuTuk" style={{color: 'var(--extracted-r6o4lv, var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255)))'}}>
                      
                      </p>
                    </div>
                    <div className="framer-y8b76s-container"><div style={{display: 'contents'}} /></div>
                  </a>
                </div>
              </div>
              <div className="framer-1ha6s17" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))', transform: 'none'}} data-framer-component-type="RichTextContainer">
                <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTUwMA==', fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', fontSize: '14px', fontWeight: 500, lineHeight: '1.5em', textAlign: 'left', color: 'var(--extracted-r6o4lv, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))'}} className="framer-text">
                Giúp lãnh đạo doanh nghiệp có thể nắm thông tin tài chính đúng nhất, sớm nhất, đầy đủ nhất để ra quyết định chính xác nhất.
                
                </p>
              </div>
            </div>
            <div className="framer-6nlf16-container">
              <a className="framer-43g8y framer-thvK1 framer-1xql04t framer-v-1j8euck framer-14bqysp" data-framer-name="btn_JP_CTA_primary_mobile" data-highlight="true" style={{borderBottomWidth: '0px', borderColor: 'rgba(0, 0, 0, 0)', borderLeftWidth: '0px', borderRightWidth: '0px', borderStyle: 'solid', borderTopWidth: '0px', backgroundColor: 'var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255))', borderBottomLeftRadius: '60px', borderBottomRightRadius: '60px', borderTopLeftRadius: '60px', borderTopRightRadius: '60px', boxShadow: 'none'}} href="global/registera885.html?redirect_uri=https://www.larksuite.com/ja_jp/download&registration_process=global_register&app_id=1001&from=home_feature" rel="noopener" tabIndex={0}>
                <div className="framer-144pz35" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'rgb(255, 255, 255)', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                  <p style={{fontSelector: 'Q1VTVE9NO05vdG8gU2FucyBKUCBCb2xk', fontFamily: '"Noto Sans JP Bold", "Noto Sans JP Bold Placeholder", sans-serif', lineHeight: '1.5em', color: 'var(--extracted-r6o4lv, rgb(255, 255, 255))'}} className="framer-text">
                   
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
      </div>
      <div className="framer-68wyzr">
  <div className="ssr-variant hidden-9i23oi hidden-150dn05 hidden-q2nkjo">
    <div className="framer-t6jill-container">
      <div className="framer-IvKEh framer-M4n02 framer-UGujo framer-1cc99ru framer-v-1cc99ru" data-framer-name="Before" style={{borderBottomWidth: '0px', borderColor: 'rgba(0, 0, 0, 0)', borderLeftWidth: '0px', borderRightWidth: '0px', borderStyle: 'solid', borderTopWidth: '0px', backgroundColor: 'rgba(0, 0, 0, 0)', borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0, height: '100%', width: '100%'}} tabIndex={0}>
        <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
          <img decoding="async" sizes="(min-width: 1240px) max((min(100vw, 1240px) - 291px) / 2, 0px), (min-width: 600px) and (max-width: 904px) calc(min(100vw, 700px) - 48px), (min-width: 905px) and (max-width: 1239px) calc((min(100vw, 1240px) - 48px) * 0.46), (max-width: 599px) calc(min(100vw, 480px) - 32px)" srcSet="
                                              https://framerusercontent.com/images/3IBc6NqVZkO6p3wYKXWs8jZ2Y.png?scale-down-to=512   512w,
                                              https://framerusercontent.com/images/3IBc6NqVZkO6p3wYKXWs8jZ2Y.png?scale-down-to=1024 1024w,
                                              https://framerusercontent.com/images/3IBc6NqVZkO6p3wYKXWs8jZ2Y.png                    1680w
                                          " src="https://framerusercontent.com/images/3IBc6NqVZkO6p3wYKXWs8jZ2Y.png" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center bottom', objectFit: 'contain', imageRendering: 'auto'}} data-framer-original-sizes="max((min(100vw, 1240px) - 291px) / 2, 0px)" />
        </div>
        <div className="framer-7xcdew">
          <div className="framer-wnn4gv">
            <div className="framer-1egupmm">
              <div className="framer-410ztq" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))', transform: 'none'}} data-framer-component-type="RichTextContainer">
                <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTkwMA==', fontFamily: '"Noto Sans JP", sans-serif', fontSize: '24px', fontWeight: 900, lineHeight: '1.5em', textAlign: 'left', color: 'var(--extracted-r6o4lv, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))'}} className="framer-text">
                  Quản lý hồ sơ thuế TNCN
                </p>
              </div>
            </div>
            <div className="framer-1ha6s17" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))', transform: 'none'}} data-framer-component-type="RichTextContainer">
              <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTUwMA==', fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', fontWeight: 500, lineHeight: '1.5em', textAlign: 'left', color: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'}} className="framer-text">
              Tổng hợp tự động cả năm từ các dữ liệu thu nhập chịu thuế, giảm trừ gia cảnh, bảo hiểm bắt buộc, thuế đã nôp để tính ra số thuế phải thu/ hoàn lại
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="ssr-variant hidden-ollpel hidden-150dn05 hidden-q2nkjo">
    <div className="framer-t6jill-container">
      <div className="framer-IvKEh framer-M4n02 framer-UGujo framer-1cc99ru framer-v-kxctfk" data-framer-name="Mobile - Before" style={{borderBottomWidth: '0px', borderColor: 'rgba(0, 0, 0, 0)', borderLeftWidth: '0px', borderRightWidth: '0px', borderStyle: 'solid', borderTopWidth: '0px', backgroundColor: 'rgba(0, 0, 0, 0)', borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0, height: '100%', width: '100%'}} tabIndex={0}>
        <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
          <img decoding="async" sizes="(min-width: 1240px) max((min(100vw, 1240px) - 291px) / 2, 0px), (min-width: 600px) and (max-width: 904px) calc(min(100vw, 700px) - 48px), (min-width: 905px) and (max-width: 1239px) calc((min(100vw, 1240px) - 48px) * 0.46), (max-width: 599px) calc(min(100vw, 480px) - 32px)" srcSet="
                                              https://framerusercontent.com/images/3IBc6NqVZkO6p3wYKXWs8jZ2Y.png?scale-down-to=512   512w,
                                              https://framerusercontent.com/images/3IBc6NqVZkO6p3wYKXWs8jZ2Y.png?scale-down-to=1024 1024w,
                                              https://framerusercontent.com/images/3IBc6NqVZkO6p3wYKXWs8jZ2Y.png                    1680w
                                          " src="https://framerusercontent.com/images/3IBc6NqVZkO6p3wYKXWs8jZ2Y.png" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center bottom', objectFit: 'contain', imageRendering: 'auto'}} data-framer-original-sizes="calc(min(100vw, 700px) - 48px)" />
        </div>
        <div className="framer-7xcdew">
          <div className="framer-wnn4gv">
            <div className="framer-1egupmm">
              <div className="framer-410ztq" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))', transform: 'none'}} data-framer-component-type="RichTextContainer">
                <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTkwMA==', fontFamily: '"Noto Sans JP", sans-serif', fontWeight: 900, lineHeight: '1.5em', textAlign: 'left', color: 'var(--extracted-r6o4lv, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))'}} className="framer-text">
                  Quản lý hồ sơ thuế TNCN
                </p>
              </div>
            </div>
            <div className="framer-1ha6s17" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))', transform: 'none'}} data-framer-component-type="RichTextContainer">
              <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTUwMA==', fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', fontSize: '14px', fontWeight: 500, lineHeight: '1.5em', textAlign: 'left', color: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'}} className="framer-text">
              Tổng hợp tự động cả năm từ các dữ liệu thu nhập chịu thuế, giảm trừ gia cảnh, bảo hiểm bắt buộc, thuế đã nôp để tính ra số thuế phải thu/ hoàn lại
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="ssr-variant hidden-ollpel hidden-9i23oi hidden-q2nkjo">
    <div className="framer-t6jill-container">
      <div className="framer-IvKEh framer-M4n02 framer-UGujo framer-1cc99ru framer-v-kxctfk" data-framer-name="Mobile - Before" style={{borderBottomWidth: '0px', borderColor: 'rgba(0, 0, 0, 0)', borderLeftWidth: '0px', borderRightWidth: '0px', borderStyle: 'solid', borderTopWidth: '0px', backgroundColor: 'rgba(0, 0, 0, 0)', borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0, height: '100%', width: '100%'}} tabIndex={0}>
        <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
          <img decoding="async" sizes="(min-width: 1240px) max((min(100vw, 1240px) - 291px) / 2, 0px), (min-width: 600px) and (max-width: 904px) calc(min(100vw, 700px) - 48px), (min-width: 905px) and (max-width: 1239px) calc((min(100vw, 1240px) - 48px) * 0.46), (max-width: 599px) calc(min(100vw, 480px) - 32px)" srcSet="
                                              https://framerusercontent.com/images/3IBc6NqVZkO6p3wYKXWs8jZ2Y.png?scale-down-to=512   512w,
                                              https://framerusercontent.com/images/3IBc6NqVZkO6p3wYKXWs8jZ2Y.png?scale-down-to=1024 1024w,
                                              https://framerusercontent.com/images/3IBc6NqVZkO6p3wYKXWs8jZ2Y.png                    1680w
                                          " src="https://framerusercontent.com/images/3IBc6NqVZkO6p3wYKXWs8jZ2Y.png" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center bottom', objectFit: 'contain', imageRendering: 'auto'}} data-framer-original-sizes="calc((min(100vw, 1240px) - 48px) * 0.46)" />
        </div>
        <div className="framer-7xcdew">
          <div className="framer-wnn4gv">
            <div className="framer-1egupmm">
              <div className="framer-410ztq" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))', transform: 'none'}} data-framer-component-type="RichTextContainer">
                <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTkwMA==', fontFamily: '"Noto Sans JP", sans-serif', fontWeight: 900, lineHeight: '1.5em', textAlign: 'left', color: 'var(--extracted-r6o4lv, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))'}} className="framer-text">
                  Quản lý hồ sơ thuế TNCN
                </p>
              </div>
            </div>
            <div className="framer-1ha6s17" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))', transform: 'none'}} data-framer-component-type="RichTextContainer">
              <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTUwMA==', fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', fontSize: '14px', fontWeight: 500, lineHeight: '1.5em', textAlign: 'left', color: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'}} className="framer-text">
              Tổng hợp tự động cả năm từ các dữ liệu thu nhập chịu thuế, giảm trừ gia cảnh, bảo hiểm bắt buộc, thuế đã nôp để tính ra số thuế phải thu/ hoàn lại
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="ssr-variant hidden-ollpel hidden-9i23oi hidden-150dn05">
    <div className="framer-t6jill-container">
      <div className="framer-IvKEh framer-M4n02 framer-UGujo framer-1cc99ru framer-v-kxctfk" data-framer-name="Mobile - Before" style={{borderBottomWidth: '0px', borderColor: 'rgba(0, 0, 0, 0)', borderLeftWidth: '0px', borderRightWidth: '0px', borderStyle: 'solid', borderTopWidth: '0px', backgroundColor: 'rgba(0, 0, 0, 0)', borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0, height: '100%', width: '100%'}} tabIndex={0}>
        <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
          <img decoding="async" sizes="(min-width: 1240px) max((min(100vw, 1240px) - 291px) / 2, 0px), (min-width: 600px) and (max-width: 904px) calc(min(100vw, 700px) - 48px), (min-width: 905px) and (max-width: 1239px) calc((min(100vw, 1240px) - 48px) * 0.46), (max-width: 599px) calc(min(100vw, 480px) - 32px)" srcSet="
                                              https://framerusercontent.com/images/3IBc6NqVZkO6p3wYKXWs8jZ2Y.png?scale-down-to=512   512w,
                                              https://framerusercontent.com/images/3IBc6NqVZkO6p3wYKXWs8jZ2Y.png?scale-down-to=1024 1024w,
                                              https://framerusercontent.com/images/3IBc6NqVZkO6p3wYKXWs8jZ2Y.png                    1680w
                                          " src="https://framerusercontent.com/images/3IBc6NqVZkO6p3wYKXWs8jZ2Y.png" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center bottom', objectFit: 'contain', imageRendering: 'auto'}} data-framer-original-sizes="calc(min(100vw, 480px) - 32px)" />
        </div>
        <div className="framer-7xcdew">
          <div className="framer-wnn4gv">
            <div className="framer-1egupmm">
              <div className="framer-410ztq" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))', transform: 'none'}} data-framer-component-type="RichTextContainer">
                <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTkwMA==', fontFamily: '"Noto Sans JP", sans-serif', fontWeight: 900, lineHeight: '1.5em', textAlign: 'left', color: 'var(--extracted-r6o4lv, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))'}} className="framer-text">
                  Quản lý hồ sơ thuế TNCN
                </p>
              </div>
            </div>
            <div className="framer-1ha6s17" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))', transform: 'none'}} data-framer-component-type="RichTextContainer">
              <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTUwMA==', fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', fontSize: '14px', fontWeight: 500, lineHeight: '1.5em', textAlign: 'left', color: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'}} className="framer-text">
              Tổng hợp tự động cả năm từ các dữ liệu thu nhập chịu thuế, giảm trừ gia cảnh, bảo hiểm bắt buộc, thuế đã nôp để tính ra số thuế phải thu/ hoàn lại
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 
  <div className="ssr-variant hidden-9i23oi hidden-150dn05 hidden-q2nkjo">
    <div className="framer-15cw2a5-container" data-framer-name="data visualization" name="data visualization">
      <div name="data visualization" className="framer-IvKEh framer-M4n02 framer-UGujo framer-1cc99ru framer-v-i2v4cz" data-framer-name="After" style={{borderBottomWidth: '0px', borderColor: 'rgba(0, 0, 0, 0)', borderLeftWidth: '0px', borderRightWidth: '0px', borderStyle: 'solid', borderTopWidth: '0px', backgroundColor: 'rgba(0, 0, 0, 0)', borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0, height: '100%', width: '100%'}} tabIndex={0}>
        <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
          <img decoding="async" sizes="(min-width: 1240px) max((min(100vw, 1240px) - 291px) / 2, 0px)" srcSet="
                                              https://framerusercontent.com/images/62m4rLcLymxVaEjmhdwD2ydBZY.png?scale-down-to=512   512w,
                                              https://framerusercontent.com/images/62m4rLcLymxVaEjmhdwD2ydBZY.png?scale-down-to=1024 1024w,
                                              https://framerusercontent.com/images/62m4rLcLymxVaEjmhdwD2ydBZY.png?scale-down-to=2048 2048w,
                                              https://framerusercontent.com/images/62m4rLcLymxVaEjmhdwD2ydBZY.png                    2811w
                                          " src="https://framerusercontent.com/images/62m4rLcLymxVaEjmhdwD2ydBZY.png" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center bottom', objectFit: 'contain', imageRendering: 'auto'}} data-framer-original-sizes="max((min(100vw, 1240px) - 291px) / 2, 0px)" />
        </div>
        <div className="framer-7xcdew">
          <div className="framer-wnn4gv">
            <div className="framer-1egupmm">
              <div className="framer-410ztq" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))', transform: 'none'}} data-framer-component-type="RichTextContainer">
                <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTkwMA==', fontFamily: '"Noto Sans JP", sans-serif', fontSize: '24px', fontWeight: 900, lineHeight: '1.5em', textAlign: 'left', color: 'var(--extracted-r6o4lv, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))'}} className="framer-text">
                 Báo cáo đánh giá nhân viên
                </p>
              </div>
             
            </div>
            <div className="framer-1ha6s17" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))', transform: 'none'}} data-framer-component-type="RichTextContainer">
              <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTUwMA==', fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', fontWeight: 500, lineHeight: '1.5em', textAlign: 'left', color: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'}} className="framer-text">
                Theo dõi sự tiến bộ của người lao động: thông qua các kỳ đánh giá, đánh giá được sự cải tiến và chất lượng, năng suất lao động, theo dõi được quá trình thăng tiến của người lao động.
              </p>
            </div>
          </div>
         
        </div>
      </div>
    </div>
  </div>
  <div className="ssr-variant hidden-ollpel hidden-150dn05 hidden-q2nkjo">
    <div className="framer-15cw2a5-container" data-framer-name="data visualization" name="data visualization">
      <div name="data visualization" className="framer-IvKEh framer-M4n02 framer-UGujo framer-1cc99ru framer-v-sch4q7" data-framer-name="Mobile - After" style={{borderBottomWidth: '0px', borderColor: 'rgba(0, 0, 0, 0)', borderLeftWidth: '0px', borderRightWidth: '0px', borderStyle: 'solid', borderTopWidth: '0px', backgroundColor: 'rgb(246, 246, 251)', borderBottomLeftRadius: '16px', borderBottomRightRadius: '16px', borderTopLeftRadius: '16px', borderTopRightRadius: '16px', height: '100%', width: '100%'}} tabIndex={0}>
        <div className="framer-hwr9l5">
          <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
            <img decoding="async" sizes="(min-width: 600px) and (max-width: 904px) calc(calc(min(100vw, 700px) - 48px) * 0.66), (max-width: 599px) calc(calc(min(100vw, 480px) - 32px) * 0.46)" srcSet="https://framerusercontent.com/images/XF9l2Pl2uOBzUUnHiqfHMxKw7VA.png?scale-down-to=512 512w, https://framerusercontent.com/images/XF9l2Pl2uOBzUUnHiqfHMxKw7VA.png 717w" src="https://framerusercontent.com/images/XF9l2Pl2uOBzUUnHiqfHMxKw7VA.png" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'contain', imageRendering: 'auto'}} data-framer-original-sizes="calc(calc(min(100vw, 700px) - 48px) * 0.66)" />
          </div>
        </div>
        <div className="framer-7xcdew">
          <div className="framer-wnn4gv">
            <div className="framer-1egupmm">
              <div className="framer-410ztq" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))', transform: 'none'}} data-framer-component-type="RichTextContainer">
                <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTkwMA==', fontFamily: '"Noto Sans JP", sans-serif', fontWeight: 900, lineHeight: '1.5em', textAlign: 'left', color: 'var(--extracted-r6o4lv, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))'}} className="framer-text">
                  Báo cáo đánh giá nhân viên
                </p>
              </div>
            
            </div>
            <div className="framer-1ha6s17" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))', transform: 'none'}} data-framer-component-type="RichTextContainer">
              <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTUwMA==', fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', fontSize: '14px', fontWeight: 500, lineHeight: '1.5em', textAlign: 'left', color: 'var(--extracted-r6o4lv, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))'}} className="framer-text">
                売上データや、品質管理、スタッフの勤務状況
                など、
                経営に必要な全てのデータをリアルタイムで見える化できます
              </p>
            </div>
          </div>
         
        </div>
      </div>
    </div>
  </div>
  <div className="ssr-variant hidden-ollpel hidden-9i23oi hidden-q2nkjo">
    <div className="framer-15cw2a5-container" data-framer-name="data visualization" name="data visualization">
      <div name="data visualization" className="framer-IvKEh framer-M4n02 framer-UGujo framer-1cc99ru framer-v-1lf72iq" data-framer-name="Mobile - After - smaller img" style={{borderBottomWidth: '0px', borderColor: 'rgba(0, 0, 0, 0)', borderLeftWidth: '0px', borderRightWidth: '0px', borderStyle: 'solid', borderTopWidth: '0px', backgroundColor: 'rgb(246, 246, 251)', borderBottomLeftRadius: '16px', borderBottomRightRadius: '16px', borderTopLeftRadius: '16px', borderTopRightRadius: '16px', height: '100%', width: '100%'}} tabIndex={0}>
        <div className="framer-hwr9l5">
          <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
            <img decoding="async" sizes="(min-width: 905px) and (max-width: 1239px) calc(calc((min(100vw, 1240px) - 48px) * 0.46) * 0.46)" srcSet="https://framerusercontent.com/images/weAS1gLkyTyll4pfY81VZmuM.png?scale-down-to=512 512w, https://framerusercontent.com/images/weAS1gLkyTyll4pfY81VZmuM.png 956w" src="https://framerusercontent.com/images/weAS1gLkyTyll4pfY81VZmuM.png" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'contain', imageRendering: 'auto'}} data-framer-original-sizes="calc(calc((min(100vw, 1240px) - 48px) * 0.46) * 0.46)" />
          </div>
        </div>
        <div className="framer-7xcdew">
          <div className="framer-wnn4gv">
            <div className="framer-1egupmm">
              <div className="framer-410ztq" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))', transform: 'none'}} data-framer-component-type="RichTextContainer">
                <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTkwMA==', fontFamily: '"Noto Sans JP", sans-serif', fontWeight: 900, lineHeight: '1.5em', textAlign: 'left', color: 'var(--extracted-r6o4lv, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))'}} className="framer-text">
                   Báo cáo đánh giá nhân viên
                </p>
              </div>
             
            </div>
            <div className="framer-1ha6s17" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))', transform: 'none'}} data-framer-component-type="RichTextContainer">
              <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTUwMA==', fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', fontSize: '14px', fontWeight: 500, lineHeight: '1.5em', textAlign: 'left', color: 'var(--extracted-r6o4lv, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))'}} className="framer-text">
                Theo dõi sự tiến bộ của người lao động: thông qua các kỳ đánh giá, đánh giá được sự cải tiến và chất lượng, năng suất lao động, theo dõi được quá trình thăng tiến của người lao động.
              </p>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
  <div className="ssr-variant hidden-ollpel hidden-9i23oi hidden-150dn05">
    <div className="framer-15cw2a5-container" data-framer-name="data visualization" name="data visualization">
      <div name="data visualization" className="framer-IvKEh framer-M4n02 framer-UGujo framer-1cc99ru framer-v-1lf72iq" data-framer-name="Mobile - After - smaller img" style={{borderBottomWidth: '0px', borderColor: 'rgba(0, 0, 0, 0)', borderLeftWidth: '0px', borderRightWidth: '0px', borderStyle: 'solid', borderTopWidth: '0px', backgroundColor: 'rgb(246, 246, 251)', borderBottomLeftRadius: '16px', borderBottomRightRadius: '16px', borderTopLeftRadius: '16px', borderTopRightRadius: '16px', height: '100%', width: '100%'}} tabIndex={0}>
        <div className="framer-hwr9l5">
          <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
            <img decoding="async" sizes="(min-width: 600px) and (max-width: 904px) calc(calc(min(100vw, 700px) - 48px) * 0.66), (max-width: 599px) calc(calc(min(100vw, 480px) - 32px) * 0.46)" srcSet="https://framerusercontent.com/images/XF9l2Pl2uOBzUUnHiqfHMxKw7VA.png?scale-down-to=512 512w, https://framerusercontent.com/images/XF9l2Pl2uOBzUUnHiqfHMxKw7VA.png 717w" src="https://framerusercontent.com/images/XF9l2Pl2uOBzUUnHiqfHMxKw7VA.png" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'contain', imageRendering: 'auto'}} data-framer-original-sizes="calc(calc(min(100vw, 480px) - 32px) * 0.46)" />
          </div>
        </div>
        <div className="framer-7xcdew">
          <div className="framer-wnn4gv">
            <div className="framer-1egupmm">
              <div className="framer-410ztq" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))', transform: 'none'}} data-framer-component-type="RichTextContainer">
                <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTkwMA==', fontFamily: '"Noto Sans JP", sans-serif', fontWeight: 900, lineHeight: '1.5em', textAlign: 'left', color: 'var(--extracted-r6o4lv, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))'}} className="framer-text">
                   Báo cáo đánh giá nhân viên
                </p>
              </div>
             
            </div>
            <div className="framer-1ha6s17" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))', transform: 'none'}} data-framer-component-type="RichTextContainer">
              <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTUwMA==', fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', fontSize: '14px', fontWeight: 500, lineHeight: '1.5em', textAlign: 'left', color: 'var(--extracted-r6o4lv, var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41)))'}} className="framer-text">
                Theo dõi sự tiến bộ của người lao động: thông qua các kỳ đánh giá, đánh giá được sự cải tiến và chất lượng, năng suất lao động, theo dõi được quá trình thăng tiến của người lao động.
              </p>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  );
}

export default Section3Component;
