import { useEffect, useState, useRef } from "react";

function Section2Component() {
  const [display, setDisplay] = useState(0);

const onHoverElement = (id) => {
  const hoverItems = document.querySelectorAll('.hover-list');
    hoverItems.forEach((item, i) => {
        hoverItems[i].classList.remove('custom-class')
    })
    hoverItems[id].classList.add('custom-class')
    setDisplay(id)
  }
  const listFeature = [
    {
        title: "Quản lý nhân sự",
        img: "./Group 9.png",
        description1: "Giúp quản lý toàn diện thông tin của người lao động từ ngày vào công ty cho đến khi nghỉ việc ",
        description2: "Công cụ hỗ trợ đắc lực cho nhà quản lý theo dõi về tình trạng hợp đồng, chế độ cho người lao động, phần mềm đưa ra các cảnh báo đến hạn nâng lương, hết hạn hợp đồng…."
    }, 
    {
        title: "Giải pháp chấm công bằng khuôn mặt",
        img: "./Group 5.png",
        description1: "Sử dụng công nghệ nhận diện bằng khuôn mặt để theo dõi thời gian ra vào của nhân viên. ",
        description2: "Giải quyết triệt để bài toán chấm công phù hợp với mọi loại hình doanh nghiệp. Kết nối lấy dữ liệu chấm công và tính toán chia tách thời gian làm việc thực tế, số phút đi muộn về sớm…"
    },
    {
        title: "Quản lý lương nhân viên",
        img: "./Group 11.png",
        description1: "Tính toán chi tiết các loại lương trong danh mục loại lương như: Lương thời gian, lương sản phẩm, lương làm thêm giờ, phụ cấp các loại… ",
        description2: "Cho phép người dùng tự định nghĩa các tham số, thiết lập công thức, nhập các hệ số, mức lương, mức thưởng…Do đó giúp cho người sử dụng có thể hoàn toàn chủ động và linh hoạt khi sử dụng"
    },
    {
        title: "Quản lý bảo hiểm",
        img: "./Group 12.png",
        description1: "Công cụ hỗ trợ đắc lực người làm bảo hiểm quản lý từng đợt phát sinh tăng giảm bảo hiểm của doanh nghiệp..",
        description2: "Tổng hợp chi tiết mức đóng bảo hiểm của người lao động: Mức lương đóng, hệ số đóng, doanh nghiệp đóng, người lao động phải đóng bảo hiểm."
    }
  ]
  return (
    <>
      <div
        className="framer-1b6grsm"
        data-framer-name="Product"
        name="Product"
        style={{ display: "block !important", marginBottom: 24 }}
      >
        <div className="framer-d72m6s-container">
        <a
            name="Messenger - selected"
            className="framer-LLuvs framer-1o4mfei framer-v-1xe9fcu framer-zluhaf hover-list"
            data-framer-name="JP Font - no bg - Selected"
            data-highlight="true"
           
            tabIndex={0}
            onMouseEnter={() => onHoverElement(0)}
          >
            <div
              className="framer-t0vvxd"
              style={{
                backgroundColor: "rgba(0, 0, 0, 0)",
                borderBottomLeftRadius: "16px",
                borderBottomRightRadius: "16px",
                borderTopLeftRadius: "16px",
                borderTopRightRadius: "16px",
              }}
            >
              <div
                className="framer-45u3hb"
                style={{ transform: "translate(-50%, 50%)", top: 0 }}
              >
                <div
                  style={{
                    position: "absolute",
                    borderRadius: "inherit",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                  }}
                  data-framer-background-image-wrapper="true"
                >
                  <img
                    decoding="async"
                    sizes="38px"
                    src="https://framerusercontent.com/images/jUJEbEYIiOylbFngKykNR5pSc.png"
                    alt=""
                    style={{
                      display: "block",
                      width: "100%",
                      height: "100%",
                      borderRadius: "inherit",
                      objectPosition: "center",
                      objectFit: "contain",
                      imageRendering: "auto",
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className="framer-x8fz64"
              style={{
                outline: "none",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                flexShrink: 0,
                extractedR6o4lv:
                  "var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))",
                framerLinkTextColor: "rgb(0, 153, 255)",
                framerLinkTextDecoration: "underline",
                transform: "translateX(-50%)",
              }}
              data-framer-component-type="RichTextContainer"
            >
              <p
                style={{
                  fontSelector: "R0Y7Tm90byBTYW5zIEpQLTUwMA==",
                  fontFamily:
                    '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
                  fontWeight: 500,
                  textAlign: "center",
                  color:
                    "var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))",
                }}
                className="framer-text"
              >
                Nhân sự
              </p>
            </div>
           {
            display == 0 && (
                <div
                data-framer-component-type="SVG"
                data-framer-name="Union"
                style={{
                  imageRendering: "pixelated",
                  flexShrink: 0,
                  fill: "rgba(0, 0, 0, 1)",
                  color: "rgba(0, 0, 0, 1)",
                }}
                className="framer-1ktcuia"
              >
                <div
                  className="svgContainer"
                  style={{
                    width: "100%",
                    height: "100%",
                    aspectRatio: "inherit",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    style={{ width: "100%", height: "100%" }}
                    viewBox="0 0 92 14"
                  >
                    <use href="#svg-449754127_719" />
                  </svg>
                </div>
              </div>
            )
           }
          </a>
        </div>

        <div
          className="framer-1bwl10k-container"
          data-framer-name="Messenger - selected"
          name="Messenger - selected"
        >
          <a
            name="Messenger - selected"
            className="framer-LLuvs framer-1o4mfei framer-v-1xe9fcu framer-zluhaf hover-list"
            data-framer-name="JP Font - no bg - Selected"
            data-highlight="true"
           
            tabIndex={0}
            onMouseEnter={() => onHoverElement(1)}
          >
            <div
              className="framer-t0vvxd"
              style={{
                backgroundColor: "rgba(0, 0, 0, 0)",
                borderBottomLeftRadius: "16px",
                borderBottomRightRadius: "16px",
                borderTopLeftRadius: "16px",
                borderTopRightRadius: "16px",
              }}
            >
              <div
                className="framer-45u3hb"
                style={{ transform: "translate(-50%, 50%)", top: 0 }}
              >
                <div
                  style={{
                    position: "absolute",
                    borderRadius: "inherit",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                  }}
                  data-framer-background-image-wrapper="true"
                >
                  <img
                    decoding="async"
                    sizes="38px"
                    src="https://framerusercontent.com/images/QbJOm93UUnVkMGCW59pB6XTENw.png"
                    alt=""
                    style={{
                      display: "block",
                      width: "100%",
                      height: "100%",
                      borderRadius: "inherit",
                      objectPosition: "center",
                      objectFit: "contain",
                      imageRendering: "auto",
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className="framer-x8fz64"
              style={{
                outline: "none",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                flexShrink: 0,
                extractedR6o4lv:
                  "var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))",
                framerLinkTextColor: "rgb(0, 153, 255)",
                framerLinkTextDecoration: "underline",
                transform: "translateX(-50%)",
              }}
              data-framer-component-type="RichTextContainer"
            >
              <p
                style={{
                  fontSelector: "R0Y7Tm90byBTYW5zIEpQLTUwMA==",
                  fontFamily:
                    '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
                  fontWeight: 500,
                  textAlign: "center",
                  color:
                    "var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))",
                }}
                className="framer-text"
              >
                Chấm công
              </p>
            </div>
           {
            display == 1 && (
                <div
                data-framer-component-type="SVG"
                data-framer-name="Union"
                style={{
                  imageRendering: "pixelated",
                  flexShrink: 0,
                  fill: "rgba(0, 0, 0, 1)",
                  color: "rgba(0, 0, 0, 1)",
                }}
                className="framer-1ktcuia"
              >
                <div
                  className="svgContainer"
                  style={{
                    width: "100%",
                    height: "100%",
                    aspectRatio: "inherit",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    style={{ width: "100%", height: "100%" }}
                    viewBox="0 0 92 14"
                  >
                    <use href="#svg-449754127_719" />
                  </svg>
                </div>
              </div>
            )
           }
          </a>
        </div>

        <div
          className="framer-16u6h76"
          data-framer-name="Meeting"
          data-highlight="true"
          style={{
            backgroundColor: "rgb(255, 255, 255)",
            borderBottomLeftRadius: "6px",
            borderBottomRightRadius: "6px",
            borderTopLeftRadius: "6px",
            borderTopRightRadius: "6px",
          }}
        >
            <a
            name="Messenger - selected"
            className="framer-LLuvs framer-1o4mfei framer-v-1xe9fcu framer-zluhaf hover-list"
            data-framer-name="JP Font - no bg - Selected"
            data-highlight="true"
           
            tabIndex={0}
            onMouseEnter={() => onHoverElement(2)}
          >
            <div
              className="framer-t0vvxd"
              style={{
                backgroundColor: "rgba(0, 0, 0, 0)",
                borderBottomLeftRadius: "16px",
                borderBottomRightRadius: "16px",
                borderTopLeftRadius: "16px",
                borderTopRightRadius: "16px",
              }}
            >
              <div
                className="framer-45u3hb"
                style={{ transform: "translate(-50%, 50%)", top: 0 }}
              >
                <div
                  style={{
                    position: "absolute",
                    borderRadius: "inherit",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                  }}
                  data-framer-background-image-wrapper="true"
                >
                  <img
                    decoding="async"
                    sizes="38px"
                    src="https://framerusercontent.com/images/XWX36GJD1rfQYqazCAdXY7LK84.png"
                    alt=""
                    style={{
                      display: "block",
                      width: "100%",
                      height: "100%",
                      borderRadius: "inherit",
                      objectPosition: "center",
                      objectFit: "contain",
                      imageRendering: "auto",
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className="framer-x8fz64"
              style={{
                outline: "none",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                flexShrink: 0,
                extractedR6o4lv:
                  "var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))",
                framerLinkTextColor: "rgb(0, 153, 255)",
                framerLinkTextDecoration: "underline",
                transform: "translateX(-50%)",
              }}
              data-framer-component-type="RichTextContainer"
            >
              <p
                style={{
                  fontSelector: "R0Y7Tm90byBTYW5zIEpQLTUwMA==",
                  fontFamily:
                    '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
                  fontWeight: 500,
                  textAlign: "center",
                  color:
                    "var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))",
                }}
                className="framer-text"
              >
                Lương
              </p>
            </div>
           {
            display == 2 && (
                <div
                data-framer-component-type="SVG"
                data-framer-name="Union"
                style={{
                  imageRendering: "pixelated",
                  flexShrink: 0,
                  fill: "rgba(0, 0, 0, 1)",
                  color: "rgba(0, 0, 0, 1)",
                }}
                className="framer-1ktcuia"
              >
                <div
                  className="svgContainer"
                  style={{
                    width: "100%",
                    height: "100%",
                    aspectRatio: "inherit",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    style={{ width: "100%", height: "100%" }}
                    viewBox="0 0 92 14"
                  >
                    <use href="#svg-449754127_719" />
                  </svg>
                </div>
              </div>
            )
           }
          </a>
        </div>
        <div
          className="framer-19x5u2e"
          data-framer-name="Minutes"
          data-highlight="true"
          style={{
            backgroundColor: "rgb(255, 255, 255)",
            borderBottomLeftRadius: "6px",
            borderBottomRightRadius: "6px",
            borderTopLeftRadius: "6px",
            borderTopRightRadius: "6px",
          }}
        >
            <a
            name="Messenger - selected"
            className="framer-LLuvs framer-1o4mfei framer-v-1xe9fcu framer-zluhaf custom-class hover-list"
            data-framer-name="JP Font - no bg - Selected"
            data-highlight="true"
           
            tabIndex={0}
            onMouseEnter={() => onHoverElement(3)}
          >
            <div
              className="framer-t0vvxd"
              style={{
                backgroundColor: "rgba(0, 0, 0, 0)",
                borderBottomLeftRadius: "16px",
                borderBottomRightRadius: "16px",
                borderTopLeftRadius: "16px",
                borderTopRightRadius: "16px",
              }}
            >
              <div
                className="framer-45u3hb"
                style={{ transform: "translate(-50%, 50%)", top: 0 }}
              >
                <div
                  style={{
                    position: "absolute",
                    borderRadius: "inherit",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                  }}
                  data-framer-background-image-wrapper="true"
                >
                  <img
                    decoding="async"
                    sizes="38px"
                    src="https://framerusercontent.com/images/M1NrR3NohBo3TMfTO8SpqxNMwH0.png?scale-down-to=1024"
                    alt=""
                    style={{
                      display: "block",
                      width: "100%",
                      height: "100%",
                      borderRadius: "inherit",
                      objectPosition: "center",
                      objectFit: "contain",
                      imageRendering: "auto",
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className="framer-x8fz64"
              style={{
                outline: "none",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                flexShrink: 0,
                extractedR6o4lv:
                  "var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))",
                framerLinkTextColor: "rgb(0, 153, 255)",
                framerLinkTextDecoration: "underline",
                transform: "translateX(-50%)",
              }}
              data-framer-component-type="RichTextContainer"
            >
              <p
                style={{
                  fontSelector: "R0Y7Tm90byBTYW5zIEpQLTUwMA==",
                  fontFamily:
                    '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
                  fontWeight: 500,
                  textAlign: "center",
                  color:
                    "var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))",
                }}
                className="framer-text"
              >
                Bảo hiểm
              </p>
            </div>
            {
                display == 3 && (
                    <div
              data-framer-component-type="SVG"
              data-framer-name="Union"
              style={{
                imageRendering: "pixelated",
                flexShrink: 0,
                fill: "rgba(0, 0, 0, 1)",
                color: "rgba(0, 0, 0, 1)",
              }}
              className="framer-1ktcuia"
            >
              <div
                className="svgContainer"
                style={{
                  width: "100%",
                  height: "100%",
                  aspectRatio: "inherit",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  style={{ width: "100%", height: "100%" }}
                  viewBox="0 0 92 14"
                >
                  <use href="#svg-449754127_719" />
                </svg>
              </div>
            </div>
                )
            }
          </a>
        </div>
        <div className="framer-sssp26 " data-framer-name="Calendar" data-highlight="true" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px', borderTopLeftRadius: '6px', borderTopRightRadius: '6px', display: 'none'}}>
  <div className="framer-4dog9y" style={{borderBottomLeftRadius: '16px', borderBottomRightRadius: '16px', borderTopLeftRadius: '16px', borderTopRightRadius: '16px'}}>
    <div className="framer-1f510xq" style={{transform: 'translate(-50%, -50%)'}}>
      <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
        <img decoding="async" src="https://framerusercontent.com/images/qaEwxO6ci4lmW1ozxihFzBWKvE.svg" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'cover', imageRendering: 'auto'}} />
      </div>
    </div>
  </div>
  <div className="framer-1sibaza" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
    <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTUwMA==', framerFontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', framerFontWeight: 500, framerTextAlignment: 'center', framerTextColor: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'}} className="framer-text">
     Nghỉ phép
    </p>
  </div>
</div>

      </div>
      <div className="framer-1s47dgr display-desktop" data-framer-name="Feature Screenshot" style={{borderBottomLeftRadius: '24px', borderBottomRightRadius: '24px', borderTopLeftRadius: '24px', borderTopRightRadius: '24px'}}>
  <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
    <img decoding="async" src={listFeature[display].img} alt="メッセージ" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'cover', imageRendering: 'auto'}} />
  </div>
  <div className="framer-gf1rp3">
    <div className="framer-j0udfs">
      <div className="framer-16xuql2">
        <div className="framer-1toxow1" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, transform: 'none'}} data-framer-component-type="RichTextContainer">
          <h6 className="framer-text framer-styles-preset-h2swgp" data-styles-preset="rgvzP8QHm">
            {listFeature[display].title}
          </h6>
        </div>
        <div className="framer-1oq33iw-container">
          <a className="framer-43g8y framer-thvK1 framer-1xql04t framer-v-142g9u framer-14bqysp" data-framer-name="Text Link with icon" data-highlight="true" style={{borderBottomWidth: '0px', borderColor: 'rgba(0, 0, 0, 0)', borderLeftWidth: '0px', borderRightWidth: '0px', borderStyle: 'solid', borderTopWidth: '0px', backgroundColor: 'rgba(0, 0, 0, 0)', borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px', borderTopLeftRadius: '6px', borderTopRightRadius: '6px', boxShadow: 'none'}} href="#" rel="noopener" tabIndex={0}>
            <div className="framer-144pz35" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
              <p className="framer-text framer-styles-preset-91fca6" data-styles-preset="ikm7JuTuk" style={{color: 'var(--extracted-r6o4lv, var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255)))'}}>
                
              </p>
            </div>
            <div className="framer-y8b76s-container"><div style={{display: 'contents'}} /></div>
          </a>
        </div>
      </div>
    </div>
    <div className="framer-eomqrc" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, transform: 'none'}} data-framer-component-type="RichTextContainer">
      <p className="framer-text framer-styles-preset-jg60nb" data-styles-preset="X82eKqeAg" style={{whiteSpace: 'pre-line', width: '1000px', marginTop: 4}}>
        {listFeature[display].description1}
      </p>
      <p className="framer-text framer-styles-preset-jg60nb" style={{whiteSpace: 'pre-line', width: '1000px', marginTop: 4}}>
        {listFeature[display].description2}

 
      </p>
    </div>
  </div>
</div>
<div className="framer-1lkehf8 display-mobile" data-framer-name="Features Script" style={{opacity: 1, background: '#E5E7F2'}}>
  <div className="framer-1k8o00f" style={{opacity: 1}}>
    <div className="framer-1ipmm6h" style={{opacity: 1}}>
      <div className="framer-jbbymo" data-framer-component-type="RichTextContainer" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, transform: 'none', opacity: 1}}>
      <p 
  className="framer-text" 
  style={{ 
    fontFamily: '"Noto Sans JP", sans-serif', 
    fontSize: '20px', 
    fontWeight: 900, 
    lineHeight: '1.5em', 
    textAlign: 'left' 
  }}
>
    {listFeature[display].title}
</p>

       
      </div>
      <div className="framer-v698sa-container" style={{opacity: 1}}>
        <a className="framer-43g8y framer-thvK1 framer-1xql04t framer-v-142g9u framer-14bqysp" data-framer-name="Text Link with icon" data-highlight="true" href="https://www.larksuite.com/ja_jp/product/messenger?from=home_product" rel="noopener" tabIndex={0} style={{borderBottomWidth: '0px', borderColor: 'rgba(0, 0, 0, 0)', borderLeftWidth: '0px', borderRightWidth: '0px', borderStyle: 'solid', borderTopWidth: '0px', backgroundColor: 'rgba(0, 0, 0, 0)', borderRadius: '6px', boxShadow: 'none', opacity: 1}}>
          <div className="framer-144pz35" data-framer-component-type="RichTextContainer" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none', opacity: 1}}>
            <p className="framer-text framer-styles-preset-91fca6" data-styles-preset="ikm7JuTuk" style={{framerTextColor: 'var(--extracted-r6o4lv, var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255)))'}}>
             
            </p>
          </div>
        </a>
      </div>
    </div>
    <div className="framer-yy0nlj" style={{opacity: 1}}>
      <div className="framer-uwep9x" data-framer-component-type="RichTextContainer" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, transform: 'none', opacity: 1}}>
        <p className="framer-text" style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLXJlZ3VsYXI=', fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', fontSize: '18px', lineHeight: '1.5em', textAlignment: 'left'}}>
         
    {listFeature[display].description1}
        </p>
        <p className="framer-text" style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLXJlZ3VsYXI=', fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', fontSize: '18px', lineHeight: '1.5em', textAlignment: 'left'}}>
    {listFeature[display].description2}
          </p>
      </div>
    </div>
  </div>
</div>

    </>
  );
}

export default Section2Component;
