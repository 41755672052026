function StepRegister() {
    return ( 
        <div className="framer-1syxgo7" data-framer-name="Steps of Lark" name="Steps of Lark">
        <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
          <img decoding="async" loading="lazy" sizes="(min-width: 1240px) 100vw, (min-width: 905px) and (max-width: 1239px) 100vw, (min-width: 600px) and (max-width: 904px) 100vw, (max-width: 599px) 100vw" srcSet="
                                    https://framerusercontent.com/images/vfOmA0o808nRr6BTUe8oKsLpi0.png?scale-down-to=512   512w,
                                    https://framerusercontent.com/images/vfOmA0o808nRr6BTUe8oKsLpi0.png?scale-down-to=1024 1024w,
                                    https://framerusercontent.com/images/vfOmA0o808nRr6BTUe8oKsLpi0.png?scale-down-to=2048 2048w,
                                    https://framerusercontent.com/images/vfOmA0o808nRr6BTUe8oKsLpi0.png                    2272w
                                " src="https://framerusercontent.com/images/vfOmA0o808nRr6BTUe8oKsLpi0.png" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'cover', imageRendering: 'auto'}} data-framer-original-sizes="100vw" />
        </div>
        <div className="framer-tv0ktm">
          <div className="framer-1pmbe6m">
          <div className="framer-1kx0oi6">
        <div 
          className="framer-148n41r" 
          style={{
            outline: 'none',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            flexShrink: 0,
            transform: 'none'
          }} 
          data-framer-component-type="RichTextContainer"
        >
          <h3 
            className="framer-text framer-styles-preset-1k1rjey" 
            data-styles-preset="iT7awcTns"
          >
            Quy trình đăng ký sử dụng phần mềm
          </h3>
        </div>
        <div 
          className="framer-1ov4woq" 
          style={{
            outline: 'none',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            flexShrink: 0,
            transform: 'none'
          }} 
          data-framer-component-type="RichTextContainer"
        >
          <p 
            className="framer-text framer-styles-preset-jg60nb" 
            data-styles-preset="X82eKqeAg"
          >
            <span 
              style={{
                color: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))'
              }} 
              className="framer-text"
            >
              Hãy làm theo hướng dẫn ở dưới đây
            </span>
            <br className="framer-text" />
            <span 
              style={{
                color: 'var(--token-1b3671a7-ddbb-4586-b516-422ef5d98b58, rgb(31, 35, 41))'
              }} 
              className="framer-text"
            >
              Chúng tôi sẽ hỗ trợ cho bạn.
            </span>
          </p>
        </div>
      </div>
      
          </div>
          <div className="framer-17w3w6l">
            <div className="ssr-variant hidden-9i23oi hidden-150dn05 hidden-q2nkjo">
              <div className="framer-1ykogaz hidden-ollpel" style={{transform: 'rotate(90deg) translateZ(0)'}}>
                <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                  <img decoding="async" src="https://framerusercontent.com/images/89UnvC1a2bCZe8QLOepmdNxNRIY.svg" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'cover', imageRendering: 'auto'}} />
                </div>
              </div>
            </div>
            <div className="ssr-variant hidden-ollpel hidden-150dn05 hidden-q2nkjo">
              <div className="framer-1ykogaz hidden-ollpel" style={{transform: 'rotate(90deg) translateZ(0)'}}>
                <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                  <img decoding="async" loading="lazy" src="https://framerusercontent.com/images/89UnvC1a2bCZe8QLOepmdNxNRIY.svg" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'cover', imageRendering: 'auto'}} />
                </div>
              </div>
            </div>
            <div className="ssr-variant hidden-ollpel hidden-9i23oi hidden-q2nkjo">
              <div className="framer-1ykogaz hidden-ollpel" style={{transform: 'rotate(90deg) translateZ(0)'}}>
                <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                  <img decoding="async" loading="lazy" src="https://framerusercontent.com/images/89UnvC1a2bCZe8QLOepmdNxNRIY.svg" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'cover', imageRendering: 'auto'}} />
                </div>
              </div>
            </div>
            <div className="ssr-variant hidden-ollpel hidden-9i23oi hidden-150dn05">
              <div className="framer-1ykogaz hidden-ollpel" style={{transform: 'rotate(90deg) translateZ(0)'}}>
                <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                  <img decoding="async" loading="lazy" src="https://framerusercontent.com/images/89UnvC1a2bCZe8QLOepmdNxNRIY.svg" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'cover', imageRendering: 'auto'}} />
                </div>
              </div>
            </div>
            <div className="ssr-variant hidden-q2nkjo">
              <div className="framer-12mglrr hidden-150dn05 hidden-9i23oi">
                <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                  <img decoding="async" loading="lazy" src="https://framerusercontent.com/images/obr8fDIepRP0nVM1OZsPx4Pj6FI.svg" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'cover', imageRendering: 'auto'}} />
                </div>
              </div>
            </div>
            <div className="ssr-variant hidden-ollpel hidden-9i23oi hidden-150dn05">
              <div className="framer-12mglrr hidden-150dn05 hidden-9i23oi" style={{transform: 'rotate(90deg) translateZ(0)'}}>
                <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                  <img decoding="async" loading="lazy" src="https://framerusercontent.com/images/obr8fDIepRP0nVM1OZsPx4Pj6FI.svg" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'cover', imageRendering: 'auto'}} />
                </div>
              </div>
            </div>
            <div className="ssr-variant hidden-q2nkjo">
              <div className="framer-f8r1oc">
                <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                  <img decoding="async" loading="lazy" src="https://framerusercontent.com/images/89UnvC1a2bCZe8QLOepmdNxNRIY.svg" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'cover', imageRendering: 'auto'}} />
                </div>
              </div>
            </div>
            <div className="ssr-variant hidden-ollpel hidden-9i23oi hidden-150dn05">
              <div className="framer-f8r1oc" style={{transform: 'rotate(90deg) translateZ(0)'}}>
                <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                  <img decoding="async" loading="lazy" src="https://framerusercontent.com/images/89UnvC1a2bCZe8QLOepmdNxNRIY.svg" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'cover', imageRendering: 'auto'}} />
                </div>
              </div>
            </div>
            <div className="framer-1cfjzzb hidden-150dn05 hidden-9i23oi hidden-q2nkjo">
              <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                <img decoding="async" loading="lazy" src="https://framerusercontent.com/images/obr8fDIepRP0nVM1OZsPx4Pj6FI.svg" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'cover', imageRendering: 'auto'}} />
              </div>
            </div>
            <div className="ssr-variant hidden-9i23oi hidden-150dn05 hidden-q2nkjo">
              <div className="framer-1uzgol2-container">
                <div className="framer-gDYHu framer-UGujo framer-zG9tg framer-6x9247 framer-v-6x9247" data-framer-name="Variant 1" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', height: '100%', width: '100%'}} tabIndex={0}>
                  <div className="framer-1136j8q">
                    <div className="framer-qyef0j-container">
                      <a className="framer-43g8y framer-thvK1 framer-1xql04t framer-v-142g9u framer-14bqysp" data-framer-name="Text Link with icon" data-highlight="true" style={{borderBottomWidth: '0px', borderColor: 'rgba(0, 0, 0, 0)', borderLeftWidth: '0px', borderRightWidth: '0px', borderStyle: 'solid', borderTopWidth: '0px', backgroundColor: 'rgba(0, 0, 0, 0)', borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px', borderTopLeftRadius: '6px', borderTopRightRadius: '6px', boxShadow: 'none'}} href="global/salessupport0e85.html?lang=ja-JP&from=home_onboard_steps" rel="noopener" tabIndex={0}>
                        <div className="framer-144pz35" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                          <p className="framer-text framer-styles-preset-91fca6" data-styles-preset="ikm7JuTuk" style={{framerTextColor: 'var(--extracted-r6o4lv, var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255)))'}}>
                           
                          </p>
                        </div>
                        <div className="framer-y8b76s-container"><div style={{display: 'contents'}} /></div>
                      </a>
                    </div>
                  </div>
                  <div className="framer-jmjnku" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                    <p className="framer-text framer-styles-preset-jg60nb" data-styles-preset="X82eKqeAg">
                      Điền đầy đủ thông tin, chúng tôi sẽ liên hệ cho bạn.
                    </p>
                  </div>
                  <div className="framer-b19xlc" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                    {/* <h6 className="framer-text framer-styles-preset-h2swgp" data-styles-preset="rgvzP8QHm">  Điền Form đăng ký</h6> */}
                  </div>
                  <div className="framer-10x28tw">
                    <div className="framer-l14z9g" style={{background: 'linear-gradient( 138deg, rgb(51, 214, 192) 0%, rgb(80, 131, 251) 24.31376689189189%, rgb(51, 109, 244) 48.52899774774775%, rgb(71, 82, 230) 71.5917088963964%, rgb(141, 85, 237) 100% )', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translateX(-50%)'}} />
                    <div className="framer-xj23t2" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translate(-50%, 50%)'}}>
                      <div className="framer-ri8ki2" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translate(-50%, 50%)'}} data-framer-component-type="RichTextContainer">
                       <p 
        style={{
          fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
          fontSize: '36px',
          fontWeight: 500,
          color: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'
        }} 
        className="framer-text"
      >
        <span 
          data-text-fill="true" 
          style={{
            backgroundImage: 'linear-gradient(89deg, rgb(65, 164, 255) 0%, rgb(99, 143, 255) 48.6486%, rgb(149, 84, 255) 99.0521%)'
          }} 
          className="framer-text"
        >
          01
        </span>
      </p>
      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ssr-variant hidden-ollpel hidden-150dn05 hidden-q2nkjo">
              <div className="framer-1uzgol2-container">
                <div className="framer-gDYHu framer-UGujo framer-zG9tg framer-6x9247 framer-v-6x9247" data-framer-name="Variant 1" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', height: '100%', width: '100%'}} tabIndex={0}>
                  <div className="framer-1136j8q">
                    <div className="framer-qyef0j-container">
                      <a className="framer-43g8y framer-thvK1 framer-1xql04t framer-v-142g9u framer-14bqysp" data-framer-name="Text Link with icon" data-highlight="true" style={{borderBottomWidth: '0px', borderColor: 'rgba(0, 0, 0, 0)', borderLeftWidth: '0px', borderRightWidth: '0px', borderStyle: 'solid', borderTopWidth: '0px', backgroundColor: 'rgba(0, 0, 0, 0)', borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px', borderTopLeftRadius: '6px', borderTopRightRadius: '6px', boxShadow: 'none'}} href="global/salessupport0e85.html?lang=ja-JP&from=home_onboard_steps" rel="noopener" tabIndex={0}>
                        <div className="framer-144pz35" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                          <p className="framer-text framer-styles-preset-91fca6" data-styles-preset="ikm7JuTuk" style={{framerTextColor: 'var(--extracted-r6o4lv, var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255)))'}}>
                         
                          </p>
                        </div>
                        <div className="framer-y8b76s-container"><div style={{display: 'contents'}} /></div>
                      </a>
                    </div>
                  </div>
                  <div className="framer-jmjnku" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                    <p className="framer-text framer-styles-preset-jg60nb" data-styles-preset="X82eKqeAg">
                     Bạn vui lòng điền đầy đủ thông tin, chúng tôi sẽ liên hệ lại cho bạn
                    </p>
                  </div>
                  <div className="framer-b19xlc" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                    {/* <h6 className="framer-text framer-styles-preset-h2swgp" data-styles-preset="rgvzP8QHm">  Điền Form đăng ký</h6> */}
                  </div>
                  <div className="framer-10x28tw">
                    <div className="framer-l14z9g" style={{background: 'linear-gradient( 138deg, rgb(51, 214, 192) 0%, rgb(80, 131, 251) 24.31376689189189%, rgb(51, 109, 244) 48.52899774774775%, rgb(71, 82, 230) 71.5917088963964%, rgb(141, 85, 237) 100% )', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translateX(-50%)'}} />
                    <div className="framer-xj23t2" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translate(-50%, 50%)'}}>
                      <div className="framer-ri8ki2" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translate(-50%, 50%)'}} data-framer-component-type="RichTextContainer">
                       <p 
        style={{
          fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
          fontSize: '36px',
          fontWeight: 500,
          color: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'
        }} 
        className="framer-text"
      >
        <span 
          data-text-fill="true" 
          style={{
            backgroundImage: 'linear-gradient(89deg, rgb(65, 164, 255) 0%, rgb(99, 143, 255) 48.6486%, rgb(149, 84, 255) 99.0521%)'
          }} 
          className="framer-text"
        >
          01
        </span>
      </p>
      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ssr-variant hidden-ollpel hidden-9i23oi hidden-q2nkjo">
              <div className="framer-1uzgol2-container">
                <div className="framer-gDYHu framer-UGujo framer-zG9tg framer-6x9247 framer-v-6x9247" data-framer-name="Variant 1" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', height: '100%', width: '100%'}} tabIndex={0}>
                  <div className="framer-1136j8q">
                    <div className="framer-qyef0j-container">
                      <a className="framer-43g8y framer-thvK1 framer-1xql04t framer-v-142g9u framer-14bqysp" data-framer-name="Text Link with icon" data-highlight="true" style={{borderBottomWidth: '0px', borderColor: 'rgba(0, 0, 0, 0)', borderLeftWidth: '0px', borderRightWidth: '0px', borderStyle: 'solid', borderTopWidth: '0px', backgroundColor: 'rgba(0, 0, 0, 0)', borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px', borderTopLeftRadius: '6px', borderTopRightRadius: '6px', boxShadow: 'none'}} href="global/salessupport0e85.html?lang=ja-JP&from=home_onboard_steps" rel="noopener" tabIndex={0}>
                        <div className="framer-144pz35" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                          <p className="framer-text framer-styles-preset-91fca6" data-styles-preset="ikm7JuTuk" style={{framerTextColor: 'var(--extracted-r6o4lv, var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255)))'}}>
                         
                          </p>
                        </div>
                        <div className="framer-y8b76s-container"><div style={{display: 'contents'}} /></div>
                      </a>
                    </div>
                  </div>
                  <div className="framer-jmjnku" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                    <p className="framer-text framer-styles-preset-jg60nb" data-styles-preset="X82eKqeAg">
                     Bạn vui lòng điền đầy đủ thông tin, chúng tôi sẽ liên hệ lại cho bạn
                    </p>
                  </div>
                  <div className="framer-b19xlc" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                    <h6 className="framer-text framer-styles-preset-h2swgp" data-styles-preset="rgvzP8QHm">  Điền Form đăng ký</h6>
                  </div>
                  <div className="framer-10x28tw">
                    <div className="framer-l14z9g" style={{background: 'linear-gradient( 138deg, rgb(51, 214, 192) 0%, rgb(80, 131, 251) 24.31376689189189%, rgb(51, 109, 244) 48.52899774774775%, rgb(71, 82, 230) 71.5917088963964%, rgb(141, 85, 237) 100% )', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translateX(-50%)'}} />
                    <div className="framer-xj23t2" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translate(-50%, 50%)'}}>
                      <div className="framer-ri8ki2" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translate(-50%, 50%)'}} data-framer-component-type="RichTextContainer">
                       <p 
        style={{
          fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
          fontSize: '36px',
          fontWeight: 500,
          color: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'
        }} 
        className="framer-text"
      >
        <span 
          data-text-fill="true" 
          style={{
            backgroundImage: 'linear-gradient(89deg, rgb(65, 164, 255) 0%, rgb(99, 143, 255) 48.6486%, rgb(149, 84, 255) 99.0521%)'
          }} 
          className="framer-text"
        >
          01
        </span>
      </p>
      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ssr-variant hidden-ollpel hidden-9i23oi hidden-150dn05">
              <div className="framer-1uzgol2-container">
                <div className="framer-gDYHu framer-UGujo framer-zG9tg framer-6x9247 framer-v-6x9247" data-framer-name="Variant 1" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', height: '100%', width: '100%'}} tabIndex={0}>
                  <div className="framer-1136j8q">
                    <div className="framer-qyef0j-container">
                      <a className="framer-43g8y framer-thvK1 framer-1xql04t framer-v-142g9u framer-14bqysp" data-framer-name="Text Link with icon" data-highlight="true" style={{borderBottomWidth: '0px', borderColor: 'rgba(0, 0, 0, 0)', borderLeftWidth: '0px', borderRightWidth: '0px', borderStyle: 'solid', borderTopWidth: '0px', backgroundColor: 'rgba(0, 0, 0, 0)', borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px', borderTopLeftRadius: '6px', borderTopRightRadius: '6px', boxShadow: 'none'}} href="global/salessupport0e85.html?lang=ja-JP&from=home_onboard_steps" rel="noopener" tabIndex={0}>
                        <div className="framer-144pz35" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                          <p className="framer-text framer-styles-preset-91fca6" data-styles-preset="ikm7JuTuk" style={{framerTextColor: 'var(--extracted-r6o4lv, var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255)))'}}>
                         
                          </p>
                        </div>
                        <div className="framer-y8b76s-container"><div style={{display: 'contents'}} /></div>
                      </a>
                    </div>
                  </div>
                  <div className="framer-jmjnku" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                    <p className="framer-text framer-styles-preset-jg60nb" data-styles-preset="X82eKqeAg">
                     Bạn vui lòng điền đầy đủ thông tin, chúng tôi sẽ liên hệ lại cho bạn
                    </p>
                  </div>
                  <div className="framer-b19xlc" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                    <h6 className="framer-text framer-styles-preset-h2swgp" data-styles-preset="rgvzP8QHm">  Điền Form đăng ký</h6>
                  </div>
                  <div className="framer-10x28tw">
                    <div className="framer-l14z9g" style={{background: 'linear-gradient( 138deg, rgb(51, 214, 192) 0%, rgb(80, 131, 251) 24.31376689189189%, rgb(51, 109, 244) 48.52899774774775%, rgb(71, 82, 230) 71.5917088963964%, rgb(141, 85, 237) 100% )', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translateX(-50%)'}} />
                    <div className="framer-xj23t2" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translate(-50%, 50%)'}}>
                      <div className="framer-ri8ki2" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translate(-50%, 50%)'}} data-framer-component-type="RichTextContainer">
                       <p 
        style={{
          fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
          fontSize: '36px',
          fontWeight: 500,
          color: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'
        }} 
        className="framer-text"
      >
        <span 
          data-text-fill="true" 
          style={{
            backgroundImage: 'linear-gradient(89deg, rgb(65, 164, 255) 0%, rgb(99, 143, 255) 48.6486%, rgb(149, 84, 255) 99.0521%)'
          }} 
          className="framer-text"
        >
          01
        </span>
      </p>
      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ssr-variant hidden-9i23oi hidden-150dn05 hidden-q2nkjo">
              <div className="framer-gwhrp1-container">
                <div className="framer-gDYHu framer-UGujo framer-zG9tg framer-6x9247 framer-v-6x9247" data-framer-name="Variant 1" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', height: '100%', width: '100%'}} tabIndex={0}>
                  <div className="framer-jmjnku" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                    <p className="framer-text framer-styles-preset-jg60nb" data-styles-preset="X82eKqeAg">
                      Chúng tôi tư vấn gói dịch vụ phù hợp với doanh nghiệp của bạn
                    </p>
                  </div>
                  <div className="framer-b19xlc" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                    <h6 className="framer-text framer-styles-preset-h2swgp" data-styles-preset="rgvzP8QHm">Lựa chọn gói dịch vụ</h6>
                  </div>
                  <div className="framer-10x28tw">
                    <div className="framer-l14z9g" style={{background: 'linear-gradient( 138deg, rgb(51, 214, 192) 0%, rgb(80, 131, 251) 24.31376689189189%, rgb(51, 109, 244) 48.52899774774775%, rgb(71, 82, 230) 71.5917088963964%, rgb(141, 85, 237) 100% )', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translateX(-50%)'}} />
                    <div className="framer-xj23t2" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translate(-50%, 50%)'}}>
                      <div className="framer-ri8ki2" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translate(-50%, 50%)'}} data-framer-component-type="RichTextContainer">
                       <p 
        style={{
          fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
          fontSize: '36px',
          fontWeight: 500,
          color: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'
        }} 
        className="framer-text"
      >
        <span 
          data-text-fill="true" 
          style={{
            backgroundImage: 'linear-gradient(89deg, rgb(65, 164, 255) 0%, rgb(99, 143, 255) 48.6486%, rgb(149, 84, 255) 99.0521%)'
          }} 
          className="framer-text"
        >
          02
        </span>
      </p>
      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ssr-variant hidden-ollpel hidden-150dn05 hidden-q2nkjo">
              <div className="framer-gwhrp1-container">
                <div className="framer-gDYHu framer-UGujo framer-zG9tg framer-6x9247 framer-v-6x9247" data-framer-name="Variant 1" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', height: '100%', width: '100%'}} tabIndex={0}>
                  <div className="framer-jmjnku" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                    <p className="framer-text framer-styles-preset-jg60nb" data-styles-preset="X82eKqeAg">
                      Chúng tôi tư vấn gói dịch vụ phù hợp với doanh nghiệp của bạn
                    </p>
                  </div>
                  <div className="framer-b19xlc" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                    <h6 className="framer-text framer-styles-preset-h2swgp" data-styles-preset="rgvzP8QHm">Lựa chọn gói dịch vụ</h6>
                  </div>
                  <div className="framer-10x28tw">
                    <div className="framer-l14z9g" style={{background: 'linear-gradient( 138deg, rgb(51, 214, 192) 0%, rgb(80, 131, 251) 24.31376689189189%, rgb(51, 109, 244) 48.52899774774775%, rgb(71, 82, 230) 71.5917088963964%, rgb(141, 85, 237) 100% )', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translateX(-50%)'}} />
                    <div className="framer-xj23t2" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translate(-50%, 50%)'}}>
                      <div className="framer-ri8ki2" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translate(-50%, 50%)'}} data-framer-component-type="RichTextContainer">
                       <p 
        style={{
          fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
          fontSize: '36px',
          fontWeight: 500,
          color: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'
        }} 
        className="framer-text"
      >
        <span 
          data-text-fill="true" 
          style={{
            backgroundImage: 'linear-gradient(89deg, rgb(65, 164, 255) 0%, rgb(99, 143, 255) 48.6486%, rgb(149, 84, 255) 99.0521%)'
          }} 
          className="framer-text"
        >
          02
        </span>
      </p>
      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ssr-variant hidden-ollpel hidden-9i23oi hidden-q2nkjo">
              <div className="framer-gwhrp1-container">
                <div className="framer-gDYHu framer-UGujo framer-zG9tg framer-6x9247 framer-v-6x9247" data-framer-name="Variant 1" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', height: '100%', width: '100%'}} tabIndex={0}>
                  <div className="framer-jmjnku" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                    <p className="framer-text framer-styles-preset-jg60nb" data-styles-preset="X82eKqeAg">
                      Chúng tôi tư vấn gói dịch vụ phù hợp với doanh nghiệp của bạn
                    </p>
                  </div>
                  <div className="framer-b19xlc" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                    <h6 className="framer-text framer-styles-preset-h2swgp" data-styles-preset="rgvzP8QHm">Lựa chọn gói dịch vụ</h6>
                  </div>
                  <div className="framer-10x28tw">
                    <div className="framer-l14z9g" style={{background: 'linear-gradient( 138deg, rgb(51, 214, 192) 0%, rgb(80, 131, 251) 24.31376689189189%, rgb(51, 109, 244) 48.52899774774775%, rgb(71, 82, 230) 71.5917088963964%, rgb(141, 85, 237) 100% )', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translateX(-50%)'}} />
                    <div className="framer-xj23t2" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translate(-50%, 50%)'}}>
                      <div className="framer-ri8ki2" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translate(-50%, 50%)'}} data-framer-component-type="RichTextContainer">
                       <p 
        style={{
          fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
          fontSize: '36px',
          fontWeight: 500,
          color: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'
        }} 
        className="framer-text"
      >
        <span 
          data-text-fill="true" 
          style={{
            backgroundImage: 'linear-gradient(89deg, rgb(65, 164, 255) 0%, rgb(99, 143, 255) 48.6486%, rgb(149, 84, 255) 99.0521%)'
          }} 
          className="framer-text"
        >
          02
        </span>
      </p>
      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ssr-variant hidden-ollpel hidden-9i23oi hidden-150dn05">
              <div className="framer-gwhrp1-container">
                <div className="framer-gDYHu framer-UGujo framer-zG9tg framer-6x9247 framer-v-6x9247" data-framer-name="Variant 1" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', height: '100%', width: '100%'}} tabIndex={0}>
                  <div className="framer-jmjnku" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                    <p className="framer-text framer-styles-preset-jg60nb" data-styles-preset="X82eKqeAg">
                      Chúng tôi tư vấn gói dịch vụ phù hợp với doanh nghiệp của bạn
                    </p>
                  </div>
                  <div className="framer-b19xlc" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                    <h6 className="framer-text framer-styles-preset-h2swgp" data-styles-preset="rgvzP8QHm">Lựa chọn gói dịch vụ</h6>
                  </div>
                  <div className="framer-10x28tw">
                    <div className="framer-l14z9g" style={{background: 'linear-gradient( 138deg, rgb(51, 214, 192) 0%, rgb(80, 131, 251) 24.31376689189189%, rgb(51, 109, 244) 48.52899774774775%, rgb(71, 82, 230) 71.5917088963964%, rgb(141, 85, 237) 100% )', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translateX(-50%)'}} />
                    <div className="framer-xj23t2" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translate(-50%, 50%)'}}>
                      <div className="framer-ri8ki2" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translate(-50%, 50%)'}} data-framer-component-type="RichTextContainer">
                       <p 
        style={{
          fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
          fontSize: '36px',
          fontWeight: 500,
          color: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'
        }} 
        className="framer-text"
      >
        <span 
          data-text-fill="true" 
          style={{
            backgroundImage: 'linear-gradient(89deg, rgb(65, 164, 255) 0%, rgb(99, 143, 255) 48.6486%, rgb(149, 84, 255) 99.0521%)'
          }} 
          className="framer-text"
        >
          02
        </span>
      </p>
      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ssr-variant hidden-q2nkjo">
              <div className="framer-12ajjor-container hidden-150dn05 hidden-9i23oi">
                <div className="framer-gDYHu framer-UGujo framer-zG9tg framer-6x9247 framer-v-6x9247" data-framer-name="Variant 1" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', height: '100%', width: '100%'}} tabIndex={0}>
                  <div className="framer-jmjnku" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                    <p className="framer-text framer-styles-preset-jg60nb" data-styles-preset="X82eKqeAg">
                      Chúng tôi sẽ gửi hợp đồng tới địa chỉ của bạn, bạn vui lòng đọc kĩ và kí, xong gửi lại cho chúng tôi
                    </p>
                  </div>
                  <div className="framer-b19xlc" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                    <h6 className="framer-text framer-styles-preset-h2swgp" data-styles-preset="rgvzP8QHm">Ký kết hợp đồng</h6>
                  </div>
                  <div className="framer-10x28tw">
                    <div className="framer-l14z9g" style={{background: 'linear-gradient( 138deg, rgb(51, 214, 192) 0%, rgb(80, 131, 251) 24.31376689189189%, rgb(51, 109, 244) 48.52899774774775%, rgb(71, 82, 230) 71.5917088963964%, rgb(141, 85, 237) 100% )', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translateX(-50%)'}} />
                    <div className="framer-xj23t2" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translate(-50%, 50%)'}}>
                      <div className="framer-ri8ki2" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translate(-50%, 50%)'}} data-framer-component-type="RichTextContainer">
                       <p 
        style={{
          fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
          fontSize: '36px',
          fontWeight: 500,
          color: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'
        }} 
        className="framer-text"
      >
        <span 
          data-text-fill="true" 
          style={{
            backgroundImage: 'linear-gradient(89deg, rgb(65, 164, 255) 0%, rgb(99, 143, 255) 48.6486%, rgb(149, 84, 255) 99.0521%)'
          }} 
          className="framer-text"
        >
          03
        </span>
      </p>
      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ssr-variant hidden-ollpel hidden-9i23oi hidden-150dn05">
              <div className="framer-12ajjor-container hidden-150dn05 hidden-9i23oi">
                <div className="framer-gDYHu framer-UGujo framer-zG9tg framer-6x9247 framer-v-6x9247" data-framer-name="Variant 1" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', height: '100%', width: '100%'}} tabIndex={0}>
                  <div className="framer-jmjnku" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                    <p className="framer-text framer-styles-preset-jg60nb" data-styles-preset="X82eKqeAg">
                      Chúng tôi sẽ gửi hợp đồng tới địa chỉ của bạn, bạn vui lòng đọc kĩ và kí, xong gửi lại cho chúng tôi
                    </p>
                  </div>
                  <div className="framer-b19xlc" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                    <h6 className="framer-text framer-styles-preset-h2swgp" data-styles-preset="rgvzP8QHm">Ký kết hợp đồng</h6>
                  </div>
                  <div className="framer-10x28tw">
                    <div className="framer-l14z9g" style={{background: 'linear-gradient( 138deg, rgb(51, 214, 192) 0%, rgb(80, 131, 251) 24.31376689189189%, rgb(51, 109, 244) 48.52899774774775%, rgb(71, 82, 230) 71.5917088963964%, rgb(141, 85, 237) 100% )', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translateX(-50%)'}} />
                    <div className="framer-xj23t2" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translate(-50%, 50%)'}}>
                      <div className="framer-ri8ki2" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translate(-50%, 50%)'}} data-framer-component-type="RichTextContainer">
                       <p 
        style={{
          fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
          fontSize: '36px',
          fontWeight: 500,
          color: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'
        }} 
        className="framer-text"
      >
        <span 
          data-text-fill="true" 
          style={{
            backgroundImage: 'linear-gradient(89deg, rgb(65, 164, 255) 0%, rgb(99, 143, 255) 48.6486%, rgb(149, 84, 255) 99.0521%)'
          }} 
          className="framer-text"
        >
          03
        </span>
      </p>
      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ssr-variant hidden-q2nkjo">
              <div className="framer-jl2qyz-container hidden-150dn05 hidden-9i23oi">
                <div className="framer-gDYHu framer-UGujo framer-zG9tg framer-6x9247 framer-v-6x9247" data-framer-name="Variant 1" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', height: '100%', width: '100%'}} tabIndex={0}>
                  <div className="framer-jmjnku" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                    <p className="framer-text framer-styles-preset-jg60nb" data-styles-preset="X82eKqeAg">
                      Cần hỗ trợ thêm, vui lòng liên hệ lại cho chúng tôi
                    </p>
                  </div>
                  <div className="framer-b19xlc" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                    <h6 className="framer-text framer-styles-preset-h2swgp" data-styles-preset="rgvzP8QHm">Bắt đầu sử dụng</h6>
                  </div>
                  <div className="framer-10x28tw">
                    <div className="framer-l14z9g" style={{background: 'linear-gradient( 138deg, rgb(51, 214, 192) 0%, rgb(80, 131, 251) 24.31376689189189%, rgb(51, 109, 244) 48.52899774774775%, rgb(71, 82, 230) 71.5917088963964%, rgb(141, 85, 237) 100% )', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translateX(-50%)'}} />
                    <div className="framer-xj23t2" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translate(-50%, 50%)'}}>
                      <div className="framer-ri8ki2" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translate(-50%, 50%)'}} data-framer-component-type="RichTextContainer">
                       <p 
        style={{
          fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
          fontSize: '36px',
          fontWeight: 500,
          color: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'
        }} 
        className="framer-text"
      >
        <span 
          data-text-fill="true" 
          style={{
            backgroundImage: 'linear-gradient(89deg, rgb(65, 164, 255) 0%, rgb(99, 143, 255) 48.6486%, rgb(149, 84, 255) 99.0521%)'
          }} 
          className="framer-text"
        >
          04
        </span>
      </p>
      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ssr-variant hidden-ollpel hidden-9i23oi hidden-150dn05">
              <div className="framer-jl2qyz-container hidden-150dn05 hidden-9i23oi">
                <div className="framer-gDYHu framer-UGujo framer-zG9tg framer-6x9247 framer-v-6x9247" data-framer-name="Variant 1" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', height: '100%', width: '100%'}} tabIndex={0}>
                  <div className="framer-jmjnku" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                    <p className="framer-text framer-styles-preset-jg60nb" data-styles-preset="X82eKqeAg">
                      Cần hỗ trợ thêm, vui lòng liên hệ lại cho chúng tôi
                    </p>
                  </div>
                  <div className="framer-b19xlc" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                    <h6 className="framer-text framer-styles-preset-h2swgp" data-styles-preset="rgvzP8QHm">Bắt đầu sử dụng</h6>
                  </div>
                  <div className="framer-10x28tw">
                    <div className="framer-l14z9g" style={{background: 'linear-gradient( 138deg, rgb(51, 214, 192) 0%, rgb(80, 131, 251) 24.31376689189189%, rgb(51, 109, 244) 48.52899774774775%, rgb(71, 82, 230) 71.5917088963964%, rgb(141, 85, 237) 100% )', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translateX(-50%)'}} />
                    <div className="framer-xj23t2" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translate(-50%, 50%)'}}>
                      <div className="framer-ri8ki2" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translate(-50%, 50%)'}} data-framer-component-type="RichTextContainer">
                       <p 
        style={{
          fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
          fontSize: '36px',
          fontWeight: 500,
          color: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'
        }} 
        className="framer-text"
      >
        <span 
          data-text-fill="true" 
          style={{
            backgroundImage: 'linear-gradient(89deg, rgb(65, 164, 255) 0%, rgb(99, 143, 255) 48.6486%, rgb(149, 84, 255) 99.0521%)'
          }} 
          className="framer-text"
        >
          04
        </span>
      </p>
      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ssr-variant hidden-9i23oi hidden-150dn05">
              <div className="framer-t986o0 hidden-ollpel hidden-q2nkjo">
                <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                  <img decoding="async" src="https://framerusercontent.com/images/FzmoxvDoiGXbXBD0TCJhVbeQ1GE.png" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'cover', imageRendering: 'auto'}} />
                </div>
              </div>
            </div>
            <div className="ssr-variant hidden-ollpel hidden-150dn05 hidden-q2nkjo">
              <div className="framer-t986o0 hidden-ollpel hidden-q2nkjo">
                <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                  <img decoding="async" loading="lazy" src="https://framerusercontent.com/images/FzmoxvDoiGXbXBD0TCJhVbeQ1GE.png" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'cover', imageRendering: 'auto'}} />
                </div>
              </div>
            </div>
            <div className="ssr-variant hidden-ollpel hidden-9i23oi hidden-q2nkjo">
              <div className="framer-t986o0 hidden-ollpel hidden-q2nkjo">
                <div style={{position: 'absolute', borderRadius: 'inherit', top: 0, right: 0, bottom: 0, left: 0}} data-framer-background-image-wrapper="true">
                  <img decoding="async" loading="lazy" src="https://framerusercontent.com/images/FzmoxvDoiGXbXBD0TCJhVbeQ1GE.png" alt="" style={{display: 'block', width: '100%', height: '100%', borderRadius: 'inherit', objectPosition: 'center', objectFit: 'cover', imageRendering: 'auto'}} />
                </div>
              </div>
            </div>
          </div>
          <div className="framer-9li1du hidden-ollpel hidden-q2nkjo">
            <div className="framer-jxgu4y-container hidden-150dn05 hidden-9i23oi">
              <div className="framer-gDYHu framer-UGujo framer-zG9tg framer-6x9247 framer-v-6x9247" data-framer-name="Variant 1" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', height: '100%', width: '100%'}} tabIndex={0}>
                <div className="framer-1136j8q">
                  <div className="framer-qyef0j-container">
                    <a className="framer-43g8y framer-thvK1 framer-1xql04t framer-v-142g9u framer-14bqysp" data-framer-name="Text Link with icon" data-highlight="true" style={{borderBottomWidth: '0px', borderColor: 'rgba(0, 0, 0, 0)', borderLeftWidth: '0px', borderRightWidth: '0px', borderStyle: 'solid', borderTopWidth: '0px', backgroundColor: 'rgba(0, 0, 0, 0)', borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px', borderTopLeftRadius: '6px', borderTopRightRadius: '6px', boxShadow: 'none'}} tabIndex={0}>
                      <div className="framer-144pz35" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                        <p className="framer-text framer-styles-preset-91fca6" data-styles-preset="ikm7JuTuk" style={{framerTextColor: 'var(--extracted-r6o4lv, var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255)))'}}>
                       
                        </p>
                      </div>
                      <div className="framer-y8b76s-container"><div style={{display: 'contents'}} /></div>
                    </a>
                  </div>
                </div>
                <div className="framer-jmjnku" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                  <p className="framer-text framer-styles-preset-jg60nb" data-styles-preset="X82eKqeAg">
                   Bạn vui lòng điền đầy đủ thông tin, chúng tôi sẽ liên hệ lại cho bạn
                  </p>
                </div>
                <div className="framer-b19xlc" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                  <h6 className="framer-text framer-styles-preset-h2swgp" data-styles-preset="rgvzP8QHm">  Điền Form đăng ký</h6>
                </div>
                <div className="framer-10x28tw">
                  <div className="framer-l14z9g" style={{background: 'linear-gradient( 138deg, rgb(51, 214, 192) 0%, rgb(80, 131, 251) 24.31376689189189%, rgb(51, 109, 244) 48.52899774774775%, rgb(71, 82, 230) 71.5917088963964%, rgb(141, 85, 237) 100% )', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translateX(-50%)'}} />
                  <div className="framer-xj23t2" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translate(-50%, 50%)'}}>
                    <div className="framer-ri8ki2" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translate(-50%, 50%)'}} data-framer-component-type="RichTextContainer">
                      <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTUwMA==', framerFontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', framerFontSize: '36px', framerFontWeight: 500, framerTextColor: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'}} className="framer-text">
                        <span data-text-fill="true" style={{backgroundImage: 'linear-gradient(89deg, rgb(65, 164, 255) 0%, rgb(99, 143, 255) 48.6486%, rgb(149, 84, 255) 99.0521%)'}} className="framer-text">
                          01
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="framer-1407q3n-container hidden-150dn05 hidden-9i23oi">
              <div className="framer-gDYHu framer-UGujo framer-zG9tg framer-6x9247 framer-v-6x9247" data-framer-name="Variant 1" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', height: '100%', width: '100%'}} tabIndex={0}>
                <div className="framer-1136j8q">
                  <div className="framer-qyef0j-container">
                    <a className="framer-43g8y framer-thvK1 framer-1xql04t framer-v-142g9u framer-14bqysp" data-framer-name="Text Link with icon" data-highlight="true" style={{borderBottomWidth: '0px', borderColor: 'rgba(0, 0, 0, 0)', borderLeftWidth: '0px', borderRightWidth: '0px', borderStyle: 'solid', borderTopWidth: '0px', backgroundColor: 'rgba(0, 0, 0, 0)', borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px', borderTopLeftRadius: '6px', borderTopRightRadius: '6px', boxShadow: 'none'}} tabIndex={0}>
                      <div className="framer-144pz35" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                        <p className="framer-text framer-styles-preset-91fca6" data-styles-preset="ikm7JuTuk" style={{framerTextColor: 'var(--extracted-r6o4lv, var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255)))'}}>
                       
                        </p>
                      </div>
                      <div className="framer-y8b76s-container"><div style={{display: 'contents'}} /></div>
                    </a>
                  </div>
                </div>
                <div className="framer-jmjnku" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                  <p className="framer-text framer-styles-preset-jg60nb" data-styles-preset="X82eKqeAg">
                    Chúng tôi tư vấn gói dịch vụ phù hợp với doanh nghiệp của bạn
                  </p>
                </div>
                <div className="framer-b19xlc" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                  <h6 className="framer-text framer-styles-preset-h2swgp" data-styles-preset="rgvzP8QHm">Lựa chọn gói dịch vụ</h6>
                </div>
                <div className="framer-10x28tw">
                  <div className="framer-l14z9g" style={{background: 'linear-gradient( 138deg, rgb(51, 214, 192) 0%, rgb(80, 131, 251) 24.31376689189189%, rgb(51, 109, 244) 48.52899774774775%, rgb(71, 82, 230) 71.5917088963964%, rgb(141, 85, 237) 100% )', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translateX(-50%)'}} />
                  <div className="framer-xj23t2" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translate(-50%, 50%)'}}>
                    <div className="framer-ri8ki2" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translate(-50%, 50%)'}} data-framer-component-type="RichTextContainer">
                      <p style={{fontSelector: 'R0Y7Tm90byBTYW5zIEpQLTUwMA==', framerFontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif', framerFontSize: '36px', framerFontWeight: 500, framerTextColor: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'}} className="framer-text">
                        <span data-text-fill="true" style={{backgroundImage: 'linear-gradient(89deg, rgb(65, 164, 255) 0%, rgb(99, 143, 255) 48.6486%, rgb(149, 84, 255) 99.0521%)'}} className="framer-text">
                          02
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ssr-variant hidden-9i23oi hidden-150dn05">
              <div className="framer-1cf6qec-container">
                <div className="framer-gDYHu framer-UGujo framer-zG9tg framer-6x9247 framer-v-6x9247" data-framer-name="Variant 1" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', height: '100%', width: '100%'}} tabIndex={0}>
                  <div className="framer-1136j8q">
                    <div className="framer-qyef0j-container">
                      <a className="framer-43g8y framer-thvK1 framer-1xql04t framer-v-142g9u framer-14bqysp" data-framer-name="Text Link with icon" data-highlight="true" style={{borderBottomWidth: '0px', borderColor: 'rgba(0, 0, 0, 0)', borderLeftWidth: '0px', borderRightWidth: '0px', borderStyle: 'solid', borderTopWidth: '0px', backgroundColor: 'rgba(0, 0, 0, 0)', borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px', borderTopLeftRadius: '6px', borderTopRightRadius: '6px', boxShadow: 'none'}} tabIndex={0}>
                        <div className="framer-144pz35" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                          <p className="framer-text framer-styles-preset-91fca6" data-styles-preset="ikm7JuTuk" style={{framerTextColor: 'var(--extracted-r6o4lv, var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255)))'}}>
                         
                          </p>
                        </div>
                        <div className="framer-y8b76s-container"><div style={{display: 'contents'}} /></div>
                      </a>
                    </div>
                  </div>
                  <div className="framer-jmjnku" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                    <p className="framer-text framer-styles-preset-jg60nb" data-styles-preset="X82eKqeAg">
                      Chúng tôi sẽ gửi hợp đồng tới địa chỉ của bạn, bạn vui lòng đọc kĩ và kí, xong gửi lại cho chúng tôi
                    </p>
                  </div>
                  <div className="framer-b19xlc" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                    <h6 className="framer-text framer-styles-preset-h2swgp" data-styles-preset="rgvzP8QHm">Ký kết hợp đồng</h6>
                  </div>
                  <div className="framer-10x28tw">
                    <div className="framer-l14z9g" style={{background: 'linear-gradient( 138deg, rgb(51, 214, 192) 0%, rgb(80, 131, 251) 24.31376689189189%, rgb(51, 109, 244) 48.52899774774775%, rgb(71, 82, 230) 71.5917088963964%, rgb(141, 85, 237) 100% )', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translateX(-50%)'}} />
                    <div className="framer-xj23t2" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translate(-50%, 50%)'}}>
                      <div className="framer-ri8ki2" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translate(-50%, 50%)'}} data-framer-component-type="RichTextContainer">
                       <p 
        style={{
          fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
          fontSize: '36px',
          fontWeight: 500,
          color: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'
        }} 
        className="framer-text"
      >
        <span 
          data-text-fill="true" 
          style={{
            backgroundImage: 'linear-gradient(89deg, rgb(65, 164, 255) 0%, rgb(99, 143, 255) 48.6486%, rgb(149, 84, 255) 99.0521%)'
          }} 
          className="framer-text"
        >
          03
        </span>
      </p>
      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ssr-variant hidden-ollpel hidden-150dn05 hidden-q2nkjo">
              <div className="framer-1cf6qec-container">
                <div className="framer-gDYHu framer-UGujo framer-zG9tg framer-6x9247 framer-v-6x9247" data-framer-name="Variant 1" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', height: '100%', width: '100%'}} tabIndex={0}>
                  <div className="framer-jmjnku" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                    <p className="framer-text framer-styles-preset-jg60nb" data-styles-preset="X82eKqeAg">
                      Chúng tôi sẽ gửi hợp đồng tới địa chỉ của bạn, bạn vui lòng đọc kĩ và kí, xong gửi lại cho chúng tôi
                    </p>
                  </div>
                  <div className="framer-b19xlc" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                    <h6 className="framer-text framer-styles-preset-h2swgp" data-styles-preset="rgvzP8QHm">Ký kết hợp đồng</h6>
                  </div>
                  <div className="framer-10x28tw">
                    <div className="framer-l14z9g" style={{background: 'linear-gradient( 138deg, rgb(51, 214, 192) 0%, rgb(80, 131, 251) 24.31376689189189%, rgb(51, 109, 244) 48.52899774774775%, rgb(71, 82, 230) 71.5917088963964%, rgb(141, 85, 237) 100% )', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translateX(-50%)'}} />
                    <div className="framer-xj23t2" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translate(-50%, 50%)'}}>
                      <div className="framer-ri8ki2" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translate(-50%, 50%)'}} data-framer-component-type="RichTextContainer">
                       <p 
        style={{
          fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
          fontSize: '36px',
          fontWeight: 500,
          color: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'
        }} 
        className="framer-text"
      >
        <span 
          data-text-fill="true" 
          style={{
            backgroundImage: 'linear-gradient(89deg, rgb(65, 164, 255) 0%, rgb(99, 143, 255) 48.6486%, rgb(149, 84, 255) 99.0521%)'
          }} 
          className="framer-text"
        >
          03
        </span>
      </p>
      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ssr-variant hidden-ollpel hidden-9i23oi hidden-q2nkjo">
              <div className="framer-1cf6qec-container">
                <div className="framer-gDYHu framer-UGujo framer-zG9tg framer-6x9247 framer-v-6x9247" data-framer-name="Variant 1" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', height: '100%', width: '100%'}} tabIndex={0}>
                  <div className="framer-jmjnku" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                    <p className="framer-text framer-styles-preset-jg60nb" data-styles-preset="X82eKqeAg">
                      Chúng tôi sẽ gửi hợp đồng tới địa chỉ của bạn, bạn vui lòng đọc kĩ và kí, xong gửi lại cho chúng tôi
                    </p>
                  </div>
                  <div className="framer-b19xlc" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                    <h6 className="framer-text framer-styles-preset-h2swgp" data-styles-preset="rgvzP8QHm">Ký kết hợp đồng</h6>
                  </div>
                  <div className="framer-10x28tw">
                    <div className="framer-l14z9g" style={{background: 'linear-gradient( 138deg, rgb(51, 214, 192) 0%, rgb(80, 131, 251) 24.31376689189189%, rgb(51, 109, 244) 48.52899774774775%, rgb(71, 82, 230) 71.5917088963964%, rgb(141, 85, 237) 100% )', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translateX(-50%)'}} />
                    <div className="framer-xj23t2" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translate(-50%, 50%)'}}>
                      <div className="framer-ri8ki2" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translate(-50%, 50%)'}} data-framer-component-type="RichTextContainer">
                       <p 
        style={{
          fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
          fontSize: '36px',
          fontWeight: 500,
          color: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'
        }} 
        className="framer-text"
      >
        <span 
          data-text-fill="true" 
          style={{
            backgroundImage: 'linear-gradient(89deg, rgb(65, 164, 255) 0%, rgb(99, 143, 255) 48.6486%, rgb(149, 84, 255) 99.0521%)'
          }} 
          className="framer-text"
        >
          03
        </span>
      </p>
      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ssr-variant hidden-9i23oi hidden-150dn05">
              <div className="framer-1xulaza-container">
                <div className="framer-gDYHu framer-UGujo framer-zG9tg framer-6x9247 framer-v-6x9247" data-framer-name="Variant 1" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', height: '100%', width: '100%'}} tabIndex={0}>
                  <div className="framer-1136j8q">
                    <div className="framer-qyef0j-container">
                      <a className="framer-43g8y framer-thvK1 framer-1xql04t framer-v-142g9u framer-14bqysp" data-framer-name="Text Link with icon" data-highlight="true" style={{borderBottomWidth: '0px', borderColor: 'rgba(0, 0, 0, 0)', borderLeftWidth: '0px', borderRightWidth: '0px', borderStyle: 'solid', borderTopWidth: '0px', backgroundColor: 'rgba(0, 0, 0, 0)', borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px', borderTopLeftRadius: '6px', borderTopRightRadius: '6px', boxShadow: 'none'}} tabIndex={0}>
                        <div className="framer-144pz35" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'none'}} data-framer-component-type="RichTextContainer">
                          <p className="framer-text framer-styles-preset-91fca6" data-styles-preset="ikm7JuTuk" style={{framerTextColor: 'var(--extracted-r6o4lv, var(--token-c35d3ec6-90ac-4a43-90e6-a37deb90809c, rgb(51, 112, 255)))'}}>
                         
                          </p>
                        </div>
                        <div className="framer-y8b76s-container"><div style={{display: 'contents'}} /></div>
                      </a>
                    </div>
                  </div>
                  <div className="framer-jmjnku" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                    <p className="framer-text framer-styles-preset-jg60nb" data-styles-preset="X82eKqeAg">
                      Cần hỗ trợ thêm, vui lòng liên hệ lại cho chúng tôi
                    </p>
                  </div>
                  <div className="framer-b19xlc" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                    <h6 className="framer-text framer-styles-preset-h2swgp" data-styles-preset="rgvzP8QHm">Bắt đầu sử dụng</h6>
                  </div>
                  <div className="framer-10x28tw">
                    <div className="framer-l14z9g" style={{background: 'linear-gradient( 138deg, rgb(51, 214, 192) 0%, rgb(80, 131, 251) 24.31376689189189%, rgb(51, 109, 244) 48.52899774774775%, rgb(71, 82, 230) 71.5917088963964%, rgb(141, 85, 237) 100% )', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translateX(-50%)'}} />
                    <div className="framer-xj23t2" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translate(-50%, 50%)'}}>
                      <div className="framer-ri8ki2" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translate(-50%, 50%)'}} data-framer-component-type="RichTextContainer">
                       <p 
        style={{
          fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
          fontSize: '36px',
          fontWeight: 500,
          color: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'
        }} 
        className="framer-text"
      >
        <span 
          data-text-fill="true" 
          style={{
            backgroundImage: 'linear-gradient(89deg, rgb(65, 164, 255) 0%, rgb(99, 143, 255) 48.6486%, rgb(149, 84, 255) 99.0521%)'
          }} 
          className="framer-text"
        >
          04
        </span>
      </p>
      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ssr-variant hidden-ollpel hidden-150dn05 hidden-q2nkjo">
              <div className="framer-1xulaza-container">
                <div className="framer-gDYHu framer-UGujo framer-zG9tg framer-6x9247 framer-v-6x9247" data-framer-name="Variant 1" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', height: '100%', width: '100%'}} tabIndex={0}>
                  <div className="framer-jmjnku" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                    <p className="framer-text framer-styles-preset-jg60nb" data-styles-preset="X82eKqeAg">
                      Cần hỗ trợ thêm, vui lòng liên hệ lại cho chúng tôi
                    </p>
                  </div>
                  <div className="framer-b19xlc" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                    <h6 className="framer-text framer-styles-preset-h2swgp" data-styles-preset="rgvzP8QHm">Bắt đầu sử dụng</h6>
                  </div>
                  <div className="framer-10x28tw">
                    <div className="framer-l14z9g" style={{background: 'linear-gradient( 138deg, rgb(51, 214, 192) 0%, rgb(80, 131, 251) 24.31376689189189%, rgb(51, 109, 244) 48.52899774774775%, rgb(71, 82, 230) 71.5917088963964%, rgb(141, 85, 237) 100% )', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translateX(-50%)'}} />
                    <div className="framer-xj23t2" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translate(-50%, 50%)'}}>
                      <div className="framer-ri8ki2" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translate(-50%, 50%)'}} data-framer-component-type="RichTextContainer">
                       <p 
        style={{
          fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
          fontSize: '36px',
          fontWeight: 500,
          color: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'
        }} 
        className="framer-text"
      >
        <span 
          data-text-fill="true" 
          style={{
            backgroundImage: 'linear-gradient(89deg, rgb(65, 164, 255) 0%, rgb(99, 143, 255) 48.6486%, rgb(149, 84, 255) 99.0521%)'
          }} 
          className="framer-text"
        >
          04
        </span>
      </p>
      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ssr-variant hidden-ollpel hidden-9i23oi hidden-q2nkjo">
              <div className="framer-1xulaza-container">
                <div className="framer-gDYHu framer-UGujo framer-zG9tg framer-6x9247 framer-v-6x9247" data-framer-name="Variant 1" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', height: '100%', width: '100%'}} tabIndex={0}>
                  <div className="framer-jmjnku" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                    <p className="framer-text framer-styles-preset-jg60nb" data-styles-preset="X82eKqeAg">
                      Cần hỗ trợ thêm, vui lòng liên hệ lại cho chúng tôi
                    </p>
                  </div>
                  <div className="framer-b19xlc" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translateX(-50%)'}} data-framer-component-type="RichTextContainer">
                    <h6 className="framer-text framer-styles-preset-h2swgp" data-styles-preset="rgvzP8QHm">Bắt đầu sử dụng</h6>
                  </div>
                  <div className="framer-10x28tw">
                    <div className="framer-l14z9g" style={{background: 'linear-gradient( 138deg, rgb(51, 214, 192) 0%, rgb(80, 131, 251) 24.31376689189189%, rgb(51, 109, 244) 48.52899774774775%, rgb(71, 82, 230) 71.5917088963964%, rgb(141, 85, 237) 100% )', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translateX(-50%)'}} />
                    <div className="framer-xj23t2" style={{backgroundColor: 'rgb(255, 255, 255)', borderBottomLeftRadius: '100px', borderBottomRightRadius: '100px', borderTopLeftRadius: '100px', borderTopRightRadius: '100px', transform: 'translate(-50%, 50%)'}}>
                      <div className="framer-ri8ki2" style={{outline: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexShrink: 0, extractedR6o4lv: 'var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115))', framerLinkTextColor: 'rgb(0, 153, 255)', framerLinkTextDecoration: 'underline', transform: 'translate(-50%, 50%)'}} data-framer-component-type="RichTextContainer">
                       <p 
        style={{
          fontFamily: '"Noto Sans JP", "Noto Sans JP Placeholder", sans-serif',
          fontSize: '36px',
          fontWeight: 500,
          color: 'var(--extracted-r6o4lv, var(--token-f2a78d25-fcec-4bd5-8d8e-593bde32ecf2, rgb(100, 106, 115)))'
        }} 
        className="framer-text"
      >
        <span 
          data-text-fill="true" 
          style={{
            backgroundImage: 'linear-gradient(89deg, rgb(65, 164, 255) 0%, rgb(99, 143, 255) 48.6486%, rgb(149, 84, 255) 99.0521%)'
          }} 
          className="framer-text"
        >
          04
        </span>
      </p>
      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     );
}

export default StepRegister;